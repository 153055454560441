<template>
  <div
    class="simple-hero home-hero" :class="config.headlineType ? 'has-headline' : ''"
    :style="[image ?`background-image: url(${image})` : 'background-color: var(--primary)',`height: ${height} !important`, `min-height: ${minHeight}px;`, isFoodleap ? `background-size: cover; background-position: center 27%;` : '']">
    <div ref="simpleHeroContainer" class="simple-hero__backdrop" :class="(isFoodleap && !hideLogo) ? 'is-foodleap' : (hideLogo && isFoodleap) ? 'is-foodleap--without-height' : ''">
      <Container class="simple-hero__container">
        <!--no Headline-->
        <template v-if="config.homepage && !hideLogo">
          <a :href="config.homepage" target="_blank" v-if="!config.headlineType">
            <div class="simple-hero__background-logo" v-if="!config.headlineType" :style="`background-image: url('${logo}')`"></div>
          </a>
        </template>
        <template v-else>
          <img v-if="!config.headlineType && !hideLogo" class="simple-hero__logo" :src="logo"/>
        </template>
        <BasicHero
          style="background: transparent"
          :title="title"
          :subtitle="tagline"
          v-if="!config.headlineType"
        />
        <!--headline-->
        <div class="basic-simple-hero__content" v-if="config.headlineType">
          <div class="simple-hero__small-logo" v-if="config.headlineType && !isCommunityEcosystem && !hideLogo" :style="`background-image: url('${logo}')`"></div>
          <div :class="{'with-gray-overlay': isSoftlanding}">
            <div class="simple-hero__title">{{ title }}</div>
            <div class="simple-hero__description">
              <div v-html="tagline"></div>
            </div>
          </div>
        </div>
        <div
          v-if="config.headlineType === 'challenge' && canCreateChallenge"
          class="headline" @click="openCreateChallengePanel">
          <ds-input
            disabled
            :placeholder="headlineText || $t('headline_default_message')"></ds-input>
        </div>
        <div v-if="config.headlineType === 'global-search'" class="headline">
          <TopBarGlobalSearch
            no-input
            :disabled="isMobile"
            :placeholder="headlineText || $t('headline_default_message')"
            @click="onGlobalSearchClick"
          />
        </div>
        <div v-if="showLoginAndRegister" class="basic-simple-hero__login__register-container">
          <DsButton
            variant="rounded"
            label="Register"
            to="/register"
            style="background-color: #ED1C24; text-align: center;"
          />
          <DsButton
            variant="rounded"
            label="Login"
            to="/login"
            style="background-color: #231F20; text-align: center;"
          />
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
  import TopBarGlobalSearch from '../TopBar/TopBarGlobalSearch.vue'
  import Container from '../Container/Container.vue'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import BasicHero from '../BasicHero/BasicHero.vue'
  import FiltersMixin from '../../util/FiltersMixin.js'

  export default {
    name: 'SimpleHero',
    props: {
      isFoodleap: {
        type: Boolean,
        default: false,
      },
      height: {
        type: String
      },
      showLoginAndRegister: {
        type: Boolean,
        default: false,
      },
      hideLogo: {
        type: Boolean,
        default: false,
      }
    },
    data: () => {
      return {
        minHeight: 300,
      }
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      image () {
        if (this.$store.getters.isStaging) {
          return '/images/static-home-page/static-home-page-foodleap.png'
        }

        return this.config.homepage_knowledge_base_image
      },
      logo () {
        return this.config.logo_url_light || this.config.logo_url
      },
      isSoftlanding () {
        return this.$store.getters.isSoftlanding
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      tagline () {
        return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'tagline')
          : this.localizedDisplayProperty(this.config, 'taglineLoggedOut')
      },
      canCreateChallenge () {
        return this.$store.getters.canCreateChallenge
      },
      headlineText () {
        return this.localizedDisplayProperty(this.config, 'headlineText')
      },
      isMobile () {
        return this.$store.getters.isMobile
      },
      title () {
        return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'introTitleLoggedIn')
          : this.localizedDisplayProperty(this.config, 'introTitleLoggedOut')
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
    },
    methods: {
      openCreateChallengePanel () {
        trackHeapEvent('basicHomepageSimplified.ctaCreateChallenge')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'manage-challenge-panel' })
      },
      onGlobalSearchClick () {
        // Make sure that the global search input bar is visible
        if (document.querySelector('.global-search')) {
          document.querySelector('.global-search').style.transition = 'opacity 500ms'
          document.querySelector('.global-search').style.display = 'block'
          document.querySelector('.global-search').style.opacity = 1
          document.querySelector('.global-search input').focus()
        }

        trackHeapEvent('basicHomepageSimplified.ctaGlobalSearch')
        if (this.isMobile) {
          this.$bus.emit('toggleMobileSearchDrawerVisibility')
        }
      },
      setHeroHeight () {
        if (this.height) {
          this.minHeight = this.height
          return
        }

        if (this.$refs && this.$refs.simpleHeroContainer) {
          const heroContainerHeight = this.$refs.simpleHeroContainer.clientHeight

          if (heroContainerHeight > this.minHeight) {
            this.minHeight = heroContainerHeight
          }
        }
      },
      setHeightForHomeFoodleap () {
        if (this.$store.getters.isFoodleap) {
          this.minHeight = 500
        }
      },
    },
    mounted () {
      this.setHeroHeight()
      this.setHeightForHomeFoodleap()

      this.$bus.on('resize', () => {
        this.setHeroHeight()
      })
    },
    mixins: [FiltersMixin, TranslationsMixin],
    components: {
      Container,
      TopBarGlobalSearch,
      BasicHero,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .static-home-community-ecosystem {
    .simple-hero {
      background-position: initial;
    }

    .simple-hero__title {
      font-weight: 500;
    }

    .simple-hero__title, .simple-hero__description {
      color: #323441;
      text-shadow: none;
    }

    .headline {
      .button {
        border-color: #323441;
        color: #323441;

        .button__label {
          color: #323441;
        }
      }
    }
  }

  .foodvalley {
    .home-hero {
      justify-content: flex-start;
    }
  }

  .home-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  .simple-hero__backdrop {
    // Request by Matthias: remove backdrop colour
    // background-color: rgba(0, 0, 0, 0.25);
  }

  .simple-hero__background-logo {
    width: 100%;
    display: flex;
    margin: 2rem auto .5rem auto;
    min-height: 50px;
    max-height: 100px;
    max-width: 280px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .simple-hero__logo {
    max-height: 100px;
    max-width: 280px;
    margin: 0 auto;
    width: 100%;
  }

  .simple-hero {
    .with-gray-overlay {
      opacity: 0.8;
      padding: 20px;
      background: rgba(0, 0, 0, 0.5);
    }

    .simple-hero__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0;
    }

    &.has-headline {
      height: initial;

      .simple-hero__backdrop {
        .simple-hero__container {
          .basic-simple-hero__content {
            .simple-hero__small-logo {
              width: 100%;
              display: flex;
              margin: 0 auto 1rem auto;
              min-height: 50px;
              max-height: 100px;
              max-width: 280px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
          }

          .headline {
            width: 100%;

            &:hover {
              :deep(button) {
                border: 1px solid $color-primary;
                background: white;
              }
            }
          }
        }
      }
    }
  }

  .basic-simple-hero__login__register-container {
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }

  .home-hero .basic-hero {
    margin: auto;
    max-width: 750px;
  }

  .simple-hero__title {
    margin: auto;
    max-width: 750px;
    font-size: 2rem;
    text-align: center;
  }

  .basic-simple-hero__content {
    margin-bottom: 0;
    padding: 1rem;
    color: #fff;
    text-shadow: 0 0 4px black;
  }

  .simple-hero__description {
    text-align: center;
    margin: 5px auto 10px;

    * {
      font-size: 1rem;
    }
  }

  .headline {
    text-align: center;
  }

  .button {
    width: 45%;
    border: 1px solid $color-borders;
  }

  .is-foodleap {
    font-family: Museo, serif;
    margin-top: 90px;
    font-size: 35px;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
  }

  .is-foodleap--without-height {
    margin-top: 0px;
  }

  @media(max-width: $screen-sm) {
    .button {
      width: 100%;
    }
  }

</style>
