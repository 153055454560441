<template>
  <modal :id="modalId" ref="modal" @close="$emit('close')" :size="!modalContext.status ? 'flex' : ''" :wrapper-size="!modalContext.status ? 'flex' : ''">
    <template v-slot:body>
      <div class="contact-modal__header">
        <div class="contact-modal__header-title">{{ title }}</div>
        <ds-button
          class="contact-modal__header-close-btn"
          icon="remove"
          variant="minimal"
          @click="close"
        />
      </div>
      <div v-if="!modalContext.status">
        <div v-if="loadingSufficientInformation">
          <Loading/>
        </div>
        <div v-else-if="!hasSufficientInformation">
          <p style="margin-top: 2rem;">Please provide your VAT number and country in the <a href="/settings/billing-overview">billing overview</a>.</p>
        </div>

        <div v-else-if="!showPaymentForm">
          <div class="actor-credit-packages">
            <div class="package" v-for="dataPackage in dataPackages" :key="dataPackage.name">
              <div v-if="dataPackage.credits === 5000" class="popular-banner">
                Most popular
              </div>
              <h2 class="title">{{ dataPackage.title }}</h2>
              <h4>{{ dataPackage.credits }}</h4>
              <p>Actor credits</p>
              <p class="price"><strong>€ {{ dataPackage.price }}</strong></p>
              <span class="exclusive-vat">(VAT Excl.)</span>
              <div class="purchase-button-container">
                <ds-button
                  label="Purchase"
                  variant="rounded"
                  class="purchase-button"
                  @click="selectPackage(dataPackage)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="payment-form--header" v-if="selectedPackage">
          <h1>€ {{ selectedPackage.price }}</h1>
          <p>{{ selectedPackage.credits }} actor credits</p>
          <span class="choose-other-plan"
                @click="selectedPackage = null; showPaymentForm = false;">Choose other package</span>
        </div>
        <div v-if="showPaymentForm" id="payment-form">
          <h3>Enter Payment Details</h3>
          <form-group label="Card holder">
            <div id="card-holder"></div>
          </form-group>
          <form-group label="Card number" class="form-group">
            <div id="card-number"></div>
          </form-group>
          <form-group label="Expiry date" class="form-group">
            <div id="expiry-date"></div>
          </form-group>
          <form-group label="CVV" class="form-group">
            <div id="cvv"></div>
          </form-group>

          <p v-if="paymentError" class="error">{{ paymentError }}</p>
          <ds-button
            v-if="selectedPackage"
            :label="loading ? '' : 'Pay € ' + selectedPackage.price"
            @click="submitPayment"
            variant="secondary"
            style="width: 100%"
            :icon="loading ? 'spinner' : ''"
          />
          <!--            :icon="loading ? 'spinner' : ''"-->
          <p class="powered-by-mollie">
            <icon name="padlock" size="12"/>
            Payment secured and powered by
            <img class="mollie-logo" alt="mollie-logo" src="../../../../../public/images/logos/mollie-logo.svg"/>
          </p>
        </div>
      </div>
      <div v-else style="display: flex; justify-content: center; flex-direction: column; align-content: center;">
        <icon
          v-if="modalContext.status === 'paid'"
          style="align-self: center; margin: 1rem; fill: #20b920;"
          name="check-circle"
          size="60"
        />
        <icon
          v-else-if="modalContext.status === 'open'"
          style="align-self: center; margin: 1rem; fill: #35b7f5;"
          name="circle"
          size="60"
        />
        <icon
          v-else-if="modalContext.status === 'failed'"
          style="align-self: center; margin: 1rem; fill: red;"
          name="check-xmark"
          size="60"
        />
        <p style="text-align: center;" v-html="subtitle"></p>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modals/Modal.vue';
  import { checkTenantVatAndCountryInfo, createPaymentLink } from '@/api/billing';
  import FormGroup from '@/components/Form/FormGroup.vue'
  import Icon from '@/components/Icon/Icon.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'
  import MODAL_IDS from '@/constants/modal-ids'
  import UserMixin from '@/util/UserMixin'
  import Loading from '@/components/Dashboard/ConceptMap/Loading.vue'

  export default {
    components: { Loading, Icon, FormGroup, Modal },
    mixins: [UserMixin],
    data () {
      return {
        modalId: 'purchase-modal',
        showPaymentForm: false,
        selectedPackage: null,
        mollie: null,
        paymentError: null,
        mollieComponents: {},
        loading: false,
        loadingSufficientInformation: false,
        hasSufficientInformation: false,
      };
    },
    mounted () {
      this.loadingSufficientInformation = true
      checkTenantVatAndCountryInfo()
        .then((response) => {
          if (response.success) {
            this.hasSufficientInformation = true
          }
        })
        .catch((error) => {
          this.hasSufficientInformation = false
        })
        .finally(() => {
          this.loadingSufficientInformation = false
        })
    },
    computed: {
      subtitle () {
        switch (this.modalContext.status) {
          case 'open':
            return 'Your payment is still open. Please complete your payment to proceed. If you\'ve already paid, the transaction may take a moment to process. Thank you for your patience!'
          case 'paid':
            return this.modalContext.credits ? `Thank you for your purchase! ${this.modalContext.credits} credits will be added to the ecosystem shortly!`
              : 'Thank you for your purchase! Your credits will be added shortly to the ecosystem!'
          case 'failed':
            return 'Your payment has been canceled. If this was unintentional, you can try again to complete your order. For further assistance, please contact <a href="mailto:support@datascouts.eu">support@datascouts.eu</a>.'
          default:
            return ''
        }
      },
      dataPackages () {
        return [
          { name: '2500_credits', credits: 2500, price: 860, title: 'Small' },
          { name: '5000_credits', credits: 5000, price: 1435, title: 'Medium' },
          { name: '10000_credits', credits: 10000, price: 2475, title: 'Large' },
        ];
      },
      title () {
        return this.modalContext.title
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      }
    },
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.PURCHASE_DATA_CREDITS)
        this.$emit('close')
      },
      async selectPackage (dataPackage) {
        this.selectedPackage = dataPackage;
        this.showPaymentForm = true;
        this.mollie = null

        await this.loadMollieScript();
        this.initMollieComponents(); // First-time initialization
      },
      async loadMollieScript () {
        if (window.Mollie) {
          // Mollie script is already loaded
          return;
        }

        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = 'https://js.mollie.com/v1/mollie.js';
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
      },
      initMollieComponents () {
        try {
          this.mollie = Mollie('pfl_htE23d5Sq9', { locale: 'en_US', testmode: true });

          // Create Mollie components for secure card input
          this.mollieComponents = {
            cardHolder: this.mollie.createComponent('cardHolder'),
            cardNumber: this.mollie.createComponent('cardNumber'),
            expiryDate: this.mollie.createComponent('expiryDate'),
            verificationCode: this.mollie.createComponent('verificationCode'),
          };

          // Mount components to their respective DOM elements
          this.mollieComponents.cardHolder.mount('#card-holder')
          this.mollieComponents.cardNumber.mount('#card-number');
          this.mollieComponents.expiryDate.mount('#expiry-date');
          this.mollieComponents.verificationCode.mount('#cvv');

          // Add classes to the wrapping divs or directly to Mollie components
          document.querySelector('#card-holder').classList.add('mollie-component', 'card-holder-class');
          document.querySelector('#card-number').classList.add('mollie-component', 'card-number-class');
          document.querySelector('#expiry-date').classList.add('mollie-component', 'expiry-date-class');
          document.querySelector('#cvv').classList.add('mollie-component', 'cvv-class');
        } catch (error) {
          console.error('Error initializing Mollie Components:', error);
          this.paymentError = 'Failed to load payment form. Please try again later.';
        }
      },
      async submitPayment () {
        this.loading = true
        try {
          // Use the tokenization method to generate a token
          const { token, error } = await this.mollie.createToken();

          // Handle any error in the tokenization process
          if (error) {
            console.error('Tokenization error:', error);
            this.paymentError = 'Invalid card details. Please check and try again.';
            this.loading = false

            return;
          }

          // Send token to backend for payment creation
          const response = await createPaymentLink(
            token,
            this.selectedPackage.name,
            this.selectedPackage.price,
            this.selectedPackage.credits
          );

          if (response.success) {
            window.location.href = response.paymentUrl; // Redirect to Mollie for 3D Secure, if required
          } else {
            this.paymentError = response.message || 'Payment failed. Please try again.';
          }

          this.loading = false
        } catch (err) {
          console.error('Error during payment submission:', err);
          this.paymentError = 'An unexpected error occurred.';
          this.loading = false
        }
      }
    },
  };
</script>

<style>
  .contact-modal__header {
    width: 109.3% !important;
  }

  .actor-credit-packages {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .package {
      position: relative;
      background-color: #7eeed9;
      border-radius: 0.25rem;
      padding: 25px;
      margin: 2.5rem 0.5rem 0.5rem 0.5rem;
      width: 200px;
      font-family: "Open Sans", sans-serif;

      .title {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 2rem;
      }

      .price {
        font-size: 18px;
        margin-bottom: 0;
      }

      .exclusive-vat {
        display: block;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 3rem;
      }

      h4 {
        text-align: center;
      }

      p {
        text-align: center;
      }

      .purchase-button-container {
        display: flex;
        justify-content: center;

        .purchase-button {
          align-self: center;
          justify-self: center;
          background-color: white;
          color: black;

          &:hover {
            background-color: black;
            color: white;
          }
        }
      }

    }
  }

  .mollie-input {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .mollie-input label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
  }

  .mollie-input input {
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
  }

  .mollie-input input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .mollie-input.error input {
    border-color: #dc3545;
  }

  .mollie-input .error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 5px;
  }

  .payment-form--header {
    text-align: center;
    margin: 1.5rem 0;

    .choose-other-plan {
      font-size: 10px;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--primary)
      }
    }
  }

  #payment-form h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }

  #payment-form {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .powered-by-mollie {
    margin-top: 0.25rem;
    font-size: 12px;

    .mollie-logo {
      width: 35px;
    }
  }


  .error {
    color: red;
  }

  .popular-banner {
    position: absolute;
    background-color: #009178;
    padding: 0.5rem;
    color: white;
    transform: rotate(18deg);
    right: -10px;
    top: -10px;
  }
</style>
