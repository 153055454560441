<template>
  <div>
    <div class="billing-details">
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h2>Billing Information</h2>
        <DsButton
          label="Save billing information"
          variant="rounded"
          style="margin-right: 1rem; width: 210px; text-align: center;"
          @click="updateBillingInformation"
          :loading="loading"
          :without-label="loading"
          icon-fill-color="#fff"
          :disabled="isEditingBillingInformation"
        />
      </div>
      <FormGroup
        required
        style="margin-top: 1rem;"
        is-simplified
        label="Vat Number & Country"
        instructions="(Required information)"
      >
        <div class="billing-details-row">
          <div style="width: 50%">
            <DsInput class="billing-details-field" placeholder="Vat Number" v-model="editedBillingInformation.vatNumber"/>
            <p style="color: red;" v-if="errors.vatNumber">{{ errors.vatNumber }}</p>
          </div>
          <div style="width: 50%">
            <!--            <DsInput class="billing-details-field" placeholder="Billing Country" v-model="editedBillingInformation.country"/>-->
            <country-dropdown
              id="company-country"
              @update:modelValue="handleCountryChange"
              :model-value="editedBillingInformation.country"
            />
            <p style="color: red;" v-if="errors.country">{{ errors.country }}</p>
          </div>
        </div>
      </FormGroup>
      <FormGroup
        is-simplified
        label="Billing address"
        instructions="(Optional information)"
      >

        <div class="billing-details-row">
          <DsInput class="billing-details-field" placeholder="Billing Address line 1" v-model="editedBillingInformation.addressLine1"/>
          <DsInput class="billing-details-field" placeholder="Billing Address line 2" v-model="editedBillingInformation.addressLine2"/>
        </div>

        <div class="billing-details-row">
          <DsInput class="billing-details-field" placeholder="Billing City" v-model="editedBillingInformation.city"/>
          <DsInput class="billing-details-field" placeholder="Billing Zip" v-model="editedBillingInformation.zip"/>
        </div>

        <div class="billing-details-row">
          <DsInput style="width: 50%;" class="billing-details-field" placeholder="Billing State" v-model="editedBillingInformation.state"/>
        </div>
      </FormGroup>
    </div>
    <h2 style="margin-bottom: 1rem;">
      Recent Invoices
    </h2>
    <div class="fixed-heading">
      <div class="curation fixed-heading scrollable">
        <div v-if="billingData" class="has-pagination">
          <table class="table table--fill table--hover">
            <thead>
            <tr class="row-curation">
              <td>Package</td>
              <td>User</td>
              <td>Date</td>
              <td>Status</td>
            </tr>
            </thead>
            <tbody>
            <tr class="row-curation" v-for="bill in billingData">
              <td class="text-left">{{ humanize(bill.description) }}</td>
              <td>{{ bill.user && bill.user.name ? bill.user.name : 'System' }}</td>
              <td>{{ new Date(bill.created_at).toLocaleString() }}</td>
              <td>
                <p v-if="bill.status !== 'paid' && bill.type != 'automatic_invoice'" class="status" :class="'status--' + bill.status">
                  {{ humanize(bill.status) }}
                </p>

                <p v-else-if="bill.status !== 'paid' && bill.type === 'automatic_invoice'" @click="downloadInvoice(bill.id, 'automatic_invoice')" class="status"
                   :class="'status--' + bill.status + ' hoverable'">
                  {{ humanize(bill.status) }}
                  <icon v-if="bill.status === 'open'" name="download" size="12"/>
                </p>

                <p v-else @click="downloadInvoice(bill.id, 'data_credits')" style="cursor: pointer;" class="status" :class="'status--' + bill.status">
                  {{ humanize(bill.status) }}
                  <icon name="download" size="12"/>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NewCard from '../../components/NewCard/NewCard.vue'
  import { defineComponent } from 'vue'
  import { fetchBillingInformation, fetchBillingPayments, updateBillingInformation } from '@/api/billing'
  import { humanize } from '@/constants/properties'
  import DsInput from '@/components/Form/DsInput.vue'
  import FormGroup from '@/components/Form/FormGroup.vue'
  import CountryDropdown from '@/components/Dropdown/CountryDropdown.vue'

  export default defineComponent({
    data () {
      return {
        billingData: null,
        billingInformation: {
          vatNumber: null,
          country: null,
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          zip: null,
        },
        editedBillingInformation: {
          vatNumber: null,
          country: null,
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          zip: null,
        },
        loading: false,
        errors: {},
      }
    },
    computed: {
      isEditingBillingInformation () {
        return this.billingInformation.vatNumber === this.editedBillingInformation.vatNumber &&
          this.billingInformation.country === this.editedBillingInformation.country &&
          this.billingInformation.addressLine1 === this.editedBillingInformation.addressLine1 &&
          this.billingInformation.addressLine2 === this.editedBillingInformation.addressLine2 &&
          this.billingInformation.city === this.editedBillingInformation.city &&
          this.billingInformation.state === this.editedBillingInformation.state &&
          this.billingInformation.zip === this.editedBillingInformation.zip
      },
    },
    methods: {
      humanize,
      downloadInvoice (id, type) {
        window.open('/api/billing/invoice/' + id + '?t=' + type, '_blank');
      },
      handleCountryChange (country) {
        this.editedBillingInformation.country = country.name
      },
      fetchPayments () {
        fetchBillingPayments()
          .then((data) => {
            this.billingData = data
          })
      },
      updateBillingInformation () {
        this.loading = true

        if (!this.validate()) {
          this.loading = false
          return
        }

        updateBillingInformation(this.editedBillingInformation)
          .then((response) => {
            this.billingInformation = { ...response };
            this.editedBillingInformation = { ...response };
          })
          .finally(() => {
            this.loading = false
          })
      },
      fetchBillingInformation () {
        fetchBillingInformation()
          .then((response) => {
            this.billingInformation = { ...response };
            this.editedBillingInformation = { ...response };
          })
      },
      validate () {
        this.errors = {}
        if (!this.editedBillingInformation.country) {
          this.errors.country = 'A country is required'
        }

        if (!this.editedBillingInformation.vatNumber) {
          this.errors.vatNumber = 'A vat number is required'
        }

        if (!this.errors.vatNumber && !this.errors.country) {
          return true
        }

        return false
      }
    },
    created () {
      this.fetchPayments()
    },
    mounted () {
      this.fetchBillingInformation()
    },
    components: {
      CountryDropdown,
      FormGroup,
      DsInput,
      NewCard,
    },
  })
</script>

<style scoped lang="scss">
  @import '../../../scss/variables';

  .status {
    /* Use a lighter version of the color prop for the background. Replace "--color" with the variable you use for colors. */
    background-color: var(--color-light, #f0f0f0); /* Default to a light gray if no color is defined */
    padding: 0.25rem;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    width: 80px;
  }

  .status--paid {
    color: green;
    background-color: #dfffd6; /* Light green for open status */

    &:hover {
      background-color: green;
      color: #dfffd6;
    }
  }

  .status--open {
    color: #4180fd;
    background-color: #d6ebff; /* Blue green for open status */
  }

  .status--open.hoverable {
    &:hover {
      background-color: #4180fd;
      color: #d6ebff;
    }

    cursor: unset;
  }

  .status--in_progress {
    color: orange;
    background-color: #fff4d6; /* Light orange for in-progress status */
  }

  .status--canceled {
    color: red;
    background-color: #ffd6d6; /* Light red for canceled status */
  }

  .billing-details-field {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .billing-details-row {
    display: flex;
    flex-direction: row;
  }
</style>
