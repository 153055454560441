<template>
  <div
    class="colored-container outlined"
    v-if="actorFacetsWithoutEmpty && actorFacetsWithoutEmpty.length"
  >
    <div class="facet-filters" v-if="isMobile">
      <div class="facet-filter show-all">
        <div class="facet-filter__facet" style="width: 100%">
          <div style="display: inline-block">
            <ul class="category-list">
              <template v-for="(actorFacet, taxIndex) in actorFacetsWithoutEmpty">
                <template v-for="(facet, facetIndex) in actorFacet.options">
                  <li
                    class="category"
                    v-if="actorFacet.values && actorFacet.values.includes(facet.id) && (getReportFieldLabel(actorFacet) || getLabelForTaxonomyValue(actorFacet.name, facet.id) || facet.text)"
                    :key="'mobile-facet' + taxIndex + facetIndex"
                  >
                    <button
                      @click="showActorsWithFacet(actorFacet.name, facet)"
                    >
                      {{
                        (getLabelForTaxonomyValue(actorFacet.name, facet.id) || facet.text).split('>').slice(-1)[0].trim()
                      }}
                    </button>
                  </li>
                </template>
              </template>
              <template v-if="actor.tags && actor.tags.length">
                <li
                  class="category" v-for="(tag, index) of actor.tags"
                  :key="'mobile-tag' + index"
                >
                  <button
                    @click="showActorsWithFacet('tag', tag)">
                    <icon name="tag"/>
                    {{ tag.label }}
                  </button>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="facet-filters" v-else>
      <div
        class="facet-filter" :class="{'show-all': showAll[taxonomy.name]}"
        v-for="(taxonomy, index) in actorFacetsWithoutEmpty"
        :key="'taxonomy' + index"
      >
        <div
          class="facet-filter__title"
          :title="facetLabel(taxonomy)"
        >
          {{
            facetLabel(taxonomy)
          }}
        </div>

        <div class="facet-filter__facet">
          <div style="display: inline-block">
            <ul
              class="category-list"
              :ref="taxonomy.parent_id ? taxonomy.name + taxonomy.parent_id : taxonomy.name"
            >
              <template
                v-for="(facet, index) in taxonomy.options" :key="'facet' + index"
              >
                <li
                  class="category"
                  v-if="taxonomy.name.startsWith('report_field_') || (taxonomy.values && taxonomy.values.includes(facet.id) && (getLabelForTaxonomyValue(taxonomy.name, facet.id) || facet.text))"
                >
                  <button
                    @click="showActorsWithFacet(taxonomy.name, facet)">
                    {{
                      (getLabelForTaxonomyValue(taxonomy.name, facet.id) || facet.text || facet).split('>').slice(-1)[0].trim()
                    }}
                  </button>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div
          class="facet-filter__show-more-container"
          v-if="hasExceededLengthLimit(taxonomy.name, 52, taxonomy.parent_id)"
          :key="windowWidth + componentKey"
        >
          <a
            class="facet-filter__show-more-link" href="#"
            @click="toggleShowAll(taxonomy.name)"
          >{{
              showAll[taxonomy.name] ? $t('actor_detail_show_less') : $t('actor_detail_show_all')
            }}</a>
        </div>
      </div>

      <div class="facet-filter">
        <div
          class="facet-filter__title"
          title="tag" v-if="actor.tags && actor.tags.length"
        >
          {{ $t('add_actor_tags') }}
        </div>
        <div class="facet-filter__facet" v-if="actor.tags && actor.tags.length">
          <div style="display: inline-block">
            <ul class="category-list">
              <li
                class="category" v-for="(tag, index) of actor.tags"
                :key="'tag' + index"
              >
                <button
                  @click="showActorsWithFacet('tag', tag)">
                  <icon name="tag"/>
                  {{ tag.label }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import Icon from '@/components/Icon/Icon.vue'
  import { trackHeapEvent } from '@/util/analytics'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '@/store/modules/filters'
  import TranslationsMixin from '@/util/TranslationsMixin'
  import FiltersMixin from '@/util/FiltersMixin'
  import { getReportFieldByReportIdentifier } from '@/util/helpers'
  import { SiteTemplate } from '@/store/modules/config'
  import ReportMixin from '@/util/ReportMixin'

  export default defineComponent({
    name: 'SimplifiedActorDetailFacets',
    mixins: [TranslationsMixin, FiltersMixin, ReportMixin],
    components: {
      Icon
    },
    props: {
      actor: {
        type: Object,
        required: true,
      },
      componentKey: {
        type: Number,
        required: true,
      },
      isMobile: {
        type: Boolean,
        required: true,
      },
      windowWidth: {
        required: true
      }
    },
    data () {
      return {
        showAll: {},
      }
    },
    computed: {
      isProduct () {
        const result = this.actor && this.actor['actor_type'] === 'Product'
        // hack to fix problem with store/modules/filters.js where the current page can't be tracked correctly without an external reference when clicking "next"
        window.isProductPage = !!result
        return result
      },
      actorFacetsWithoutEmpty () {
        if (!this.actorFacets) {
          return []
        }

        return [...this.actorFacets, ...this.actorReportFieldFilters]
          .filter(facet => {
            return facet && facet.values && facet.values.length
          })
      },
      actorFacets () {
        const productControls = this.isSimplifiedTemplateUsed ? this.config.simplifiedFilterControlsProducts : this.config.filterControlsProducts
        const actorControls = this.isSimplifiedTemplateUsed ? this.config.simplifiedFilterControls : this.config.filterControls

        return this.getTaxonomyValuesForFilterControls(this.isProduct ? productControls : actorControls)
      },
      actorReportFieldFilters () {
        const currentReport = this.actor.current_report

        if (!currentReport || !currentReport.length) {
          return []
        }

        const filterableReportFieldIdentifiers = this.filterableReportFieldsForActor.map((field) => 'report_field_' + field.id)

        const actorReportFilters = []

        currentReport
          .map((actorReportItem) => {
            if (!actorReportItem.value || !actorReportItem.value.length) {
              return
            }

            // Get the report field and check if it's an enabled filter
            const reportFieldId = 'report_field_' + actorReportItem.ecosystem_report_field_id

            if (filterableReportFieldIdentifiers.includes(reportFieldId)) {
              actorReportFilters.push({
                name: 'report_field_' + actorReportItem.ecosystem_report_field_id,
                values: Array.isArray(actorReportItem.value) ? actorReportItem.value : [actorReportItem.value],
                options: Array.isArray(actorReportItem.value) ? actorReportItem.value : [actorReportItem.value],
              })
            }
          })

        return actorReportFilters
      },
      config () {
        return this.$store.state.config
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
    },
    methods: {
      facetLabel (facet) {
        if (facet.name.startsWith('report_field_')) {
          return this.getReportFieldLabel(facet)
        }

        return facet.parent_id ? this.getLabelForTaxonomyValue(facet.name, facet.parent_id) : this.getTaxonomyAliasWithDefault(facet.name)
      },
      getReportFieldLabel (facet) {
        const reportField = getReportFieldByReportIdentifier(facet.name)

        if (reportField) {
          return reportField.label
        }

        return ''
      },
      showActorsWithFacet (facetName, feature) {
        trackHeapEvent(this.isProduct ? 'productDetail.showProductsWithFeature' : 'actorDetail.showProductsWithFeature')

        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)

        if (facetName === 'tag') {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_NON_TAXONOMY_FACET, {
            facet: facetName,
            value: feature,
          })
        } else if (facetName.startsWith('report_field_')) {
          this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, {
            reportId: facetName,
            value: feature,
          })
        } else {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: facetName,
            values: [feature.id || feature.value],
          })
        }

        const simplifiedPage = this.isProduct ? '/products-simplified' : '/actors-simplified'
        const advancedPage = this.isProduct ? '/product-gallery' : '/actors'
        const targetPage = this.isSimplifiedTemplateUsed ? simplifiedPage : advancedPage
        this.$router.push(targetPage)
      },
      hasExceededLengthLimit (facetName, customHeight, parentId) {
        const maxHeight = customHeight || 52

        var refName = facetName

        if (parentId) {
          refName += parentId
        }

        if (this.$refs[refName] && this.$refs[refName][0]) {
          return this.$refs[refName][0].offsetHeight > maxHeight
        }
      },
      toggleShowAll (facetName) {
        this.showAll[facetName] = !this.showAll[facetName]
        this.$forceUpdate()
      },
      getTaxonomyValuesForFilterControls (filterControls) {
        const actorFacets = []

        if (!filterControls) {
          return actorFacets
        }

        let taxonomyFacets

        for (const facet of filterControls) {
          // this is hacky/hardcoded because of discrepancies between filter controls and actual taxonomy values / facet values
          switch (facet) {
            case 'category':
            case 'stage':
              if (this.actor[facet]) {
                actorFacets.push({
                  name: facet,
                  values: [this.actor[facet].value],
                  options: [{
                    id: this.actor[facet].value,
                    text: this.actor[facet].label,
                  }],
                })
              }
              break
            case 'activities':
              if (this.actor[facet]) {
                actorFacets.push({
                  name: facet,
                  values: this.actor[facet].map(f => f.value),
                  options: this.actor[facet].map(f => {
                    return { id: f.value, text: f.label }
                  }),
                })
              }
              break
            // NOTE: refactoring domains and industries to generic 2-level hierarchy taxonomies would be a great refactor
            case 'domains':
              if (!this.actor[facet]) {
                break
              }

              taxonomyFacets = this.parseHierarchicalTaxonomyFacetValues(facet, 'parentId')

              if (taxonomyFacets.length > 0) {
                taxonomyFacets.forEach((facet) => {
                  actorFacets.push(facet)
                })
              }

              break
            case 'industries':
              if (!this.actor[facet]) {
                break
              }

              taxonomyFacets = this.parseHierarchicalTaxonomyFacetValues(facet, 'industry_id')

              if (taxonomyFacets.length > 0) {
                taxonomyFacets.forEach((facet) => {
                  actorFacets.push(facet)
                })
              }
              break
            case 'technology':
            case 'product_features_a':
            case 'product_features_b':
            case 'product_features_c':
              if (!this.actor[facet]) {
                break
              }

              taxonomyFacets = this.parseHierarchicalTaxonomyFacetValues(facet)

              if (taxonomyFacets.length > 0) {
                taxonomyFacets.forEach((facet) => {
                  if (facet.options.length > 0 && facet.parent_id) {
                    actorFacets.push(facet)
                  }
                })
              }

              break
            // Filtered properties that are not a taxonomy
            case 'maturity':
              if (this.actor[facet]) {
                actorFacets.push({
                  name: facet,
                  values: [this.actor[facet]],
                  options: [{
                    id: this.actor[facet],
                    text: this.actor[facet],
                  }],
                })
              }

              break
            default:
              break
          }
        }

        return actorFacets
      },
      parseHierarchicalTaxonomyFacetValues (facet, parentKey = 'parent_id') {
        var parentFacets = this.actor[facet].filter(f => !f[parentKey])
        var parentIds = parentFacets.map(f => f.id) || []

        const taxonomyFacetValues = []

        taxonomyFacetValues.push({
          name: facet,
          displayName: facet,
          values: parentFacets.map(feature => feature.id),
          options: this.getOptionsForFacet(facet),
        })

        const additionalParentFacets = []

        parentIds.forEach((parentId) => {
          const facetValue = {
            name: facet,
            displayName: parentFacets.find(f => f.id == parentId).name,
            values: this.actor[facet].filter(f => f[parentKey] == parentId).map(f => f.id),
            options: this.getOptionsForFacet(facet, true),
          }

          facetValue['parent_id'] = parentId

          additionalParentFacets.push(facetValue)
        })

        additionalParentFacets
          .sort((a, b) => {
            if (!a?.displayName) return 1; // Move undefined/null values to the end
            if (!b?.displayName) return -1;
            return a.displayName.localeCompare(b.displayName)
          })
          .forEach((additionalFacet) => {
            taxonomyFacetValues.push(additionalFacet)
          })

        return taxonomyFacetValues
      },
      getOptionsForFacet (productFeature, children = false) {
        switch (productFeature) {
          case 'product_features_a':
            if (children) {
              return this.productFeaturesAChildrenOptions
            }
            return this.productFeaturesAOptions
          case 'product_features_b':
            if (children) {
              return this.productFeaturesBChildrenOptions
            }
            return this.productFeaturesBOptions
          case 'product_features_c':
            if (children) {
              return this.productFeaturesCChildrenOptions
            }
            return this.productFeaturesCOptions
          case 'technology':
            if (children) {
              return this.technologyChildrenOptions
            }
            return this.technologyOptions
          case 'domains':
            if (children) {
              return this.domainsChildrenOptions
            }
            return this.domainsOptions
          case 'industries':
            if (children) {
              return this.industriesChildrenOptions
            }
            return this.industriesOptions
          default:
            return []
        }
      },
    }
  })
</script>
<style lang="scss" scoped>
</style>
