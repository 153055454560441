<template>
  <NewCard title="User behaviour" v-if="allUsers && allUsers.length">
    <div class="user-behaviour_card">
      <p>The graph below shows the user behaviour of the selected user.</p>

      <div class="user-behaviour__filter-items">
        <div class="user-behaviour__filter-item">
          <p>
            <span>User</span>
            <icon v-tooltip.bottom="'Select a user to see his behaviour'" name="information"/>
          </p>
          <Dropdown
            v-model="userId"
            :options="allUsers"
            @update:modelValue="selectedUser"
          />
        </div>

        <div class="user-behaviour__filter-item">
          <p>
            <span>Range</span>
            <icon v-tooltip.bottom="'Select a range to see more specific behaviours (Selected Monthly? use the dropdown at the top of the page to filter per month)'"
                  name="information"/>
          </p>
          <Dropdown
            :disabled="!userId"
            @update:modelValue="changeRange($event)"
            :options="['All', 'Daily', 'Monthly']"
            v-model="range"
          />
        </div>

        <div class="user-behaviour__filter-item">
          <p>
            <span>Activity dates</span>
            <icon v-tooltip.bottom="'These dates are dates that the user had activities (only active when the Daily range option is selected)'" name="information"/>
          </p>
          <Dropdown
            :disabled="range === 'All' || range === 'Monthly'"
            :options="defaultUserBehaviour.dates_visited || []"
            v-model="specificDate"
            @update:modelValue="changeDayDate($event)"
          />
        </div>
        <div style="margin: 37px;">
          <a
            v-tooltip.bottom="'Export the selected users activity log NOTE: filters applied'"
            class="edit-scope-description-button"
            v-if="userId"
            :href="'/api/settings/export-user-behaviour?date='+date+'&user_id='+userId+'&range='+range+'&specificDate='+specificDate"
          >
            <icon name="download"></icon>
          </a>
        </div>
      </div>
    </div>

    <div class="user_behaviour--chart" v-if="behaviour && behaviour.length > 0">
      <plotly :chart="userBehaviourChart" v-if="userBehaviourChart.datasets"></plotly>
    </div>
    <div v-else>
      <span><strong>{{ userName }}</strong> does not have any behaviour. Please select another user.</span>
    </div>

    <br>
    <div v-if="defaultUserBehaviour && defaultUserBehaviour.dates_visited && defaultUserBehaviour.dates_visited.length > 0">
      <h3><u>Extra information of the total activity from the user</u></h3>
      <br/>
      <div>
        <strong>{{ userName }}</strong> logged in on <strong>{{ defaultUserBehaviour.dates_visited.length }}</strong> different days. The most recent login was on
        <strong>{{ defaultUserBehaviour.dates_visited[0] }}</strong>
      </div>

      <div v-if="defaultUserBehaviour && defaultUserBehaviour.most_occuring_activity && mostOccuringActivity.label && mostOccuringActivity.count">
        The most recurring activity from <strong>{{ userName }}</strong> (except the homepage) is <strong>{{ mostOccuringActivity.label }}</strong> with a total of <strong>{{
          mostOccuringActivity.count
        }}</strong> actions.
      </div>

      <div v-if="defaultUserBehaviour && defaultUserBehaviour.average_activity_per_day">
        <strong>{{ userName }}</strong> has an average of <strong>{{ defaultUserBehaviour.average_activity_per_day }} actions every time he/she visits the platform.</strong>
      </div>

      <br/>
      <!--
        'total_visits'
        'total_detail_visits'
        'total_created'
        'total_updated'
      -->
    </div>

    <!--

    Cedric has visited the announcements overview page a total of 232 times, where he created a remarkable 30 announcements.
    Additionally, he explored the detail page of an announcement 67 times,
    seeking to gather more information. Notably,
    Cedric also took the initiative to update 10 announcements.

    -->
    <div v-if="showUserAnnouncementBehaviour">
      <h3><u>Announcements</u></h3>
      <br/>
      <div>
        <span v-if="defaultUserBehaviour.announcements.total_visits > 0 || defaultUserBehaviour.announcements.total_visits.total_created > 0"><strong>{{
            userName
          }}</strong> has visited the announcements overview page a total of <strong
          :style="{'background-color': getColorForBar('announcements'), 'padding': '2px'}">{{ defaultUserBehaviour.announcements.total_visits }}</strong> times, where he created a remarkable
          <strong :style="{'background-color': getColorForBar('create_announcement'), 'padding': '2px'}">{{ defaultUserBehaviour.announcements.total_created }}</strong> announcements.</span>
        <br/>
        <span v-if="defaultUserBehaviour.announcements.total_detail_visits">Additionally, he explored the detail page of an announcement
          <strong :style="{'background-color': getColorForBar('announcements_detail'), 'padding': '2px'}">{{ defaultUserBehaviour.announcements.total_detail_visits }}</strong> times seeking to gather more information.
        </span>
        <br/>
        <span v-if="defaultUserBehaviour.announcements.total_updated > 0">Notably,
        <strong>{{ userName }}</strong> also took the initiative to update <strong :style="{'background-color': getColorForBar('update_announcement'), 'padding': '2px'}">{{
              defaultUserBehaviour.announcements.total_updated
            }}</strong> announcements.</span>
      </div>
    </div>
    <br/>
    <div v-if="showUserContentBehaviour">
      <h3><u>Content</u></h3>
      <br/>
      <div>
        <span v-if="defaultUserBehaviour.content.total_visits > 0 || defaultUserBehaviour.content.total_visits.total_created > 0"><strong>{{
            userName
          }}</strong> has visited the knowledge base <strong
          :style="{'background-color': getColorForBar('knowledge_base'), 'padding': '2px'}">{{ defaultUserBehaviour.content.total_visits }}</strong> times and created as
          many as
          <strong :style="{'background-color': getColorForBar('create_resource'), 'padding': '2px'}">{{
              defaultUserBehaviour.content.total_created
            }}</strong> resources.</span>
        <br/>
        <span v-if="defaultUserBehaviour.content.total_detail_visits"><strong>{{ userName }}</strong> also visited
          <strong :style="{'background-color': getColorForBar('file_detail'), 'padding': '2px'}">{{ defaultUserBehaviour.content.total_detail_visits }}</strong> times the detail page of a resource to
          gain more details.
        </span>
        <br/>
        <span v-if="defaultUserBehaviour.content.total_updated > 0">Last but not least has <strong>{{
            userName
          }}</strong> updated <strong :style="{'background-color': getColorForBar('update_resource'), 'padding': '2px'}">{{
            defaultUserBehaviour.content.total_updated
          }}</strong> resources.</span>
      </div>
    </div>
    <br/>
    <div v-if="showUserActorBehaviour">
      <h3><u>Actors</u></h3>
      <br/>
      <div>
        <span v-if="defaultUserBehaviour.actors.total_visits > 0 || defaultUserBehaviour.actors.total_visits.total_created > 0"><strong>{{
            userName
          }}</strong> has visited the directory <strong
          :style="{'background-color': getColorForBar('directory'), 'padding': '2px'}">{{ defaultUserBehaviour.actors.total_visits }}</strong> times and created as
          many as
          <strong :style="{'background-color': getColorForBar('create_actor'), 'padding': '2px'}">{{
              defaultUserBehaviour.actors.total_created
            }}</strong> actors.</span>
        <br/>
        <span v-if="defaultUserBehaviour.content.total_detail_visits"><strong>{{ userName }}</strong> also visited
          <strong :style="{'background-color': getColorForBar('actor_detail'), 'padding': '2px'}">{{ defaultUserBehaviour.actors.total_detail_visits }}</strong> times the detail page of an actor to
          gain more details.
        </span>
        <br/>
        <span v-if="defaultUserBehaviour.actors.total_updated > 0">Last but not least has <strong>{{
            userName
          }}</strong> updated <strong :style="{'background-color': getColorForBar('update_actor'), 'padding': '2px'}">{{
            defaultUserBehaviour.actors.total_updated
          }}</strong> actors.</span>
      </div>
    </div>

  </NewCard>
</template>

<script>
  import NewCard from '../../../components/NewCard/NewCard.vue'
  import EcosystemInfoCard from './EcosystemInfoCard.vue'
  import ChartLegend from './ChartLegend.vue'
  import SimpleLineChart from '../../Chart/SimpleLineChart.vue'
  import AlternateDropdown from '@/components/Dropdown/AlternateDropdown.vue'
  import { fetchUsers } from '@/api/config'
  import Plotly from '@/components/Chart/Plotly.vue'
  import Dropdown from '@/components/Dropdown/Dropdown.vue'
  import background from '~/webdriverio/lib/protocol/background'

  export default {
    props: {
      behaviour: Object,
      defaultUserBehaviour: Object,
      date: String,
    },
    components: {
      Dropdown,
      Plotly,
      AlternateDropdown,
      NewCard,
      EcosystemInfoCard,
      ChartLegend,
      SimpleLineChart,
    },
    emits: ['user-behaviour-analytics-user-changed', 'fetch-user-behaviour'],
    data () {
      return {
        range: 'All',
        specificDate: null,
        showMostPopularResources: false,
        allUsersData: [],
        loading: false,
        filters: {
          limit: 100,
          offset: null,
          q: null,
          verified: true,
          claimed: null,
          order: null,
          role: null,
          community: this.$route && this.$route.query && this.$route.query.community,
        },
        userId: null,
      }
    },
    computed: {
      showUserAnnouncementBehaviour () {
        return this.defaultUserBehaviour && this.defaultUserBehaviour.announcements && (this.defaultUserBehaviour.announcements.total_created > 0 || this.defaultUserBehaviour.announcements.total_updates > 0 || this.defaultUserBehaviour.announcements.total_visits || this.defaultUserBehaviour.announcements.total_detail_visits)
      },
      showUserContentBehaviour () {
        return this.defaultUserBehaviour && this.defaultUserBehaviour.content && (this.defaultUserBehaviour.content.total_created > 0 || this.defaultUserBehaviour.content.total_updates > 0 || this.defaultUserBehaviour.content.total_visits || this.defaultUserBehaviour.content.total_detail_visits)
      },
      showUserActorBehaviour () {
        return this.defaultUserBehaviour && this.defaultUserBehaviour.actors && (this.defaultUserBehaviour.actors.total_created > 0 || this.defaultUserBehaviour.actors.total_updates > 0 || this.defaultUserBehaviour.actors.total_visits || this.defaultUserBehaviour.actors.total_detail_visits)
      },
      userName () {
        return this.allUsers.filter(user => Number(user.value) === Number(this.userId))[0].label
      },
      mostOccuringActivity () {
        if (this.defaultUserBehaviour.most_occuring_activity && this.defaultUserBehaviour.most_occuring_activity.description && this.defaultUserBehaviour.most_occuring_activity.count) {
          const formattedDescription = this.defaultUserBehaviour.most_occuring_activity.description.replace('_', ' ')
          return {
            label: formattedDescription.charAt(0).toUpperCase() + formattedDescription.slice(1),
            count: this.defaultUserBehaviour.most_occuring_activity.count,
          }
        } else {
          return {
            label: '',
            count: '',
          }
        }
      },
      allUsers () {
        let users = []

        this.allUsersData.forEach((user) => {
          if (user.name && user.verified) {
            users.push({
              label: user.name,
              value: Number(user.id),
            })
          }
        })

        users.sort((a, b) => a.label.localeCompare(b.label))

        if (users.length > 0 && !this.userId) {
          this.userId = users[users.length - 1].value
          this.selectedUser(this.userId)
        }

        return users
      },
      userBehaviourChart () {
        if (!this.behaviour && this.behaviour.length === 0) {
          return {}
        }

        let behaviour = this.behaviour

        return {
          datasets: [{
            x: behaviour.map(datapoint => {
              const formattedDescription = datapoint.description.replace('_', ' ')
              return formattedDescription.charAt(0).toUpperCase() + formattedDescription.slice(1)
            }),
            y: behaviour.map(datapoint => datapoint.count),
            type: 'bar',
            hoverinfo: behaviour.map(datapoint => datapoint.count),
            marker: { 'color': behaviour.map(datapoint => this.getColorForBar(datapoint.description)) },
          }],
          layout: {
            showlegend: false,
            height: 400,
            autoMargin: true,
            margin: { l: 20, r: 20, b: 100, t: 10, pad: 4 },
          },
          plotlyType: 'bar',
        }
      },
    },
    methods: {
      background,
      getColorForBar (description) {
        switch (description) {
          case 'home_page':
            return '#FFBF00'
          case 'actor_detail':
            return '#E83F6F'
          case 'directory':
            return '#2274A5'
          case 'user_settings':
            return '#32936F'
          case 'curation':
            return '#ff00ff'
          case 'update_actor':
            return '#0000ff'
          case 'product_gallery':
            return '#00fa9a'
          case 'file_detail':
            return '#ffff00'
          case 'announcements':
            return '#00ffff'
          case 'announcements_detail':
            return '#dcbeff'
          case 'update_announcement':
            return '#808000'
          case 'create_actor':
            return '#ffd8b1'
          case 'create_announcement':
            return '#fabed4'
          case 'challenges_overview':
            return '#800000'
          case 'products_simplified':
            return '#911eb4'
          case 'actors_simplified':
            return '#fffac8'
          case 'knowledge_base':
            return '#ff0000'
          case 'create_resource':
            return '#ffb5a1'
          case 'update_resource':
            return '#a1ffae'
          default:
            return '#000000'
        }
      },
      convertUrl (pageUrl) {
        return `${window.location.protocol}//${window.location.hostname}${pageUrl}`
      },
      getAllUsers () {
        this.loading = true
        fetchUsers(this.filters)
          .then((response) => {
            this.loading = false

            this.allUsersData = response.data
          })
          .catch(error => {
            this.loading = false
          })
      },
      selectedUser (event) {
        this.userId = Number(event)
        this.$emit('user-behaviour-analytics-user-changed', { user_id: this.userId, range: this.range, specificDate: this.specificDate })
        this.$emit('fetch-user-behaviour', { user_id: this.userId })
      },
      changeRange (event) {
        this.range = event
        if (this.range === 'All' || this.range === 'Monthly') {
          this.specificDate = null
        }

        this.$emit('user-behaviour-analytics-user-changed', { user_id: this.userId, range: this.range, specificDate: this.specificDate })
      },
      changeDayDate (event) {
        this.specificDate = event
        this.$emit('user-behaviour-analytics-user-changed', { user_id: this.userId, range: this.range, specificDate: this.specificDate })
      },
    },
    mounted () {
      this.getAllUsers()
    },
    watch: {
      defaultUserBehaviour () {
      },
    },
  }
</script>
<style scoped lang="scss">
  .user-behaviour_card {
    display: flex;
    justify-content: space-between;
  }

  .user-behaviour__filter-items {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;

    .user-behaviour__filter-item {
      width: 100%;
      margin-right: 10px;

      p {
        margin-bottom: 0;
        justify-content: space-between;
        display: flex;
        padding: 5px;
      }
    }
  }

  /*  .user_behaviour--chart {
      :deep(.legend) {
        display: none;
      }
    }*/
</style>
