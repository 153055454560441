<template>
  <div
    :class="{'rich-text-area-simplified': isSimplified}" class="notranslate"
    translate="no"
  >
    <div class="editor">
      <div v-if="communityId" id="data-community-id" style="display: none;">{{ communityId }}</div>
      <div class="editor-buttons" v-if="editor">
        <button
          @click.prevent="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }" class="rta__button">
          <icon name="bold"/>
        </button>
        <button
          @click.prevent="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }" class="rta__button">
          <icon name="italic"/>
        </button>
        <button
          @click.prevent="editor.chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
          class="rta__button">
          <icon name="underline"/>
        </button>
        <button
          @click.prevent="editor.chain().focus().toggleHeading({level: 1}).run()"
          :class="{ 'is-active': editor.isActive('heading', {level: 1}) }"
          class="rta__button">
          H1
        </button>
        <button
          @click.prevent="editor.chain().focus().toggleHeading({level: 2}).run()"
          :class="{ 'is-active': editor.isActive('heading', {level: 2}) }"
          class="rta__button">
          H2
        </button>
        <button
          @click.prevent="editor.chain().focus().toggleHeading({level: 3}).run()"
          :class="{ 'is-active': editor.isActive('heading', {level: 3}) }"
          class="rta__button">
          H3
        </button>
        <button @click.prevent="editor.commands.toggleBulletList" class="rta__button">
          <icon name="bullet-list"/>
        </button>
        <button @click.prevent="editor.commands.toggleOrderedList" class="rta__button">
          <icon name="ordered-list"/>
        </button>
        <button @click.prevent="addImage" class="rta__button">
          <icon name="menubar-image"/>
        </button>
        <button @click.prevent="setLink" :class="{ 'is-active': editor.isActive('link') }" class="rta__button">
          <icon name="link"/>
        </button>
        <button
          @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')"
          :style="editor.isActive('link') ? 'cursor: pointer' : 'cursor: no-drop'"
          class="rta__button">
          <icon name="link-slash"/>
        </button>
        <button v-if="false" @click.prevent="enlargenTextbox" class="rta__button"
                :title="!expandTextbox ? 'Expand the textbox' : 'Shrink the textbox'">
          <icon :name="!expandTextbox ? 'arrow-expand' : 'arrow-shrink'"/>
        </button>
        <button class="rta__button" title="Undo" @click="editor.chain().focus().undo().run()"
                :disabled="!editor.can().undo()"
        >
          <icon :name="'arrow-rotate-left'"/>
        </button>
        <button class="rta__button" title="Redo" @click="editor.chain().focus().redo().run()"
                :disabled="!editor.can().redo()"
        >
          <icon :name="'arrow-rotate-right'"/>
        </button>
        <button
          @click.prevent="showInfo"
          :class="{ 'is-active': editor.isActive('info') }" class="rta__button">
          <!--<icon name="light-bulb"/>-->
          <icon name="information-outline"/>
        </button>
      </div>
      <editor-content
        :class="{'editor__content': true, 'enlargen-textbox': expandTextbox, 'textarea': true, 'has-placeholder': (currentContentLength === 0), 'has-image-placeholder': (false && currentContentLength === 0)}"
        :data-editor-custom-placeholder="editorPlaceholder" :editor="editor" style="resize: none;"/>

      <!--<div class="info-message__text">
        Elevate your conversation by mentioning profiles! Just start typing '@' and follow it with the user's or actor's name to seamlessly tag them
      </div>-->

      <!-- Show character count -->
      <div v-if="false && editor"
           :class="{'character-count': true, 'character-count--warning': editor.storage.characterCount.characters() === limit}">
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          class="character-count__graph"
        >
          <circle
            r="10"
            cx="10"
            cy="10"
            fill="#e9ecef"
          />
          <circle
            r="5"
            cx="10"
            cy="10"
            fill="transparent"
            stroke="currentColor"
            stroke-width="10"
            :stroke-dasharray="`calc(${percentage} * 31.4 / 100) 31.4`"
            transform="rotate(-90) translate(-20)"
          />
          <circle
            r="6"
            cx="10"
            cy="10"
            fill="white"
          />
        </svg>

        <div class="character-count__text">{{ editor.storage.characterCount.characters() }}/{{ limit }} characters</div>
      </div>

      <span class="message-characters-required" v-if="currentContentLength < minLength">At least {{
          minLength - currentContentLength
        }} characters are required.</span>
    </div>
  </div>
</template>

<script>
  import getVideoId from 'get-video-id'

  import tippy from 'tippy.js'

  import DsButton from '../DsButton/DsButton.vue'
  import { Editor, EditorContent } from '@tiptap/vue-3'
  import CharacterCount from '@tiptap/extension-character-count'
  import StarterKit from '@tiptap/starter-kit'
  import Dropcursor from '@tiptap/extension-dropcursor'
  import Underline from '@tiptap/extension-underline'
  import ActorMention from '../../tiptap/ActorMention.js'
  import ActorUserMention from '../../tiptap/ActorUserMention.js'
  import { Mention } from '@tiptap/extension-mention'
  import { Link } from '@tiptap/extension-link'
  import TipTapImage from '@tiptap/extension-image'
  import { defineComponent } from 'vue'
  import { createFileRequest } from '@/api/create-request'

  export default defineComponent({
    props: {
      isSimplified: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        type: String,
      },
      minLength: {
        type: Number,
        default: null,
      },
      currentContentLength: {
        type: Number,
        default: null,
      },
      contentClass: {
        type: String,
      },
      minimal: {
        type: Boolean,
        default: false,
      },
      hideActions: {
        type: Boolean,
        default: false,
      },
      meta: {
        type: Object,
        default: () => null,
      },
      customPlaceholder: {
        type: String,
        default: '',
      },
      autoFocus: {
        type: Boolean,
        default: false,
      },
      allowDragAndDrop: {
        type: Boolean,
        default: true,
      },
      alsoAllowUserMentions: {
        type: Boolean,
        default: false,
      },
      communityId: {
        type: String,
        default: null
      }
    },
    components: {
      EditorContent,
      DsButton,
    },
    data () {
      return {
        editor: null,
        query: null,
        suggestionRange: null,
        suggestedActors: [],
        navigatedActorIndex: 0,
        insertMention: () => {
        },
        observer: null,
        isSearching: false,
        expandTextbox: false,
        linkUrl: null,
        linkMenuIsActive: false,
        disabled: false,
        currentImages: 0,
        limit: 99999,
      }
    },
    computed: {
      hasResults () {
        return this.suggestedActors.length
      },
      showSuggestions () {
        return this.query || this.hasResults
      },
      editorPlaceholder () {
        return this.customPlaceholder ? this.customPlaceholder : this.$t('textarea_tell_us_more')
      },
      percentage () {
        return Math.round((100 / this.limit) * this.editor.storage.characterCount.characters())
      },
    },
    methods: {
      enlargenTextbox () {
        this.expandTextbox = !this.expandTextbox
      },
      showInfo () {
        const url = window.alert('Elevate your conversation by mentioning profiles! Just start typing \'@\' and follow it with the user\'s or actor\'s name to seamlessly tag them')
      },
      addImage () {
        const url = window.prompt('Please provide an URL. You can also drag&drop images into the message field.')

        if (url) {
          this.editor.chain().focus().setImage({ src: url }).run()
        }
      },
      setLink () {
        const previousUrl = this.editor.getAttributes('link').href
        let url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
          return
        }

        // empty
        if (url === '') {
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

          return
        }

        // Check if the URL has a protocol, if not, prepend "https://"
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'https://' + url;
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
      },
      // navigate to the previous item
      // if it's the first item, navigate to the last one
      upHandler () {
        this.navigatedActorIndex = ((this.navigatedActorIndex + this.suggestedActors.length) - 1) % this.suggestedActors.length
      },
      // navigate to the next item
      // if it's the last item, navigate to the first one
      downHandler () {
        this.navigatedActorIndex = (this.navigatedActorIndex + 1) % this.suggestedActors.length
      },
      // @deprecated
      enterHandler () {
        const actor = this.suggestedActors[this.navigatedActorIndex]
        if (actor) {
          this.selectActor(actor)
        }
      },
      // @deprecated
      // we have to replace our suggestion text with a mention
      // so it's important to pass also the position of your suggestion text
      selectActor (actor) {
        this.insertMention({
          range: this.suggestionRange,
          attrs: {
            id: actor.id,
            label: actor.name,
          },
        })
        this.editor.focus()
      },
      // renders a popup with suggestions
      // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
      renderPopup (node) {
        if (this.popup) {
          return
        }

        this.popup = tippy(node, {
          content: this.$refs.suggestions,
          trigger: 'mouseenter',
          interactive: true,
          theme: 'dark',
          placement: 'top-start',
          inertia: true,
          duration: [400, 200],
          showOnInit: true,
          arrow: true,
          arrowType: 'round',
        })
        // we have to update tippy whenever the DOM is updated
        if (MutationObserver) {
          this.observer = new MutationObserver(() => {
            this.popup.popperInstance.scheduleUpdate()
          })

          this.observer.observe(this.$refs.suggestions, {
            childList: true,
            subtree: true,
            characterData: true,
          })
        }
      },
      destroyPopup () {
        if (this.popup) {
          this.popup.destroy()
          this.popup = null
        }
        if (this.observer) {
          this.observer.disconnect()
        }
      },
      showPrompt (command, text) {
        const src = prompt(text)

        if (src !== null) {
          console.log(src, 'src from prompt')
          command({ src })
        }
      },
      showLinkPrompt (command, text) {
        var src = prompt(text)

        if (src && !src.match(/^[a-zA-Z]+:\/\//)) {
          src = 'http://' + src
        }

        if (src !== null) {
          command({ href: src })
        }
      },
      showYouTubePrompt (command) {
        const url = prompt('Enter the URL of your YouTube video here:')
        if (!url) {
          return
        }

        const videoId = getVideoId(url)

        const finalUrl = {
          youtube: `https://www.youtube.com/embed/${videoId.id}`,
          vimeo: `https://player.vimeo.com/video/${videoId.id}`,
        }[videoId.service] || url

        command({ src: finalUrl })
      },
    },
    mounted () {
      this.editor = new Editor({
        extensions: [
          StarterKit,
          CharacterCount.configure({
            limit: this.limit,
          }),
          Dropcursor.configure({
            width: 2,
            class: 'dropcursor-class',
          }),
          Underline,
          TipTapImage.configure({
            allowBase64: false,
          }),
          Link.configure({
            autolink: true,
            HTMLAttributes: {
              // Remove target entirely so links open in current tab
              target: null,
            },
          }),
          Mention.configure({
            suggestion: this.alsoAllowUserMentions ? ActorUserMention : ActorMention,
          }),
        ],
        onUpdate: () => {
          if (this.meta) {
            this.$emit('update:modelValue', {
              html: this.editor.getHTML(),
              meta: this.meta,
            })
          } else {
            this.$emit('update:modelValue', this.editor.getHTML())
          }
        },
        // tell ProseMirror to ignore drop event
        editorProps: {
          handleDrop: function (view, event, slice, moved) {
            // see original code article on https://www.codemzy.com/blog/tiptap-drag-drop-image
            if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) { // if dropping external files
              let file = event.dataTransfer.files[0]; // the dropped file
              let filesize = ((file.size / 1024) / 1024).toFixed(4); // get the filesize in MB

              // Only allow valid images (max. 5M)
              if ((file.type !== 'image/jpeg' && file.type !== 'image/png') || filesize > 5) {
                window.alert('Images need to be in jpg or png format and less than 5MB in size.');

                return true
              }

              let _URL = window.URL || window.webkitURL;
              let img = new Image()
              img.src = _URL.createObjectURL(file);
              img.onload = function () {
                // Upload the image to the server
                new Promise((resolve, reject) => {
                  // crf. FileInput.vue
                  const form = new window.FormData()

                  form.append('files[]', file)
                  form.append('title', 'File')
                  form.append('is_private', '0')

                  const urlPath = '/api/files' // Default path to create files on an ecosystem level

                  createFileRequest({
                    url: urlPath,
                    body: form
                  })
                    .then(value => {
                      const filePath = value[0].banner
                      // const questionIndex = filePath.indexOf('?')
                      // const finalPath = questionIndex >= 0 ? filePath.substring(0, questionIndex) : filePath

                      const finalPath = window.config.url + urlPath + '/' + value[0].id

                      resolve(finalPath);
                    })
                    .catch(errors => {
                      console.log(errors)
                    })
                }).then(function (response) {
                  // pre-load the image before responding so loading indicators can stay and swaps out smoothly when image is ready
                  let image = new Image();
                  image.src = response;

                  image.onload = function () {
                    // place the now uploaded image in the editor where it was dropped
                    const { schema } = view.state;
                    const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
                    const node = schema.nodes.image.create({ src: response }); // creates the image element
                    const transaction = view.state.tr.insert(coordinates.pos, node); // places it in the correct position
                    return view.dispatch(transaction);
                  }
                }).catch(function (error) {
                  window.alert('There was a problem uploading your image, please try again.');
                });
              }

              return true
            }

            return false;
          },
          handleDOMEvents: {
            drop: (view, e) => {
              if (!this.allowDragAndDrop) {
                e.preventDefault()
              }
            },
          },
        },
        // hide the drop position indicator
        dropCursor: this.allowDragAndDrop ? {} : { width: 0, color: 'transparent' },
        autofocus: this.autoFocus,
      })
      this.editor.commands.setContent(this.modelValue)

      this.$emit('editor', this.editor)
    },
    watch: {
      modelValue: function (value) {
        if (this.editor.getHTML() !== value) {
          this.editor.commands.setContent(value)
        }
      },
    },
    beforeUnmount () {
      setTimeout(() => {
        this.editor.destroy()
      }, 2000)
      // Wait for sidepanel animation
    },
  })

  function extractYouTubeId (url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    const match = url.match(regExp)
    return (match && match[7].length === 11) ? match[7] : undefined
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .rta__button {
    cursor: pointer;
    border-top-right-radius: 4px;
    background-color: transparent;
  }

  .is-active.rta__button {
    color: tomato;
  }

  .enlargen-textbox {
    .ProseMirror {
      min-height: 600px;
    }
  }

  .ProseMirror {
    &:focus {
      outline: none;
    }

    min-height: 200px;
    height: 100%;

    padding-top: 0.7rem;

    ul, ol {
      margin-left: 1rem;
    }

    li {
      list-style: square;
    }

    .menububble.is-active {
      opacity: 1;
      visibility: visible;
    }

    .menububble__button:last-child {
      margin-right: 0;
    }

    .menububble__button {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      background: transparent;
      border: 0;
      color: #fff;
      padding: .2rem .5rem;
      margin-right: .2rem;
      border-radius: 3px;
      cursor: pointer;
    }

    .menububble {
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      z-index: 20;
      background: #000;
      border-radius: 5px;
      padding: .3rem;
      margin-bottom: .5rem;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;
      -webkit-transition: opacity .2s, visibility .2s;
      transition: opacity .2s, visibility .2s;
    }

    .menububble__form {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  .editor {
    .editor-buttons {
      background-color: var(--primary-community-extra-lightest);
      width: 95%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 0.25rem;
    }

    button {
      border: 0;
      margin-right: 2px;
      font-family: $font-stack-secondary;
      padding: 4px;

      .svg-icon {
        height: 10px;
        margin-bottom: -1px;
      }
    }

    .editor__content {
      // this prevents the textarea from collapsing during the sidepanel animation
      max-height: 216px;
      overflow: auto;

      &.enlargen-textbox {
        max-height: 616px;
      }
    }
  }

  .announcement-detail-new {
    .ProseMirror {
      min-height: 20px;
      padding-top: 0px;
    }

    .announcement-new-comment_form {
      display: flex;
      width: 100%;

      .newcomment__input {
        width: 100%;

        .textarea {
          border: none;
        }
      }
    }

    .rich-text-area-simplified {
      .editor__content.has-placeholder::after {
        top: 16px;
      }

      .rich-text-area-actions {
        background: var(--primary-extra-lightest);

        .button {
          background-color: var(--primary-extra-lightest);
          fill: var(--primary);
          color: var(--primary);

          circle, path {
            fill: var(--primary);
          }

          &:hover {
            background-color: var(--primary);
            fill: var(--primary-extra-lightest);
            color: var(--primary-extra-lightest);

            circle, path {
              fill: var(--primary-extra-lightest);
            }
          }
        }
      }
    }
  }

  .rich-text-area-simplified {
    .rich-text-area-actions {
      background: var(--primary-lightest);
      padding: 2px 2px 2px 7px;
      border-radius: $simplified-input-border-radius $simplified-input-border-radius 0 0;

      .button {
        margin-top: -0.5rem; // cancel out top: 0.5rem on some of the size="tiny" buttons

        background: transparent;
        fill: var(--primary);
        color: var(--primary);

        circle, path {
          fill: var(--primary);
        }

        &:hover {
          fill: var(--primary);
          color: var(--primary);

          circle, path {
            fill: var(--primary);
          }
        }
      }
    }

    .editor {
      position: relative;
    }

    .message-characters-required {
      position: absolute;
      bottom: 2%;
      right: 2%;
      color: $color-error-light;
      font-size: 11px;
    }

    .editor__content.has-placeholder {
      position: relative;
    }

    .editor__content.has-image-placeholder {
      background-image: url('/images/placeholder-upload-image.png');
      background-size: 200px;
      background-position: center 30px;
      background-repeat: no-repeat;
    }

    .editor__content.has-placeholder::after {
      content: attr(data-editor-custom-placeholder);
      pointer-events: none;
      position: absolute;
      right: 8px;
      top: 13%;
      left: 2%;
      transform: translateY(-50%);
      color: var(--primary-community-lighter);
    }

    .editor__content.textarea {
      border-color: var(--primary-community-lightest);
      border-radius: 0 0 $simplified-input-border-radius $simplified-input-border-radius;
      border-width: 2px;
    }
  }

  .dropcursor-class {
    background-color: var(--primary);
    color: var(--primary);
  }

  .info-message {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    color: #68CEF8;

    &__text {
      color: #868e96;
    }
  }

  .character-count {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    color: #68CEF8;

    &--warning {
      color: #FB5151;
    }

    &__graph {
      margin-right: 0.5rem;
    }

    &__text {
      color: #868e96;
    }
  }

</style>
