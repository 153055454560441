<template>
  <div class="ecosystem-member-communities has-action-bar">
    <div class="communities-announcements-permission">
      <checkbox
        v-model="toggleAnnouncementsPermissionForCommunities"
        :label="`Enable ${announcementsAndEventsLabel}`"
        :disabled="!areMessageBoardsEnabled"
      />
      <ds-button
        class="button button--secondary"
        :label="labelSaveCommunitiesAnnouncementsAddOn"
        @click="updateAnnouncementsPermissionForCommunities"
      />
    </div>
    <div v-if="fetchingContent" style="display: flex; justify-content: center;">
      <icon name="spinner"/>
    </div>
    <div v-else>
      <div class="pull-right side-panel__communities-header-button-container" style="text-align: right;">
        <div class="communities-list-container">
          <dropdown
            new-prepend-icon="fort-awesome"
            new-append-icon="chevron-down"
            icon-fill="white"
            v-model="selectedDropdownOption"
            :options="availableCommunities"
            style="width: 100%"
            v-if="availableCommunities.length > 0"
          />
        </div>
        <ds-button label="Create New Community" icon="plus" @click="displayCreateCommunityModal()"/>
        <ds-button
          icon="trash"
          @click="destroy(selectedCommunity)"
          v-if="selectedCommunity && selectedCommunity.id > 0"
        />
      </div>

      <div v-if="!communities.length">
        <span>Create your first community!</span>
      </div>

      <div v-else>
        <div v-if="selectedCommunity && selectedCommunity.id !== -1">
          <h3 class="h3">Community Information</h3>
          <div class="ecosystem-member-communities__general-container" style="margin-top: 1rem; width: 40%">
            <div class="ecosystem-member-communities__general-info">
              <div>
                <form-group label="Community name">
                  <ds-input required v-model="selectedCommunity.name"/>
                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.name">
                      <span>{{ errors.name[0] }}</span>
                    </div>
                  </div>
                </form-group>

                <form-group label="Community description">
                  <ds-textarea
                    placeholder="Provide a description of your community" required
                    v-model="selectedCommunity.description" class="communities__description"
                  />
                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.description">
                      <span>{{ errors.description[0] }}</span>
                    </div>
                  </div>
                </form-group>
              </div>

              <form-group style="margin-top: 1rem;" label="Draft"
                          instructions="Draft communities will be kept hidden from the general public.">
                <dropdown
                  v-model="selectedCommunity.draft"
                  :options="[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]"
                />
              </form-group>

              <form-group style="margin-top: 1rem;" label="Visibility"
                          instructions="Public communities are visible to all users. Private only to members.">
                <dropdown
                  v-model="selectedCommunity.visibility"
                  :options="visibilityOptions"
                />
              </form-group>

              <form-group
                style="margin-top: 1rem;"
                label="Accessibility"
                info-text="Members of the community will need to adhere to the access level set here. If the access level requires a claimed profile, users will need to claim an actor profile even though they are members of the community."
                instructions="Define which type of users can access the community"
              >
                <dropdown
                  v-model="selectedCommunity.accessLevel"
                  :options="accessLevelOptions"
                />
              </form-group>

              <form-group label="Community manager" style="margin-top: 1rem;">
                <autocomplete-dropdown
                  is-simplified
                  marginTop="10px"
                  v-model="selectedCommunity.communityManager"
                  :do-search="autocompleteSearchTerm"
                  :placeholder="$t('communities_search_for_users')"
                  :suggestions="displayedUserSuggestions"
                  :allow-clear="false"
                  :multiple="false"
                />
              </form-group>

              <form-group label="Community image">
                <image-input
                  width="512"
                  height="288"
                  secondLabel="test"
                  :allow-remove="true"
                  :placeholder="$t('community_image_placeholder')"
                  :is-simplified="true"
                  v-model="selectedCommunity.image"
                  @input="selectedCommunity.image_name = $event"
                  @remove="selectedCommunity.image = ''"
                />
              </form-group>

              <form-group label="Community colour" style="width:650px;">
                <color-picker
                  style="width: 50%;"
                  title="Community Colour"
                  :colors="{}"
                  :initial-value="selectedCommunity.color"
                  @updatePrimary="updatePrimaryColor"
                />
              </form-group>
            </div>
          </div>

          <action-bar :editing="isEditingCommunities">
            <ds-button variant="primary" label="Save Community" @click="updateCommunity()" :disabled="saving"/>
            <span class="action-bar__message" v-if="errors && errors.message">{{ errors.message }}</span>
            <span class="action-bar__message" v-else-if="saved && !saving">Community has been updated</span>
          </action-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { deleteCommunity, ManageCommunities, updateCommunitiesAnnouncementsAddOn } from '../../api/communities'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { searchUser } from '../../api/config'
  import MODAL_IDS from '../../constants/modal-ids'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import ActionBar from '../Form/ActionBar.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'

  import isEqual from 'lodash/isEqual'
  import LabelFilter from '../Filters/LabelFilter.vue'
  import ColorPicker from '../Form/ColorPicker.vue'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import { ACTION_TYPES as CONFIG_ACTION_TYPES } from '../../store/modules/config'
  import { ACTION_TYPES as COMMUNITY_ACTION_TYPES, MUTATION_TYPES as COMMUNITY_MUTATION_TYPES } from '../../store/modules/communities'
  import { defineComponent } from 'vue'
  import CommunityMixin from '../../util/CommunityMixin'

  export default defineComponent({
    name: 'ManageCommunities',
    data() {
      return {
        fetchingContent: false,
        savingCommunitiesAnnouncementsAddOn: false,
        savedCommunitiesAnnouncementsAddOn: false,
        communitiesAnnouncementsAddOnEnabled: null,
        communitiesToManage: [],
        selectedCommunity: null,
        modifiedCommunity: this.selectedCommunity,
        saving: false,
        saved: false,
        errors: {},
        userSuggestions: [],
        minTitleLength: 2,
        minBodyLength: 10,
      }
    },
    computed: {
      areMessageBoardsEnabled() {
        return this.$store.getters.areMessageBoardsEnabled
      },
      labelSaveCommunitiesAnnouncementsAddOn() {
        if (this.savingCommunitiesAnnouncementsAddOn) {
          return 'Saving...'
        }

        if (this.savedCommunitiesAnnouncementsAddOn) {
          return 'Saved!'
        }

        return 'Save'
      },
      toggleAnnouncementsPermissionForCommunities: {
        get() {
          return this.communitiesAnnouncementsAddOnEnabled || this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities
        },
        set(value) {
          this.communitiesAnnouncementsAddOnEnabled = value
        }
      },
      isEditingCommunities() {
        return !isEqual(this.modifiedCommunity, this.selectedCommunity)
      },
      displayedUserSuggestions() {
        return this.userSuggestions
      },
      availableCommunities() {
        if (!this.communitiesToManage || this.communitiesToManage.length === 0) {
          return []
        }

        return this.communitiesToManage.map(Community => {
          return {
            value: Community.id,
            label: Community.name,
          }
        })
      },
      selectedDropdownOption: {
        get() {
          if (!this.selectedCommunity || !this.selectedCommunity.id) {
            return null
          }

          return {
            value: this.selectedCommunity.id,
            label: this.selectedCommunity.name,
          }
        },
        set(communityId) {
          const community = this.communitiesToManage.find(p => p.id == communityId)
          this.selectedCommunity = community

          if (this.selectedCommunity.image) {
            this.selectedCommunity.image = window.location.protocol + '//' + window.location.hostname + '/api/communities/' + this.selectedCommunity.id + '/image'
          }

          this.selectedCommunity.description = this.selectedCommunity.description.replace('<p>', '').replace('</p>', '')

          if (this.selectedCommunity.manager_id != null) {
            this.selectedCommunity.manager = community.manager_data.name + ' (' + community.manager_data.email + ')'
          }
          this.errors = {}
        },
      },
    },
    methods: {
      updatePrimaryColor(newValue) {
        this.selectedCommunity.color = newValue
      },
      displayCreateCommunityModal() {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CREATE_COMMUNITY)
      },
      removeDuplicates(suggestions) {
        return suggestions
      },
      confirmDeleteCommunity(communityId) {
        deleteCommunity({ communityId: communityId })
          .then(response => {
            this.$store.commit(COMMUNITY_MUTATION_TYPES.FLUSH_CACHE)

            this.fetchCommunities()
          })
          .catch(error => {
            console.log(error)
          })
      },
      updateCommunity() {
        this.saving = true
        this.saved = false
        this.errors = {}

        var communityId = this.selectedCommunity.id

        const newCommunity = { ...this.selectedCommunity }
        newCommunity.communityManagerId = newCommunity.communityManager.value

        ManageCommunities
          .post(newCommunity)
          .then(response => {
            this.saved = true
            this.saving = false

            this.$bus.emit('communityUpdated', { id: communityId })
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
          .finally(() => {
            this.saving = false
          })
      },
      destroy(Community) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          name: Community.title,
          resource: Community,
          modalContextType: 'community',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      updateAnnouncementsPermissionForCommunities() {
        this.savingCommunitiesAnnouncementsAddOn = true
        updateCommunitiesAnnouncementsAddOn(this.communitiesAnnouncementsAddOnEnabled)
          .then(response => {
            this.savingCommunitiesAnnouncementsAddOn = false

            if (response.updated) {
              this.savingCommunitiesAnnouncementsAddOn = false
              this.savedCommunitiesAnnouncementsAddOn = true
              this.$store.dispatch(CONFIG_ACTION_TYPES.REFRESH_CONFIG)

              setTimeout(() => {
                this.savedCommunitiesAnnouncementsAddOn = false
              }, 1000)
            }
          })
          .catch(error => {
            this.savingCommunitiesAnnouncementsAddOn = false
            console.log(error)
          })
      },
      fetchCommunities(selectId = null) {
        this.fetchingContent = true

        this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITIES_LIST)

        ManageCommunities
          .get()
          .then(response => {
            this.communitiesToManage = response.map(c => {
              let community = { ...c }

              if (community.communityManager?.id) {
                community.communityManager = {
                  value: community.communityManager.id,
                  label: community.communityManager.name + ' (' + community.communityManager.email + ')',
                }
              }

              return community
            })

            // Initial load use case
            if (!this.selectedDropdownOption && this.communitiesToManage.length > 0) {
              this.selectedDropdownOption = this.communitiesToManage[0].id
            }

            // Update / Create use case
            if (selectId) {
              this.selectedDropdownOption = selectId
              return
            }

            // Delete use case
            if (this.selectedDropdownOption && !this.communitiesToManage.find(c => c.id == this.selectedDropdownOption)) {
              this.selectedDropdownOption = this.communitiesToManage.length > 0 ? this.communitiesToManage[0].id : null
            }
          })
          .catch(errors => {
            console.log(errors)
          })
          .finally(() => {
            this.fetchingContent = false
          })
      },
      autocompleteSearchTerm(query) {
        return searchUser({
          query: query,
          role: 'member,owner,portfolio_member',
        })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions
              .forEach(function (user) {
                options.push({
                  label: user.name + ' (' + user.email + ')',
                  value: user.id,
                })
              })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
    mixins: [TranslationsMixin, CommunityMixin],
    components: {
      LabelFilter,
      AutocompleteDropdown,
      Dropdown,
      ActionBar,
      ImageInput,
      Checkbox,
      DsTextarea,
      ColorPicker,
    },
    mounted() {
      if (!this.$store.getters.hasAccessToCommunities) {
        this.$router.push('/')
      }

      this.fetchCommunities()

      this.$bus.on('communityCreated', (context) => {
        this.$store.commit(COMMUNITY_MUTATION_TYPES.FLUSH_CACHE)

        this.fetchCommunities(context.id)
      })

      this.$bus.on('communityUpdated', (context) => {
        this.$store.commit(COMMUNITY_MUTATION_TYPES.FLUSH_CACHE)

        this.fetchCommunities(context.id)
      })

      this.$bus.on('communityDeleteConfirmation', (context) => {
        this.confirmDeleteCommunity(context.resource.id)
      })
    },
    beforeUnmount() {
      this.$bus.off('communityCreated')
      this.$bus.off('communityUpdated')
      this.$bus.off('communityDeleted')
      this.$bus.off('communityDeleteConfirmation')
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .communities-announcements-permission {
    position: absolute;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    right: 10px;
    top: 20px;
    width: 350px;
    display: flex;

    label {
      margin-right: 10px;
    }
  }

  .ecosystem-member-communities {
    .side-panel__communities-header-button-container {
      display: flex;
    }

    .communities__description {
      min-height: 75px;
      margin-bottom: 20px;
    }

    .communities-list-container {
      display: flex;
      align-items: center;
      color: white;
      background-color: var(--primary);
      margin-right: 10px;

      .icon-button {
        margin: 0px 10px;
        color: white;
      }

      .multiselect.multiselect--datascouts .multiselect__tags {
        border: 0;
        color: white;
        background-color: var(--primary);

        span {
          color: white;
          background-color: var(--primary);
        }
      }
    }
  }
</style>
