<template>
  <div class="user-information">
    <div v-if="isUserDataLoading">
      <loading/>
    </div>
    <div v-else>
      <div class="user-information-user-info_layout">
        <DsButton
          icon="x"
          variant="tertiary"
          size="extra-small"
          @click="hideUserInformationPanel"
        />
        <div class="user-information-user-info__header">
          <div class="user-information-avatar">
            <avatar
              variant="border"
              :src="user.photo_url"
              :alt="user.name"
            />
            <div class="user-information-avatar__info">
              <h3>{{ user.name }}</h3>
              <p>{{ user.department }}</p>
            </div>
          </div>
        </div>
        <div class="user-information-user">
          <div
            class="user-information-user__bio"
            v-if="user.bio"
          >
            <h3>Biography</h3>
            <p
              class="bio"
              :class="{'full-bio': showFullBio}"
            >{{ user.bio }}</p>
            <div
              v-if="user.bio && user.bio.length >= '150'"
              class="user-information-user__bio-see-more"
              @click="showFullBio = !showFullBio"
            >
              <p>{{ showFullBio ? 'see less' : 'see more' }}</p>
              <icon
                size="18"
                :name="showFullBio ? 'chevron-up' : 'chevron-down'"
              />
            </div>
          </div>
          <div v-else>The user's profile remains incomplete</div>
          <div class="user-information-user-info__personal">
            <div class="user-information-user-info__personal_info">
              <template v-for="(info, index) in userProfileColumns[0]">
                <div
                  v-if="info.show"
                  class="user-information-user-info__personal_layout"
                >
                  <h3>{{ info.label }}</h3>
                  <p v-if="info.label !== 'Phone Number'">{{ info.value }}</p>

                  <div
                    class="user-information-user-info_phoneNumber"
                    v-else
                  >
                    <div class="hiding">
                      <p :class="{'add-background': $store.state.ui.sidePanel.metaData.highlightPhoneNumber}">{{ info.value }}</p>
                      <div
                        v-if="hidePhoneNumber"
                        class="hide"
                      ></div>
                      <div class="eye-icon" :class="{'hide-eye-icon': $store.state.ui.sidePanel.metaData.highlightPhoneNumber}">
                        <icon
                          @click="toggleShow"
                          :name="showEyeIcon"
                          size="18"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>

            </div>
            <div class="user-information-user-info__personal_info" :class="{'no-margin-left': allInfoHidden}">
              <template v-for="(info, index) in userProfileColumns[1]">
                <div
                  v-if="info.show"
                  class="user-information-user-info__personal_layout"
                >
                  <h3>{{ info.label }}</h3>
                  <p v-if="info.label !== 'Social links'">{{ info.value }}</p>
                  <div
                    v-else
                    class="social-media-links"
                  >
                    <div
                      v-for="(social, index) in info.value"
                    >
                      <a
                        v-if="social.value"
                        target="_blank"
                        :href="social.url"
                      >
                        <icon :name="social.icon"/>
                      </a>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="user-information-user__email"
            v-if="isOwner"
          >
            <h3>Email</h3>
            <a
              v-if="isOwner"
              :href="`mailto:${user.email}`"
            >{{ user.email }}</a>
          </div>
        </div>
        <hr v-if="otherUserInfo && otherUserInfo.length > 0 && isOwner"/>
        <div
          v-if="isOwner"
          class="user-information-user__info"
        >
          <div v-for="(userInfo, index) in otherUserInfo">
            <div
              v-if="userInfo.value"
              class="user-information-user-info__personal_layout"
            >
              <h3>{{ userInfo.property }}</h3>
              <p>{{ userInfo.value }}</p>
            </div>
          </div>
        </div>
        <hr v-if="user && user.claims && user.claims.length > 0"/>
        <div class="user-information-user-info">
          <div
            v-if="user && user.claims && user.claims.length && user.claims.filter(claim => claim.actor_type !== 'Product').length > 0"
            class="user-information-user-info-actors"
          >
            <h3>{{ $t('profile_user_claimed_actors') }}</h3>
            <div
              class="profile-card--claim-container"
              v-for="(claim, index) in slicedNonProductClaims"
              :key="'actor' + index"
            >
              <div class="profile-card--claim">
                <div
                  :style="imageStyle(claim)"
                  :src="companyLogoUrl(claim)"
                  class="profile-card--actor-logo"
                  :alt="claim.name"
                  v-if="companyLogoUrl(claim)"
                />
                <div
                  class="profile-card--actor-logo"
                  v-else
                >
                  <span v-if="claim.name">{{ claim.name }}{{ index }}</span>
                </div>
                <div class="profile-card--claim-text">
                  <div class="profile-card--claim-name">
                    {{ claim.name }}
                  </div>
                  <router-link
                    :to="'/actors/' + claim.id"
                    class="profile-card--claim-url"
                    @click="trackHeapEvent('viewClaimedActor', {name: claim.name})"
                  >
                    {{ $t('profile_settings_actor_view') }}
                  </router-link>
                </div>
              </div>
              <a
                v-if="nonProductClaims.length > 5 && index === (slicedNonProductClaims.length - 1)"
                @click="showMoreNonProductClaims = !showMoreNonProductClaims"
              >{{ showMoreNonProductClaims ? $t('actor_detail_show_less') : $t('actor_detail_show_all') }}</a>
            </div>
          </div>
          <hr v-if="user && user.claims && user.claims.filter(claim => claim.actor_type === 'Product').length > 0"/>
          <div
            class="user-information-user-info-products"
            v-if="user.claims && user.claims.filter(claim => claim.actor_type === 'Product').length > 0"
          >
            <div class="profile-card--title">
              <h3>{{ $t('profile_user_claimed_products', { products: productsLabel }) }}</h3>
            </div>
            <div class="profile-card--claims">
              <div
                class="profile-card--claim-container"
                v-for="(claim, index) in slicedProductClaims"
                :key="'product' + index"
              >
                <div class="profile-card--claim">
                  <div class="profile-card--claim-text">
                    <div class="profile-card--claim-name">
                      {{ claim.name }}
                    </div>
                    <router-link
                      :to="'/actors/' + claim.id"
                      class="profile-card--claim-url"
                      @click="trackHeapEvent('viewClaimedProduct', {name: claim.name})"
                    >
                      {{ $t('profile_settings_product_view') }}
                    </router-link>
                  </div>
                </div>
                <a
                  v-if="productClaims.length > 5 && index === (slicedProductClaims.length - 1)"
                  @click="showMoreProductClaims = !showMoreProductClaims"
                >{{ showMoreProductClaims ? $t('actor_detail_show_less') : $t('actor_detail_show_all') }}</a>
              </div>
            </div>
          </div>
          <hr v-if="(user.communities && user.communities.length > 0) || userActivities && Object.keys(userActivities).length > 0"/>
          <div
            class="user-information-user-communities"
            v-if="user.communities && user.communities.length > 0"
          >
            <h3>{{ $t('profile_user_communities') }}</h3>
            <div class="profile-card--claims">
              <div
                class="profile-card--claim-container"
                v-for="(community, index) in user.communities"
                :key="'community' + index"
              >
                <div class="profile-card--claim">
                  <icon
                    name="fort"
                    size="18"
                    :fill="community.color"
                  />
                  <div class="profile-card--claim-text user-side-panel--communities">
                    <div>
                      <div class="profile-card--claim-name">
                        {{ community.name }}
                      </div>
                      <router-link
                        :to="'/communities/' + community.value"
                        class="profile-card--claim-url"
                        @click="trackHeapEvent('viewClaimedCommunity', {name: community.name})"
                      >
                        {{ $t('profile_settings_community_view') }}
                      </router-link>
                    </div>
                    <div>
                      <DsButton
                        label="Remove Member"
                        variant="rounded"
                        size="small"
                        @click="openRemoveMemberModal(community)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr v-if="userActivities && isOwner && user.communities && user.communities.length > 0 "/>
          <div
            v-if="isOwner && userActivities &&  Object.keys(userActivities).length > 0"
            class="user-information-user__last-activities"
          >
            <h3>Latest 50 activities</h3>
            <div v-for="(activity, date) in userActivities">
              <div class="user-information-user__last-activities-info">
                <p>{{ date }}</p>
                <ul class="activity-list">
                  <li
                    v-for="value in activity"
                    class="activity-list-item"
                  >
                    {{ value.description }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import Avatar from "@/components/Avatar/Avatar.vue";
  import { fetchUserDetail } from "@/api/config";
  import IconButton from "@/Domain/Shared/Components/IconButton.vue";
  import { actorLogo } from "@/util/actor";
  import { trackHeapEvent, trackMatomoEvent } from "@/util/analytics";
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from "@/store/modules/ui";
  import TranslationsMixin from "@/util/TranslationsMixin";
  import onboardingMixin from "@/util/OnboardingMixin";
  import Loading from "@/components/Dashboard/ConceptMap/Loading.vue";
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from "@/constants/analytics-constants";
  import Icon from "@/components/Icon/Icon.vue";
  import { toDatetimeString } from "@/util/date";
  import { humanizeUserActivity } from "@/util/user-analytics";
  import MODAL_IDS from '@/constants/modal-ids'
  import { removeMember } from '@/api/communities'

  declare interface BaseComponentData {
    user: any,
    showMoreNonProductClaims: boolean,
    showMoreProductClaims: boolean,
    isUserDataLoading: boolean,
    hidePhoneNumber: boolean,
    showFullBio: boolean,
  }

  export default defineComponent({
    name: 'UserInformationSidePanel',
    components: {
      Icon,
      Loading,
      IconButton,
      Avatar
    },
    props: {
      userId: {
        default: false,
      },
      showPhoneNumber: {
        type: Boolean,
      },
      highlightPhoneNumber: {
        type: Boolean,
      }
    },
    mixins: [TranslationsMixin, onboardingMixin],
    data: (): BaseComponentData => {
      return {
        user: [],
        showMoreNonProductClaims: false,
        showMoreProductClaims: false,
        isUserDataLoading: false,
        hidePhoneNumber: true,
        showFullBio: false,
      }
    },
    mounted() {
      this.fetchUser()

      this.$bus.on('confirmAction', this.removeUserFromCommunity)
    },
    beforeUnmount() {
      this.$bus.off('confirmAction')
    },
    watch: {
      userId() {
        this.fetchUser()
      }
    },
    computed: {
      allInfoHidden() {
        return this.userProfileColumns[0].every(info => !info.show);
      },
      isOwner() {
        return this.$store.getters.isOwner
      },
      userRole() {
        if (!window.ROLES) {
          return this.user.role
        }

        return window.ROLES[this.user.role]
      },
      otherUserInfo() {
        var userInfo = []

        var joinedAt = this.user.joined_at

        if (joinedAt) {
          joinedAt = new Date(joinedAt)
          joinedAt = toDatetimeString(joinedAt)
        }

        var lastActivity = this.user.last_activity

        if (lastActivity) {
          lastActivity = new Date(lastActivity)
          lastActivity = toDatetimeString(lastActivity)
        }

        userInfo.push(
          {
            property: 'Role',
            value: this.userRole,
          },
          {
            property: 'Weekly digest',
            value: this.user.weeklyDigest ? 'subscribed' : 'not subscribed',
          },
          {
            property: 'Joined on',
            value: joinedAt,
          },
          {
            property: 'Last login',
            value: lastActivity,
          },
        )

        return userInfo.filter(info => info.value)
      },
      userProfileColumns(): any[] {
        const columns = []

        const hasMotivation = this.user.motivation && this.user.motivation.length > 0 && this.user.motivation[0] && this.user.motivation[0].name;
        const hasExpertise = this.user.expertise && this.user.expertise.length > 0 && this.user.expertise[0] && this.user.expertise[0].name;

        const firstColumn = [
          {
            label: 'Motivation',
            value: hasMotivation ? this.user.motivation[0].name : null,
            show: hasMotivation,
          },
          {
            label: 'Department',
            value: this.user.department,
            show: !!this.user.department,
          },
          {
            label: 'Phone Number',
            value: this.user.phone,
            show: !!this.user.phone,
          },
        ];

        const secondColumn = [
          {
            label: 'Expertise',
            value: hasExpertise ? this.user.expertise[0].name : null,
            show: hasExpertise,
          },
          {
            label: 'Experience level',
            value: this.user.function,
            show: !!this.user.function,
          },
          {
            label: 'Social links',
            value: this.userProfileSocialMediaLinks,
            show: !!this.user.linkedin_url || !!this.user.instagram_url || !!this.user.twitter_url
          }
        ];

        columns.push(firstColumn, secondColumn);

        return columns
      },
      userProfileSocialMediaLinks(): any[] {
        const options = []
        options.push({
          label: 'LinkedIn:',
          value: this.user.linkedin_url,
          url: this.user.linkedin_url,
          icon: 'linkedin-logo',
        })

        options.push({
          label: 'Twitter:',
          value: this.user.twitter_url,
          url: this.user.twitter_url,
          icon: 'twitter-logo',
        })

        options.push({
          label: 'Instagram:',
          value: this.user.instagram_url,
          url: this.user.instagram_url,
          icon: 'instagram-logo',
        })

        return options
      },
      slicedNonProductClaims() {
        if (this.showMoreNonProductClaims) {
          return this.nonProductClaims
        }
        return this.nonProductClaims.slice(0, 5)
      },
      nonProductClaims() {
        if (!this.user || !this.user.claims) {
          return []
        }
        return this.checkForDuplicates(this.user.claims.filter(claim => claim.actor_type !== 'Product'))
      },
      showProductClaims() {
        if (this.nonProductClaims.length > 0) {
          return true
        }
        return this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes.includes('Product')
      },
      slicedProductClaims() {
        if (this.showMoreProductClaims) {
          return this.productClaims
        }
        return this.productClaims.slice(0, 5)
      },
      productClaimsFromActors() {
        let result = []
        if (this.nonProductClaims.length > 0) {
          for (const nonProductClaim of this.nonProductClaims) {
            if (nonProductClaim && nonProductClaim.products && nonProductClaim.products.length) {
              result = [...result, ...nonProductClaim.products]
            }
          }
        }
        return result
      },
      productClaims() {
        if (!this.user || !this.user.claims) {
          return []
        }
        return this.checkForDuplicates([...this.productClaimsFromActors, ...this.user.claims.filter(claim => claim.actor_type === 'Product')])
      },
      showEyeIcon() {
        if (this.$store.state.ui.sidePanel.metaData.showPhoneNumber) {
          this.hidePhoneNumber = false
          return 'eye-solid'
        }

        return this.hidePhoneNumber ? 'eye-slash' : 'eye-solid'
      },
      userActivities() {
        var activities = {}

        if (!this.user.activities || this.user.activities.length == 0) {
          return activities
        }

        Object.keys(this.user.activities).forEach(key => {
          var values = this.user.activities[key]

          activities[key] = []

          if (!values && values.length == 0) {
            return
          }

          activities[key] = values.map(activity => {
            return {
              ...activity,
              description: this.stringifyUserActivity(activity)
            };
          });

          activities[key].sort((a, b) => (a.description > b.description) ? 1 : -1)
        })

        return activities
      },
    },
    methods: {
      trackHeapEvent,
      removeUserFromCommunity($event) {
        removeMember($event.communityId, this.user.id)
          .then(() => {
          })
          .finally(() => {
            this.fetchUser()
          })
      },
      openRemoveMemberModal(community) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          title: `Remove ${this.user.name}`,
          body: `Are you sure you want to remove ${this.user.name} from the ${community.name}?`,
          confirmLabel: `Yes`,
          type: 'check-confirm',
          cancelLabel: 'No',
          isCancellable: true,
          communityId: community.value
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)
      },
      toggleShow() {
        this.hidePhoneNumber = !this.hidePhoneNumber

        trackHeapEvent('userInformationSidePanel.togglePhoneNumberVisibility', { hidePhoneNumber: this.hidePhoneNumber })
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ACTOR_PHONE_NUMBER, MATOMO_EVENT_ACTIONS.BUTTON_CLICK)
      },
      stringifyUserActivity(activity) {
        var action = 'Viewed '

        if (activity.properties.method && activity.properties.method != 'GET') {
          // Non "view" actions should be getting their description from the humanizeUserActivity
          action = ''
        }

        return action + humanizeUserActivity(activity.description) + ' (x' + activity.count + ')'
      },
      imageStyle(claim) {
        if (this.companyLogoUrl(claim)) {
          return { backgroundImage: `url(${this.companyLogoUrl(claim)})` }
        }
      },
      companyLogoUrl(claim) {
        return actorLogo(claim)
      },
      fetchUser() {
        this.isUserDataLoading = true
        fetchUserDetail(this.userId)
          .then(data => {
            this.user = data
            this.isUserDataLoading = false
          })
      },
      checkForDuplicates(array) {
        const filteredArray = []
        const claims = array.map(function (item) {
          return item.name
        })
        const filteredClaims = [...new Set(claims)]

        filteredClaims.map(function (value) {
          // Find
          const claim = array.find(function (item) {
            return item.name === value
          })
          // Set
          if (!filteredArray.includes(claim)) {
            filteredArray.push(claim)
          }
        })
        return filteredArray
      },
      hideUserInformationPanel() {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      }
    }
  })
</script>
<style
  lang="scss"
  scoped
>
  @import "../../../scss/_variables.scss";

  .user-information {
    padding: 15px;
    overflow-x: auto;
    height: 100%;

    .user-information-user-info_layout {
      background-color: $color-background-lightest-grey;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 4px;
    }


    .button {
      display: flex;
      margin-left: auto;
    }

    .user-information-user-info__header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      .button {
        margin-top: -1rem;
      }

      .user-information-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        margin-right: 0px;

        .avatar {
          width: 100px;
          height: 100px;
          margin-bottom: 1rem;

          :deep(img) {
            width: 100px;
            height: 100px;
          }
        }

        .user-information-avatar__info {
          text-align: center;
          flex-direction: column;

          p {
            color: #444860BF;
          }
        }
      }
    }

    .user-information-user {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      margin-bottom: 2rem;


      .user-information-user__bio {
        //h3 {
        //  color: #444860;
        //}

        .bio {
          margin-top: 0.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 0;
        }

        .full-bio {
          -webkit-line-clamp: unset;
          overflow: visible;
        }

        .user-information-user__bio-see-more {
          display: flex;
          align-items: center;
          cursor: pointer;

          p {
            color: #03738A;
            margin-bottom: 0;
          }
        }
      }

      .user-information-user__email {
        flex-direction: column;

        a {
          color: #444860;
          margin-top: 0.5rem;
        }
      }
    }

    .user-information-user__info {
      margin: 1rem 0 1rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }

    .user-information-user__last-activities {
      margin-top: 1rem;

      .user-information-user__last-activities-info {
        display: flex;
        margin-top: 1rem;

        .activity-list {
          margin-left: 2rem;

          li {
            margin-top: -0.0 .5rem !important;
          }

          .activity-list-item {
            list-style: none;
            height: 1rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .user-information-user-info__personal {
      margin-top: 2rem;
      display: flex;

      &_info {
        display: flex;
        flex-direction: column;
      }

      &_info:nth-child(2) {
        margin-left: 3rem;

        &.no-margin-left {
          margin-left: 0rem !important;
        }
      }

      .user-information-user-info__personal_layout {
        margin-bottom: 1rem;
      }


      .social-media-links {
        display: flex;
        margin-right: 2rem;
        width: 100px;

        a {
          margin-right: 0.5rem;
        }

        :deep(g), :deep(path) {
          stroke: #444860;
          fill: #444860;
        }

        .svg-icon--instagram-logo {
          margin-top: 3px;
        }
      }

      .user-information-user-info_layout {
        background-color: $color-background-lightest-grey;
        margin-bottom: 1rem;
        padding: 0.5rem;
        border-radius: 4px;
      }

      h3 {
        //color: #444860;
        margin-bottom: 0.5rem;
      }

      .user-info {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        margin-left: 0.5rem;


        //h4 {
        //  color: #444860;
        //}

        p {
          color: $color-secondary;
        }
      }

      .user-information-user-info_phoneNumber {
        //h4 {
        //  color: #444860;
        //}

        .hiding {
          position: relative;
          display: flex;

          .add-background {
            background-color: var(--primary-extra-lightest);
            color: var(--primary);
            padding: 8px 16px;
            border: 1px dashed var(--primary);
          }

          .hide-eye-icon {
            display: none;
          }

          @media (max-width: $screen-md) {
            justify-content: center;
          }

          .eye-icon {
            margin-left: 1rem;
          }

          .hide {
            background: linear-gradient(to right, transparent 10%, #F8F8FA 35%);
            width: 6.5rem;
            height: 1.5rem;
            position: absolute;
            top: 0;
            border-radius: 4px;
          }
        }
      }

    }

    .user-information-user-info-actors, .user-information-user-info-products, .user-information-user-communities {
      margin-top: 2rem;

      //h3 {
      //  color: #444860;
      //}

      .profile-card-products {
        margin-top: 2rem;
      }

      .profile-card--claim-container {
        margin-left: 0.5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;

        .profile-card--claim {
          display: flex;
          align-items: center;

          a {
            text-decoration: underline;
          }

          .profile-card--claim-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 12px;

            &.user-side-panel--communities {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            }

            .profile-card--claim-name {
              font-weight: 500;
            }

            a {
              color: #444860;
            }
          }

        }

        hr {
          margin: 10px 0;
        }
      }

      .profile-card--actor-logo {
        z-index: 3;
        border: 1px solid #B1B5C8;
        border-radius: 4px;
        height: 60px;
        width: 60px;
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          line-height: 14px;
          text-align: center;
          max-height: 56px;
          width: 100%;
        }
      }
    }
  }


</style>
