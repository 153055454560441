<template>
  <div>
    <h3 class="h2">News Outlets</h3>
    <div class="guide_text">
      Configure the news outlets that will be monitored 2x per day. The latest articles of these news outlets will be imported and will be available in your knowledge base dashboard. You can view all imported articles of a news outlet by clicking on the name.
    </div>
    <br>

    <form-group label="Add news outlets" style="flex-grow: 2">
      <autocomplete-tag-input
        placeholder="Add a source"
        ref="newsOutletsComponent"
        class="dictionary-component__grow"
        id="news-outlets-component"
        :tags="sources"
        :options="sourceOptions"
        :addOnlyFromAutocomplete="false"
        :validation="validation"
        :minInputLength="1"
        :for-sources="true"
        @tagChanged="updateSourceOptions"
        @input:raw="updateSources"
      />
    </form-group>

    <ds-button
      icon="plus"
      label="Add news outlet"
      @click="addFeed"
    />

    <br /><br />

    <table class="table table--has-button">
      <thead>
      <tr>
        <!--<th>Active</th>-->
        <th>URL</th>
        <th># Articles</th>
        <th>Latest article</th>
        <th>Status</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="loading">
        <td colspan="4"><loading></loading></td>
      </tr>
      <tr v-for="feed in feeds">
        <!--<td v-if="!editingFeeds.includes(feed.id)">
          <ToggleButton
            @update:modelValue="updateFeedActive(feed.id, feed.active)"
            :enabled="feed.active"
            color="success"
          >
          </ToggleButton>
        </td>-->
        <td v-if="!editingFeeds.includes(feed.id)">
          <a href="" @click="filterKnowledgeBaseBySource($event, feed)" v-tooltip="'Filter articles by source'">{{feed.display_name}}</a>
        </td>
        <td v-else>
          <ds-input v-model="feed.display_name" />
        </td>
        <td>{{ feed.article_count }}</td>
        <td>{{ feed.article_count > 0 && feed.most_recent_article ? getMostRecentArticleDate(feed) : '' }}</td>
        <td>
          <Icon v-if="feed.latest_harvested_message" v-tooltip.top="feed.latest_harvested_message" name="information-outline" size="18" style="vertical-align: sub;"/>
          {{ feed.checked_at ? 'Last synced: ' + daysAgo(feed.checked_at) + ' ago' : 'Not yet synced' }}
        </td>
        <td><ds-button size="small" icon="trash" @click="remove(feed.id)" /></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

  import Dropdown from '../Dropdown/Dropdown.vue'

  import { Feeds, updateNewsOutlet } from '../../api/feeds.js'
  import { defineComponent } from 'vue'
  import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '@/store/modules/knowledgeBase'
  import AutocompleteTagInput from '@/components/Form/AutocompleteTagInput.vue'
  import { debounce } from 'lodash'
  import { fetchSourceSuggestions } from '@/api/exploration'
  import { daysAgo, timeAgoTranslated } from '../../util/date'
  import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
  import moment from '~/moment'
  import ToggleButton from '@/components/Form/ToggleButton.vue'

  export default defineComponent({
    name: 'feeds',
    data () {
      return {
        outletSources: [],
        feeds: [],
        editingFeeds: [],
        // Form variables
        showAdd: false,
        adding: false,
        loading: true,
        form : {
          value: "",
          type: "news_outlet"
        },
        errors: {},
        sourceOptions: [],
        validation: [
          {
            classes: 'url',
            rule: /[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/,
            disableAdd: true,
          },
        ],
      }
    },
    computed: {
      sources () {
        return this.transformSources()
      },
    },
    methods: {
      daysAgo,
      timeAgoTranslated,
      remove (feedId) {
        Feeds.delete(feedId)
          .then(response => {
            this.fetch()
          })
          .catch()
      },
      updateFeedActive (feedId, active) {
        updateNewsOutlet({id: feedId, active: !active})
          .then(response => {
            this.fetch()
          })
          .catch()
      },
      getMostRecentArticleDate (feed) {
        if (!feed.most_recent_article) {
          return ''
        }

        if (feed.most_recent_article === null) {
          return 'Unknown'
        }

        return moment(feed.most_recent_article.date).format('YYYY-MM-DD')
      },
      updateSources (sources) {
        var updatedSources = []

        sources.forEach(source => {
          if (source.optionValue && source.optionValue.source) {
            updatedSources.push(source.optionValue.source)
          } else {
            updatedSources.push({
              label: source.text,
              name: source.name,
              er_name: source.name,
              serviced_by_er: source.servicedByER,
            })
          }
        })

        this.outletSources = updatedSources
      },
      updateSourceOptions: debounce(function (query) {
        // Make suggestions
        fetchSourceSuggestions({ query })
          .then(response => {
            this.sourceOptions = response

            if (response.length === 0) {
              // Highlight matching existing options
              // console.log('no results')
            }
          })
          .catch(error => {
            //
          })
      }, 100),
      transformSources () {
        var sources = []

        if (this.outletSources.length > 0) {
          this.outletSources.forEach(keyword => {
            let text = keyword.label

            if (!text.startsWith("https://")) {
              if (!text.startsWith('www')) {
                text = "https://www." + text
              } else {
                text = "https://" + text
              }
            }

            let keywordLabel = text
            // Append the ER name to the display text
            if (keyword.er_name) {
              keywordLabel = '(' + keyword.er_name + ') ' + keywordLabel
            }

            sources.push({
              text: text,
              optionValue: {
                text: text,
                displayText: keywordLabel,
                hoverText: keyword.er_name,
                source: keyword,
                sourceTagInactive: !keyword.serviced_by_er
              },
            })
          })
        }

        return sources
      },
      filterKnowledgeBaseBySource (event, feed) {
        event.preventDefault()

        this.addTagToFilters(event, { value: feed.web_domain_id, label: feed.domain }, 'source')
      },
      addTagToFilters (event, tag, facet = 'tags') {
        const tagFilter = { facet: facet, value: tag.value, label: tag.label }

        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)


        let filters = []
        filters.push(tagFilter)

        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_KNOWLEDGE_BASE_FILTERS, filters)

        this.$router.push('/dashboards/knowledge-base')
      },
      fetch () {
        Feeds.get()
          .then(feeds => {
            this.feeds = feeds.filter(feed => feed.type === 'news_outlet')
            this.loading = false
          })
          .catch(errors => {
            this.loading = false
          })
      },
      add () {
        this.errors = {};

        this.form.value = this.sources[0].text
        this.form.source = 'event_registry'
        this.form.type = "news_outlet"

        Feeds.post(this.form)
          .then(feed => {
            // Reset the form fields
            this.showAdd = false
            this.form = {
              value: "",
              type: "news_outlet"
            }

            this.fetch()
          })
          .catch(errors => {
            this.errors = errors;
            console.log(this.errors.message, "error")
          });
      },
      addFeed () {
        this.add()
        this.showAdd = true
      },
    },
    created () {
      this.fetch()
    },
    components: {
      ToggleButton,
      Loading,
      AutocompleteTagInput,
      Dropdown,
    }
  })
</script>
