<template>
  <span class="cb-dropdown portfolio--dropdown" v-click-away="closeDropdown">
    <ds-button
      :icon="open ? 'chevron-up' : 'chevron-down'"
      class="portfolio__dropdown__button"
      :label="label"
      variant="secondary"
      :size="size"
      iconSize="regular"
      @blur="blur"
      @click="toggle"
      @focus="focus"
    />
    <div v-if="open" class="cb-dropdown__options portfolio--dropdown__options">
      <ds-input v-model="portfolioSearchQuery" placeholder="Search" style="padding:4px;" @blur="blur"/>
      <div class="scrollable cb-dropdown__background">
        <div>
          <template v-for="option in portfolioList">
          <div v-if="option.favourited == true">
            <ds-button
              icon="star"
              class="portfolio--dropdown__options--items"
              :label="option.name"
              :variant="option.name == activePortfolioFilter.name ? 'primary' : 'secondary'"
              size="small"
              iconSize="regular"
              @focus="focus"
              @blur="blur"
              @click="input(option)"
            />
          </div>
          </template>
          <div class="navigation__divider" v-if="hasFavourites"></div>
          <template v-for="option in portfolioList">
          <div v-if="option.favourited == false">
            <ds-button
              class="portfolio--dropdown__options--items"
              :label="option.name"
              :variant="option.name == activePortfolioFilter.name ? 'primary' : 'secondary'"
              size="small"
              @focus="focus"
              @blur="blur"
              @click="input(option)"
            />
          </div>
          </template>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
  import { fetchAllFavourites, fetchPortfolio, Portfolios } from '../../api/portfolios'
  import _throttle from 'lodash/throttle'
  import { MUTATION_TYPES as PORTFOLIO_MUTATION_TYPES } from '../../store/modules/managePortfolio'

  export default {
    // ID is the id of the portfolio on which the dropdown is triggered
    props: ['options', 'label', 'variant', 'selection', 'id', 'icon', 'size'],
    data () {
      return {
        open: false,
        portfolioSearchQuery: '',
        hasFavourites: false,
        favouritePortfolios: [],
        nonFavouritePortfolios: [],
      }
    },
    computed: {
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      portfolioList () {
        var portfolios = this.nonFavouritePortfolios.concat(this.favouritePortfolios)

        // Filter out benchmark portfolios if the user is a portfolio member
        if (this.$store.getters.isPortfolioMember && this.$store.getters.userBenchmarkPortfolios && this.$store.getters.userBenchmarkPortfolios.length > 0) {
          portfolios = portfolios.filter(p => !this.$store.getters.userBenchmarkPortfolios.includes(p.id))
        }

        const uniquePortfolios = []
        const uniqueIds = []

        portfolios.forEach((portfolio) => {
          if (!uniqueIds.includes(portfolio.id)) {
            uniquePortfolios.push(portfolio)
            uniqueIds.push(portfolio.id)
          }
        })

        return uniquePortfolios
      },
      portfolioSelection () {
        // Removes the undefined values from the array, including the undefined value that came in the first position of the array previously.
        if (this.selection && this.selection.indexOf(undefined) > -1) {
          var indexOfUndefined = this.selection.indexOf(undefined)
          this.selection.splice(indexOfUndefined, 1)
        }

        return this.selection
      },
      activePortfolioFilter () {
        return this.$store.getters.activePortfolio
      },
    },
    methods: {
      submit () {
        if (this.portfolioSelection.length > 0) {
          this.$emit('submit')
        }
      },
      toggle () {
        this.open = !this.open || this.active

        // Add the current portfolio to the selection if it's not in there yet
        const index = this.portfolioSelection.indexOf(this.id)

        this.portfolioSearchQuery = ''
        this.fetch()
      },
      focus () {
        this.active = true
        this.open = true

        setTimeout(() => {
          this.active = false
        }, 500)
      },
      input (value) {
        if (value.id == this.$store.state.managePortfolio.activePortfolio.id) {
          if ((this.isPortfolioMember && this.$route.path !== '/dashboards/analytics') || !this.isPortfolioMember) {
            // To remove the selected option.
            this.$emit('update:modelValue', '')
            this.$store.commit('FILTERS/SET_PORTFOLIO', null)
            this.$store.commit(PORTFOLIO_MUTATION_TYPES.CLEAR_ACTIVE_PORTFOLIO)

            this.open = false
          }
        } else {
          this.$emit('update:modelValue', value.id)
          this.$store.commit('FILTERS/SET_PORTFOLIO', value.id)
          this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_ACTIVE_PORTFOLIO, value)

          this.open = false
        }
      },
      blur () {
        setTimeout(() => {
          if (this.$el && document.activeElement && !this.$el.contains(document.activeElement)) {
            this.open = false
          }
        }, 0)
      },
      closeDropdown (event) {
        if (event.target.parentElement && event.target.parentElement.classList.value !== 'portfolio--dropdown') {
          this.open = false
        }
      },
      fetch () {
        // Fetch all portfolios, first get non favourites, then the favourites
        Portfolios.get()
          .then(portfolios => {
            this.nonFavouritePortfolios = portfolios
          })
          .catch(errors => {
            this.errors = errors
          })

        fetchAllFavourites().then(favouritePortfolios => {
          this.hasFavourites = favouritePortfolios.length > 0
          this.favouritePortfolios = favouritePortfolios
        }).catch(errors => {
          console.log(errors)
        })
      },
    },
    watch: {
      portfolioSearchQuery: {
        deep: true,
        handler: _throttle(function () {
          if (!this.portfolioSearchQuery) {
            this.fetch()
            return
          }

          // This will search for a portfolio which matches the input value in the search field
          var filters = Object.assign({}, { query: this.portfolioSearchQuery })

          fetchPortfolio(filters).then(portfolios => {
            var hasFavourites = false

            portfolios.forEach(function (p) {
              if (p.favourited == true) {
                hasFavourites = true
              }
            })

            this.hasFavourites = hasFavourites
            this.nonFavouritePortfolios = []
            this.favouritePortfolios = portfolios
          }).catch(errors => {
            console.log(errors)
          })
        }, 200, {
          trailing: true,
        }),
      },
      open (value) {
        // There seems to be a bug that occurs very rarily that when the dropdown is opened, no portfolios are available
        if (value && this.portfolioList.length == 0 && (!this.portfolioSearchQuery || this.portfolioSearchQuery.length == 0)) {
          this.fetch()
        }
      },
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .hidden__option {
    display: none !important;
  }

  .cb-dropdown {
    position: relative;
    border: none;
    background: none;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $color-datascouts-primary;

      .fill-fg {
        fill: $color-datascouts-primary;
      }
    }

    &:hover .fill-fg {
      fill: $color-datascouts-primary;
    }

    .button {
      text-align: left;
    }

    > .svg-icon {
      display: block;
    }
  }

  .portfolio--dropdown {
    margin-left: 1em;
  }

  .button.portfolio__dropdown__button:focus {
    box-shadow: 0 0 0 2px $color-datascouts-primary !important;
  }

  .portfolio__dropdown__button {
    width: 150px;
    height: 33px;
    border: 1px solid rgba(#fff, 0.1) !important;

    span {
      color: $color-datascouts-primary;
      padding-left: 19px !important;
      font-size: 16px;
      font-family: $font-stack-primary;
      line-height: 19px;
      font-weight: 300;
      letter-spacing: .06em;
    }

    svg {
      width: 25px;
      height: 25px;
      margin-top: -1px;

      path {
        fill: $color-datascouts-primary-lighter !important;
      }
    }
  }

  .portfolio--dropdown__options {
    z-index: 108 !important;
    max-width: 20rem;
    width: 20rem;
  }

  .portfolio--dropdown__options--items {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cb-dropdown__options {
    position: absolute;
    z-index: 2;
    height: 200px;
    top: 100%;
    margin-bottom: 50px;
    left: 0px;
    white-space: nowrap;
    font-size: 14px;
    text-align: left;
    background: $color-secondary;

    > .button {
      &:focus {
        z-index: 1;
      }
    }

    .svg-icon {
      width: 12px;
      height: 12px;
    }

    .cb-dropdown__background {
      background: $color-secondary;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      background-color: $color-secondary;
      background: $color-secondary
    }

    ::-webkit-scrollbar-thumb {
      background-color: $color-background-grey;
      -webkit-border-radius: 1ex;
    }
  }

  .cb-dropdown__button {
    width: 95%;
    background-color: $color-background-grey !important;
    color: white !important;
    text-align: center !important;
    margin-bottom: 4px;
    margin-left: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, .3);
    }
  }

  .cb-dropdown__button-container {
    background: $color-secondary;
    width: 100%;
  }

  .cb-dropdown__inactive_button {
    width: 95%;
    background-color: white !important;
    text-align: center !important;

    &:hover {
      background-color: rgba(255, 255, 255, .3);
    }
  }
</style>
