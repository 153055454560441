<template>
  <div class="onboarding-container"
       :style="{backgroundColor: !isLoginModalAndCloseable ? '#fcfcfc' : 'transparent!important', backdropFilter: !isLoginModalAndCloseable ? '' : 'blur(10px)'}">
    <div class="onboarding-header" v-if="!isLoginModalAndCloseable">
      <img :src="config.logo_url_light || config.logo_url" :alt="currentEcosystem.name" class="onboarding-logo">
    </div>
    <div
      class="onboarding-body scrollable"
      :style="{justifyContent: `${justifyContent}px`, paddingTop: `${onboardingPaddingTop}px`}"
    >
      <div class="onboarding-card container">
        <div
          class="onboarding-card-left"
          :style="{backgroundImage: `url(${coverImage})`}" v-if="selectedComponent !== 'PackagePicker'"
        >
        </div>
        <div
          class="onboarding-card-right"
          :class="{'has-guide-progress': STEPS.length > 1, 'full-width': selectedComponent === 'PackagePicker' }"
        >
          <div
            class="onboarding-card_steps"
            :class="{ 'flex-column':   ['OnboardingCreditCard', 'UserBillingInformation', 'PublicRegistrationEmail'].includes(selectedComponent)}"
          >
            <h2 v-if="this.type === 'packages-sign-up' && selectedComponent === 'OnboardingCreditCard'">{{ title }}</h2>
            <GuideProgress
              v-if="STEPS.length > 1"
              v-model="currentStep"
              :completed-until="currentStep - 1"
              :steps="STEPS"
              class="onboarding-guide-progress"
            />
          </div>
          <div class="onboarding-content">
            <component
              class="onboarding-component-container"
              :is="selectedComponent"
              :coupon-code="couponCode"
              :coupon-validation="couponValidation"
              :payment-error="paymentError"
              :updating-plan="updatingPlan"
              :use-trial="useTrial"
              :payment-method-id="paymentMethodId"
              :billing-info="billingInfo"
              :is-in-modal="true"
              :selected-package="chosenPackage"
              :registration-data="registrationData"
              :type="type"
              :registration-errors="registrationErrors"
              @next="next"
              @setRegistrationErrors="setRegistrationErrors"
              @submitted-final-step="onSubmitFinalStep"
              @updateBillingInfo="updateUserBillingInfo"
              @updatePaymentMethod="updatePaymentMethod"
              @setCouponValidation="setCouponValidation"
              @packageSelected="updateChosenPackage"
              @setCouponCode="setCouponCode"
              @updateRegistrationData="updateRegistrationData"
            />
          </div>
          <template
            v-if="showLanguageSelection"
          >
            <div
              class="language-selection language-selection--english"
              :class="{'language-selected': activeLocale === 'en'}"
              @click="setActiveLocaleLocally('en')"
            >
              English
            </div>
            <div
              class="language-selection language-selection--dutch"
              :class="{'language-selected': activeLocale === 'nl'}"
              @click="setActiveLocaleLocally('nl')"
            >
              Nederlands
            </div>
          </template>
        </div>
        <button class="onboarding-close-button" @click="close" v-if="isCloseable">×</button>
      </div>
    </div>
    <div class="onboarding-footer" v-if="!isLoginModalAndCloseable">
      <span>
        {{ $t('onboarding_hosted_by') }}
        <a href="https://www.datascouts.eu" target="_blank" style="color: white">DATASCOUTS</a>
      </span>
    </div>
  </div>
</template>

<script>
  import Avatar from '../../components/Avatar/Avatar.vue'
  import GuideProgress from '../../components/ConceptSearchGuide/GuideProgress.vue'
  import FormGroup from '../../components/Form/FormGroup.vue'
  import { MUTATION_TYPES as LOCALIZATION_MUTATION_TYPES } from '../../store/modules/localization.js'
  import PublicRegistrationEmail from './PublicRegistrationEmail.vue'
  import RegistrationEmailAndPassword from './RegistrationEmailAndPassword.vue'
  import RegistrationMoreDetails from './RegistrationMoreDetails.vue'
  import RegistrationFinal from './RegistrationFinal.vue'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import OnboardingLogin from './OnboardingLogin.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import OnboardingForgotPassword from './OnboardingForgotPassword.vue'
  import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics.js'
  import OnboardingCreditCard from './OnboardingCreditCard.vue'
  import UserBillingInformation from '../../components/Profile/Subscription/UserBillingInformation.vue'
  import PackagePicker from '../../components/Modals/PackagesSignUpModal/PackagePicker.vue'
  import OnboardingActorSignUp from './OnboardingActorSignUp.vue'
  import { defineComponent } from 'vue'
  import { sendInvitationAcceptedEmailToOwners } from '@/api/user'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants.js'

  export default defineComponent({
    props: {
      type: String,
      isCloseable: {
        type: Boolean,
        default: true,
      },
      isModal: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close'],
    data () {
      return {
        currentStep: 1,
        utcSecondsAtStepStart: 0,
        secondsSpentInComponents: {},
        billingInfo: null,
        chosenPackage: {},
        useTrial: false,
        paymentMethodId: '',
        couponCode: '',
        couponValidation: null,
        paymentError: '',
        updatingPlan: false,
        registrationData: {},
        registrationErrors: {},
        onboardingPaddingTop: 0,
        justifyContent: 'flex-start',
      }
    },
    methods: {
      getNormalisedNumber (currentStepValue) {
        switch (currentStepValue) {
          case 1:
            return 'one';
          case 2:
            return 'two';
          case 3:
            return 'three';
          case 4:
            return 'four';
        }
      },
      setRegistrationErrors (errors) {
        this.registrationErrors = errors
        if (errors && Object.keys(errors).length) {
          let stepThatCausedError = this.STEPS.find(step => step.component === 'PublicRegistrationEmail')
          if (!stepThatCausedError) {
            stepThatCausedError = this.STEPS.find(step => step.component === 'RegistrationEmailAndPassword')
          }
          this.currentStep = stepThatCausedError.value
        }
      },
      updateRegistrationData (data) {
        this.registrationData = data
        this.next()
      },
      setCouponCode (couponCode) {
        this.couponCode = couponCode
      },
      updatePaymentMethod (paymentMethod) {
        this.paymentMethodId = paymentMethod.id
      },
      setCouponValidation (value) {
        this.couponValidation = value
      },
      updateChosenPackage (chosenConfig) {
        this.chosenPackage = chosenConfig.selectedPackage
        this.useTrial = chosenConfig.useTrial
        this.next()
      },
      updateUserBillingInfo (billingInfo) {
        this.billingInfo = billingInfo
        this.next()
      },
      close () {
        if (this.$route.query && this.$route.query.redirect_uri && !this.$route.query.redirect_uri.includes('api') && !this.$route.query.redirect_uri.includes('login')) {
          // Redirect to the page where the user was before the onboarding, but keep URI encoding in mind
          let redirectUri = this.$route.query.redirect_uri;

          if (redirectUri === encodeURIComponent(decodeURIComponent(redirectUri))) {
            redirectUri = decodeURIComponent(redirectUri);
          }

          this.$router.push(redirectUri)

          return
        }

        if (this.isModal) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
          if (this.type === 'login') {
            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.LOGIN)
          } else if (this.type === 'forgot-password') {
            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.FORGOT_PASSWORD)
          } else if (this.type === 'sign-up') {
            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.SIGN_UP)
          } else if (this.type === 'actor-sign-up') {
            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
          } else if (this.type === 'packages-sign-up') {
            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.PACKAGES_SIGN_UP)
          }

          this.$emit('close')
        }
      },
      getNameForStep (step) {
        // Map component names to human-readable step names
        const stepNameMapping = {
          'PackagePicker': 'Package Selection',
          'OnboardingCreditCard': 'Credit Card Entry',
          'UserBillingInformation': 'Billing Information',
          'PublicRegistrationEmail': 'Full Name & Email',
          'RegistrationEmailAndPassword': 'Password & Confirm Password',
          'OnboardingActorSignUp': 'Company Info',
          'RegistrationMoreDetails': 'More Details',
          'RegistrationFinal': 'Final Step',
          'OnboardingLogin': 'Login',
          'OnboardingForgotPassword': 'Forgot Password'
        };

        // Get the component name for the step
        const stepComponent = this.STEPS[step - 1]?.component;

        // Return the human-readable name or a fallback
        return stepNameMapping[stepComponent] || 'Unknown Step';
      },
      next () {
        // On the current step they clicked the next button
        const currentStepValue = this.STEPS[this.currentStep - 1].value;
        const isLastStep = (this.currentStep + 1) === this.STEPS.length;

        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ONBOARDING, MATOMO_EVENT_ACTIONS.ONBOARDING_REGISTRATION_COMPLETED_STEPS, 'Step ' + this.currentStep + ' - ' + this.getNameForStep(currentStepValue));

        if (window.dataLayer && !isLastStep) {
          window.dataLayer.push({
            'event': 'form_submit',
            'form_status': 'step_' + this.getNormalisedNumber(currentStepValue),
            'name': 'onboarding_registration_success',
          });
        }

        // check if this is the last step
        if (isLastStep) {
          trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ONBOARDING, MATOMO_EVENT_ACTIONS.ONBOARDING_REGISTRATION_FINISHED_SUCCESSFULLY)

          if (window.dataLayer) {
            window.dataLayer.push({
              'event': 'form_submit',
              'form_status': 'success',
              'name': 'onboarding_registration_success',
            });
          }
        }

        this.currentStep++
      },
      setActiveLocaleLocally (locale) {
        // !! we don't use "dispatch -> action_types -> update_active_locale" here because the user is not logged in
        this.$store.commit(LOCALIZATION_MUTATION_TYPES.SET_ACTIVE_LOCALE, { locale, i18n: this.$i18n })
      },
      onChangeCurrentStep (previousStep) {
        const secondsSinceStart = Math.ceil(new Date().getTime() / 1000 - this.utcSecondsAtStepStart)
        const previousStepName = this.STEPS[previousStep - 1].component
        switch (previousStepName) {
          case 'PublicRegistrationEmail':
            this.secondsSpentInComponents.publicRegistrationEmail = secondsSinceStart
            break
          case 'RegistrationEmailAndPassword':
            if (window.invited_by) {
              this.secondsSpentInComponents.privateRegistrationEmailAndPassword = secondsSinceStart
            } else {
              this.secondsSpentInComponents.publicRegistrationEmailAndPassword = secondsSinceStart
            }
            break
          case 'RegistrationMoreDetails':
            if (window.invited_by) {
              this.secondsSpentInComponents.privateRegistrationMoreDetails = secondsSinceStart
            } else {
              this.secondsSpentInComponents.publicRegistrationMoreDetails = secondsSinceStart
            }
            break
          case 'RegistrationFinal':
            if (window.invited_by) {
              this.secondsSpentInComponents.privateRegistrationFinal = secondsSinceStart
            } else {
              this.secondsSpentInComponents.publicRegistrationFinal = secondsSinceStart
            }
            break
          case 'OnboardingLogin':
            this.secondsSpentInComponents.login = secondsSinceStart
            break
          case 'OnboardingForgotPassword':
            this.secondsSpentInComponents.forgotPassword = secondsSinceStart
            break
          case 'OnboardingPackage':
            this.secondsSpentInComponents.package = secondsSinceStart
            break
          case 'OnboardingCreditCard':
            this.secondsSpentInComponents.creditCard = secondsSinceStart
            break
          case 'OnboardingBillingInformation':
            this.secondsSpentInComponents.billingInformation = secondsSinceStart
            break
          default:
            break
        }
        this.utcSecondsAtStepStart = new Date().getTime() / 1000
      },
      onSubmitFinalStep () {
        this.onChangeCurrentStep(this.currentStep)
        trackHeapEvent('finishedOnboarding', { ...this.secondsSpentInComponents, hasClosedWindow: false })

        // Send invitation accepted email to owners
        sendInvitationAcceptedEmailToOwners(window.user.id)
      },
      onCloseWindow () {
        this.onChangeCurrentStep(this.currentStep || 1)
        trackHeapEvent('finishedOnboarding', { ...this.secondsSpentInComponents, hasClosedWindow: true })
      },
      calculateCardHeight () {
        this.onboardingPaddingTop = 40

        if (window.innerHeight >= 992) {
          this.justifyContent = 'center'
        }

        if (window.innerHeight >= 600) {
          return
        }

        this.onboardingPaddingTop = 60
      },
    },
    computed: {
      isLoginModalAndCloseable () {
        return this.selectedComponent === 'OnboardingLogin' && this.closeable
      },
      closeable () {
        // The modal is not closeable if either the ecosystem does not allow anonymous users
        // or the login is part of an oauth request being resolved
        return this.$store.getters.hasAccessToPublisher && !!this.$store.state.config.allow_anonymous && !window.oauth_request
      },
      currentCouponCodeIsInvalid () {
        return this.couponValidation && this.couponValidation.valid === false
      },
      selectedComponent () {
        if (this.STEPS[this.currentStep - 1]) {
          return this.STEPS[this.currentStep - 1].component
        }
      },
      title () {
        if (this.chosenPackage.id) {
          if (this.useTrial) {
            return 'Free trial for ' + this.chosenPackage.title
          }

          if (!this.couponValidation || this.currentCouponCodeIsInvalid) {
            return `${this.chosenPackage.title} for €${this.chosenPackage.monthly_price * 12} per year`
          } else {
            return `${this.chosenPackage.title} for €${this.chosenPackage.monthly_price * 12 / 100 * (100 - this.couponValidation.discount)} per year`
          }
        }

        return 'Choose your plan'
      },
      coverImage () {
        return this.config.onboarding_image_url || this.config.coverSrc || '/images/covers/skyline.jpeg'
      },
      showLanguageSelection () {
        return this.config.languages && this.config.languages.includes('nl') && this.currentStep === 1 && this.STEPS.length > 1 && this.type !== 'packages-sign-up'
      },
      activeLocale () {
        return this.$store.getters.activeLocale
      },
      coverSrc () {
        return this.config.coverSrc || '/images/covers/skyline.jpeg'
      },
      config () {
        return this.$store.state.config
      },
      isPublic () {
        return this.$store.getters.isPublic
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      STEPS () {
        let componentList = []

        if (this.type === 'accept-invite') {
          componentList = [
            'RegistrationEmailAndPassword',
            ...this.shouldShowActorSignUp ? ['OnboardingActorSignUp'] : [],
            ...this.isLoggedIn ? ['RegistrationMoreDetails'] : [], // If the user has been logged in in the registration step, we ask for more details
            'RegistrationFinal',
          ]
        } else if (this.type === 'login') {
          componentList = ['OnboardingLogin']
        } else if (this.type === 'forgot-password') {
          componentList = ['OnboardingForgotPassword']
        } else if (this.type === 'sign-up' || this.type === 'actor-sign-up') {
          componentList = [
            'PublicRegistrationEmail',
            'RegistrationEmailAndPassword',
            ...this.shouldShowActorSignUp ? ['OnboardingActorSignUp'] : [],
            ...(this.isPublic && this.isLoggedIn) ? ['RegistrationMoreDetails'] : [],
            'RegistrationFinal',
          ]
        } else if (this.type === 'packages-sign-up') {
          if (this.chosenPackage.monthly_price === 0 || this.useTrial) {
            componentList = [
              'PackagePicker',
              'UserBillingInformation',
              'PublicRegistrationEmail',
              'RegistrationEmailAndPassword',
              ...this.shouldShowActorSignUp ? ['OnboardingActorSignUp'] : [],
              ...this.isLoggedIn ? ['RegistrationMoreDetails'] : [], // If the user has been logged in in the registration step, we ask for more details
              'RegistrationFinal',
            ]
          } else {
            componentList = [
              'PackagePicker',
              'OnboardingCreditCard',
              'UserBillingInformation',
              'PublicRegistrationEmail',
              'RegistrationEmailAndPassword',
              ...this.shouldShowActorSignUp ? ['OnboardingActorSignUp'] : [],
              ...this.isLoggedIn ? ['RegistrationMoreDetails'] : [], // If the user has been logged in in the registration step, we ask for more details
              'RegistrationFinal',
            ]
          }
        }

        return componentList.map((component, index) => {
          return { component: component, value: index + 1 }
        })
      },
    },
    mounted () {
      if (this.$store.getters.isKlik || this.$store.getters.isOpenCreativesZuidwest) {
        this.setActiveLocaleLocally('nl')
      }

      this.utcSecondsAtStepStart = new Date().getTime() / 1000
      window.addEventListener('beforeunload', this.onCloseWindow)

      this.calculateCardHeight()
      window.addEventListener('resize', this.calculateCardHeight)

      // Comes from selecting a package on the EllieConnect static homepage
      if (this.$store.state.ui.modalContext && this.$store.state.ui.modalContext.chosenPackageConfig) {
        this.updateChosenPackage(this.$store.state.ui.modalContext.chosenPackageConfig)
      }
    },
    watch: {
      currentStep (currentStep, previousStep) {
        this.onChangeCurrentStep(previousStep)
      },
    },
    beforeUnmount () {
      this.onChangeCurrentStep(this.currentStep || 1)
      trackHeapEvent('finishedOnboarding', { ...this.secondsSpentInComponents, hasClosedWindow: false })
      window.removeEventListener('beforeunload', this.onCloseWindow)
    },
    mixins: [OnboardingMixin],
    components: {
      Avatar,
      GuideProgress,
      FormGroup,
      PublicRegistrationEmail,
      RegistrationEmailAndPassword,
      RegistrationMoreDetails,
      RegistrationFinal,
      OnboardingLogin,
      OnboardingForgotPassword,
      OnboardingCreditCard,
      UserBillingInformation,
      PackagePicker,
      OnboardingActorSignUp,
    },
  })
</script>
