<template>
  <div class="column-picker row">
    <div class="col-xs-5 column-picker__left-list-container">
      <form-group :label="labelOut || 'Hidden columns'">
        <draggable class="left-draggable-list scrollable" :list="hiddenColumns" @change="apply('adding')" item-key="id" group="columns">
          <template #item="{element}">
            <div class="draggable-list-item"> {{ element.humanizedName }}</div>
          </template>
          <template #header>
          </template>
        </draggable>
      </form-group>
    </div>
    <div class="col-xs-2 column-picker__center-container">
      <ds-button variant="outline" disabled icon="expand"/>
    </div>
    <div class="col-xs-5 column-picker__right-list-container">
      <form-group :label="labelIn || 'Visible columns'">
        <draggable class="right-draggable-list scrollable" :list="visibleColumns" @change="apply('removing')" item-key="id" group="columns">
          <template #item="{element}">
            <div class="draggable-list-item"> {{ element.humanizedName }}</div>
          </template>
          <template #header>
          </template>
        </draggable>
      </form-group>
    </div>
  </div>
</template>

<script lang="ts">
  import _difference from 'lodash/difference.js'
  import draggable from 'vuedraggable'

  import { viewListOptions } from '../../constants/config.js'
  import { humanize } from '../../constants/properties.js'

  import { _unique, getReportFieldById } from '../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'draggable-column-picker',
    components: {
      draggable,
    },
    props: {
      labelIn: String,
      labelOut: String,
      options: Array,
      modelValue: Array,
    },
    emits: ['update:modelValue'],
    data() {
      return {
        hiddenColumns: [],
        visibleColumns: [],
        drag: false,
      }
    },
    computed: {
      leftovers() {
        var leftovers = _difference(this.options || viewListOptions, this.modelValue).sort()

        if (!this.isInFundingDashboard) {
          var fundingDashboardColumns = ['total_investment', 'funding_date', 'funding_type', 'funding_amount']

          leftovers = leftovers.filter(column => {
            return !fundingDashboardColumns.includes(column)
          })
        }

        return _unique(leftovers)
      },
      columnsOptionsA() {
        return this.leftovers.map(column => {
          // In the case of the 'elastic_id' we display only 'id' in the draggable item name
          if (column == 'elastic_id') {
            return { id: 'elastic_id', name: 'id', humanizedName: 'ID' }
          }

          if (column == 'stage') {
            return { id: 'stage', name: 'growth stage', humanizedName: 'Growth Stage' }
          }

          if (column == 'industry_codes') {
            return { id: column, name: column, humanizedName: 'Main NACE Code' }
          }

          if (column == 'nace_codes') {
            return { id: column, name: column, humanizedName: 'Main NACE Code' }
          }

          if (column == 'claimees') {
            return { id: column, name: column, humanizedName: 'Claimees (Contributors of the actor)' }
          }

          if (column == 'contact_email_list') {
            return { id: column, name: column, humanizedName: 'Founder & Employees (can be non-contributors)' }
          }

          return { id: column, name: column, humanizedName: this.humanizeColumn(column) }
        }).sort(sortByHumanizedName)
      },
      columnsOptionsB() {
        var options = this.modelValue

        // If the we're not in the funding list dashboard, we filter out all possible columns that only belong to the funding list dashboard
        if (!this.isInFundingDashboard) {
          var fundingDashboardColumns = ['total_investment', 'funding_date', 'funding_type', 'funding_amount']

          options = options.filter(column => {
            return !fundingDashboardColumns.includes(column.name)
          })
        }

        return options.map(column => {
          // In the case of the 'elastic_id' we display only 'id' in the draggable item name
          if (column == 'elastic_id') {
            return { id: 'elastic_id', name: 'id', humanizedName: 'ID' }
          }
          if (column == 'stage') {
            return { id: 'stage', name: 'growth stage', humanizedName: 'Growth Stage' }
          }

          if (column == 'industry_codes') {
            return { id: column, name: column, humanizedName: 'Main NACE Code' }
          }

          if (column == 'nace_codes') {
            return { id: column, name: column, humanizedName: 'Main NACE Code' }
          }

          if (column == 'claimees') {
            return { id: column, name: column, humanizedName: 'Claimees (Contributors of the actor)' }
          }

          if (column == 'contact_email_list') {
            return { id: column, name: column, humanizedName: 'Founder & Employees (can be non-contributors)' }
          }

          return { id: column, name: column, humanizedName: this.humanizeColumn(column) }
        })
      },
      isInFundingDashboard() {
        return this.$route.path === '/dashboards/funding-list'
      },
    },
    methods: {
      humanize,
      humanizeColumn(column) {
        if (column.indexOf('report_field_') >= 0) {
          var reportFieldId = column.replace('report_field_', '')
          var reportField = getReportFieldById(reportFieldId)

          if (!reportField) {
            return 'report'
          }

          return reportField.label
        }

        return humanize(column)
      },
      apply(action) {
        // We only need to send the updated visible columns
        this.$emit('update:modelValue', this.visibleColumns.map(item => item.id))
      },
    },
    created() {
      this.hiddenColumns = this.columnsOptionsA
      this.visibleColumns = this.columnsOptionsB
    }
  })

  function sortByHumanizedName(a, b) {
    return a.humanizedName.localeCompare(b.humanizedName)
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .column-picker__select {
    width: 100%;
    min-height: 300px;
    padding: 5px;
    font-size: 14px;

    option {
      padding: 3px;
    }
  }

  .column-picker__button.button {
    transition: transform .3s;
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .column-picker {
    .column-picker__left-list-container, .column-picker__right-list-container {
      max-height: 300px;
      margin-bottom: 20px;

      .form-group {
        height: 100%;
      }
    }

    .left-draggable-list, .right-draggable-list {
      height: 100%;
      border: 1px solid $color-borders;

      .draggable-list-item {
        cursor: move;

        &:hover {
          background-color: $color-background-lightest-grey;
        }
      }
    }

    .draggable-list-item {
      margin-left: .5em;
    }

    .column-picker__center-container {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .svg-icon--expand {
        margin-left: -12px !important;
        margin-right: unset;
      }
    }
  }
</style>
