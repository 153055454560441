<template>
  <data-point-card
    :title="suggestionTitleLine"
    :sub-title="suggestionSubTitleLine"
    :show-image="true"
    :logo-url="getSuggestionLogo"
    :url="suggestionUrl"
  >
    <template #body>
      <div>
        <div class="card__header-text">
          <p
            class="analysis-card__text"
            v-if="getSuggestionDescription"
          >{{ getSuggestionDescription }}</p>
        </div>
      </div>
    </template>
    <template #footer>
      <icon
        class="analysis-card-icon"
        v-if="state === 'adding' || state === 'removing' "
        style="float: right; margin-left: 15px;" name="spinner"
      />
      <icon
        class="analysis-card-icon suggestion-denied"
        v-if="state === 'removed'"
        style="float: right; margin-left: 15px;"
        name="trash"
      />
      <icon
        class="analysis-card-icon suggestion-success"
        v-if="state === 'added' && status === 'accepted'"
        style="float: right; margin-left: 15px;"
        name="check"
      />
      <icon
        class="analysis-card-icon"
        v-if="state === 'error'"
        v-tooltip.top="errors.length > 0 ? errors[0] : ''"
        style="float: right; margin-left: 15px;"
        name="warning"
      />
      <div
        v-if="status !== 'accepted' && status !== 'denied'"
        class="analysis-card-footer-actions"
      >
        <div class="analysis-card-footer-actions--item">
          <ds-button
            type="button"
            @click.prevent="approveSuggestion"
            title="Approve suggestion"
            class="button"
            :disabled="processingSuggestion"
            style="margin-bottom: 0px;"
            icon="plus-big"
            icon-fill-color="#0B3D8A"
          />
        </div>
        <div class="analysis-card-footer-actions--item">
          <DsButton
            type="button"
            @click.prevent="denySuggestion"
            title="Deny suggestion"
            class="button"
            :disabled="processingSuggestion"
            style="margin-bottom: 0px;"
            icon="x"
          />
        </div>
      </div>
    </template>
  </data-point-card>
</template>

<script>
  import DataPointCard from './DataPointCard.vue'
  import { isEmpty } from 'lodash'
  import { approveActorSuggestion, denyActorSuggestion } from '../../api/spottingareas.js'
  import { createActor } from '../../api/actors.js'
  import { trackMatomoEvent } from '../../util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'

  export default {
    props: {
      spottingAreaId: {
        type: Number,
        required: true,
      },
      actor: {
        type: Object,
        default: () => {
          return {}
        },
      },
      suggestion: {
        type: Object,
        default: () => {
          return {}
        },
      },
      processingSuggestion: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        value: {},
        errors: [],
        state: false,
        status: false,
      }
    },
    emits: ['disabled'],
    methods: {
      approveActorSuggestion () {
        approveActorSuggestion({
          spottingAreaId: this.spottingAreaId,
          actorOrCentralProfileId: this.actor.id
        })
          .then(data => {
            this.state = 'added'
            this.status = 'accepted'

            this.$emit('disabled', false)
          })
          .catch(response => {
            this.$emit('disabled', false)

            this.state = 'error'

            if (response.errors) {
              this.errors = response.errors
            }
          })
          .finally(() => {
            trackMatomoEvent(MATOMO_EVENT_CATEGORIES.SPOTTING_AREA_ACTOR_SUGGESTIONS, MATOMO_EVENT_ACTIONS.APPROVE)
          })
      },
      addActorEcosystem (suggestion, applySuggestion = false) {
        // suggestion.value.state = 'adding'

        createActor(suggestion.value)
          .then(response => {
            const actorId = response.id

            if (actorId && applySuggestion) {
              setTimeout(() => {
                this.approveActorSuggestion(suggestion)
              }, 500)
            }
          })
          .catch(err => {
            this.$emit('disabled', false)

            // Get the actor from the error message
            if (err.id) {
              this.approveActorSuggestion(suggestion)

              this.state = 'added'

              return
            }

            this.state = 'error'
          })
      },
      approveSuggestion () {
        this.state = 'adding'
        this.$emit('disabled', true)

        if (this.actor && this.actor.id) {
          return this.approveActorSuggestion()
        }

        // Skip this flow for now, as it is not fully scoped
        // this.addActorEcosystem(this.suggestion, true)
      },
      denySuggestion () {
        this.state = 'removing'
        this.$emit('disabled', true)

        denyActorSuggestion({
          spottingAreaId: this.spottingAreaId,
          actorOrCentralProfileId: this.actor.id,
        })
          .then(data => {
            this.state = 'removed'

            this.status = 'denied'

            setTimeout(() => {
              this.fetchActorSuggestions()
            }, 500)

            this.$emit('disabled', false)
          })
          .catch(response => {
            this.$emit('disabled', false)
            this.state = 'error'

            if (response.errors) {
              this.errors = response.errors
            }
          })
          .finally(() => {
            trackMatomoEvent(MATOMO_EVENT_CATEGORIES.SPOTTING_AREA_ACTOR_SUGGESTIONS, MATOMO_EVENT_ACTIONS.DENY)
          })
      },
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      suggestionTitleLine () {
        return this.actor.name
      },
      suggestionSubTitleLine () {
        const results = []

        if (this.getSuggestionAddress) {
          results.push(this.getSuggestionAddress)
        }
        if (this.getSuggestionCategory) {
          results.push(this.getSuggestionCategory)
        }
        if (this.getSuggestionConnections) {
          results.push(this.$t((this.getSuggestionConnections === 1 ? 'global_search_connection' : 'global_search_connections'), { connection: this.getSuggestionConnections }))
        }
        return results.join(' • ')
      },
      getSuggestionDescription () {
        return this.actor.description_stripped_tags || this.actor.activities_description || this.actor.short_description
      },
      getSuggestionLogo () {
        return this.actor.logo
      },
      suggestionUrl () {
        // Only allow to click on the card when there is no suggestion processing
        if (!this.processingSuggestion) {
          // If the suggested actor is coming from Central Ecosystem, redirect the user
          if (this.actor.ecosystem === 'central' && this.$store.getters.currentEcosystem.name !== 'central' && this.config.central_ecosystem_url) {
            return this.config.central_ecosystem_url + '/actors/' + this.actor.id
          }

          return '/actors/' + this.actor.id
        }
      },
      getSuggestionName () {
        return this.actor.name
      },
      getSuggestionAddress () {
        let addressObject = {}
        const addressString = []

        if (this.actor && this.actor.address) {
          addressObject = this.actor.address
        }

        if (isEmpty(addressObject)) {
          return ''
        }

        if (addressObject.country) {
          addressString.push(addressObject.country)
        }

        return addressString.join(', ')
      },
      getSuggestionCategory () {
        if (this.actor && this.actor.category && this.actor.category.name) {
          return this.actor.category.name
        }

        if (this.actor && this.actor.technology && this.actor.technology.name) {
          return this.actor.technology.name
        }

        if (this.actor && this.actor.activities && this.actor.activities[0]) {
          return this.actor.activities.slice(0, 2).map(a => a.name).join(', ')
        }

        if (this.actor && this.actor.industries && this.actor.industries[0]) {
          return this.actor.industries.slice(0, 2).map(a => a.name).join(', ')
        }

        return ''
      },
      getSuggestionConnections () {
        if (this.actor.connections) {
          return this.actor.connections
        }

        return 0
      }
    },
    components: {
      DataPointCard,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .analysis-card-list {
    margin-bottom: 0;

    .analysis-card {
      border: 2px solid $color-borders;
      border-radius: $default-border-radius-narrow;
      position: relative;
      cursor: pointer;
      margin-bottom: 1rem;
      word-break: break-word;
      height: calc(100% - 1rem);

      p {
        color: #898989;
      }

      .card__header {
        padding: 0 20px 20px 20px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        .card__header-logo {

        }

        .card__header-text {
          display: flex;
          flex: 1;
          flex-direction: column;
        }
      }

      .analysis-card__text {
        $font-size: 12px;
        $line-height: 20px;
        $max-lines: 3;

        font-size: $font-size;
        line-height: $line-height;
        height: $max-lines*$line-height;
        max-height: unset;
        display: -webkit-box;
        width: 100%;
        -webkit-line-clamp: $max-lines;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0;
        margin-bottom: 25px;
        flex-grow: 1;
        margin-top: 10px;
      }

      .analysis-card-eye {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .analysis-card-overlay {
        display: none;
        font-size: 14px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: $color-background-lightest-grey;
        justify-content: center;
        align-items: center;
        border-radius: $default-border-radius-narrow;
      }

      &:hover {
        .analysis-card-overlay {
          display: flex;
        }
      }

      .content__meta {
        padding: 0 20px;
        height: 23px;
        margin-bottom: 20px;
        overflow: hidden;

        .tag {
          background-color: var(--primary-community-lightest);
          border-color: var(--primary-community-lightest);
          border-radius: 0.5rem;
          border-bottom: 20px;
        }
      }

      .analysis-card-icon {
        position: absolute;
        top: 5px;
        right: 5px;

        &.suggestion-success {
          > .fill-fg {
            fill: green !important;
          }
        }

        &.suggestion-denied {
          > .fill-fg {
            fill: orange !important;
          }
        }
      }

      .analysis-card-footer-actions {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        color: #CECECE !important;

        &--item {
          padding-left: 10px;
          position: relative;
          padding: 5px;

          .button {
            font-size: 16px;
            border-radius: 25px;
            color: #0D0D0D !important;
            background-color: transparent;

            &:hover {
              color: var(--primary);
              //background-color: #F2F2F2;
            }

            :deep(.button svg:first-child) {
              color: #0B3D8A;
            }
          }

          &:first-child {
            margin-left: 0px;

            &:after {
              font-size: 18px;
              cursor: default;
              left: calc(100% + 2px);
              top: 23%;
              content: '|';
              width: 100%;
              height: 100%;
              position: absolute;
              // left: 25px;
            }
          }
        }
      }

      .extracted-actor {
        text-decoration: none;
        color: black;

        &.clickable {
          text-decoration: underline;
        }
      }

      h4.h4 {
        font-size: 14px;
        padding: 20px 20px 10px 20px;
        color: var(--primary);

        &.one-line {
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 47px;
          line-height: 17px;
          overflow: hidden;
        }
      }

      h6.h6 {
        font-size: 11px;
        padding: 5px 20px 5px 20px;
      }

      p {
        font-size: 11px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        line-height: 14px;
        max-height: 56px;
        overflow: hidden;
      }
    }
  }

</style>
