<template>
  <div>
    <div class="scope-description__header">
      <div v-if="part === 1" style="margin-bottom: 2rem;">
        <h2>What would you like to name your report?</h2>
        <form-group
          style="margin-top: 1rem;"
          label="Give your report a name, this can also be used to generate context in the next step."
          :error="titleError"
        >
          <DsInput style="margin-top: 1rem;" placeholder="Name of the report" v-model="title"/>
        </form-group>
      </div>

      <description-with-keywords v-model:description="scopeDescription" :is-generating-description="isGeneratingDescription" v-model:keywords="introJsMockData" :part="part"
                                 @generate-description="handleGenerateDescription"
                                 v-if="introJsMockData"/>
      <description-with-keywords v-model:description="scopeDescription" :is-generating-description="isGeneratingDescription" v-model:keywords="searchTopics" :part="part"
                                 @generate-description="handleGenerateDescription" v-else/>
    </div>
    <div class="scope-description__button">
      <ds-button variant="primary" label="Next step" @click="handleNextStep" :disabled="!canSubmit || !isTitleValid || isGeneratingDescription"/>
      <ds-button label="Cancel" @click="handleCancel" v-if="withCancel"/>
    </div>
  </div>
</template>

<script lang="ts">
  import DescriptionWithKeywords from './DescriptionWithKeywords.vue'
  import { defineComponent } from 'vue'
  import { validateTitle } from '@/api/exploration'
  import debounce from 'lodash/debounce.js'

  const MINIMUM_LENGTH = 100

  export default defineComponent({
    props: {
      part: Number,
      modelValue: {
        type: Object,
      },
      introJsMockData: {
        type: Array,
      },
      withCancel: {
        type: Boolean
      },
      isGeneratingDescription: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue', 'next-step', 'cancel', 'generate-description'],
    data() {
      return {
        MINIMUM_LENGTH,
        submitting: false,
        isTitleValid: true,
        titleError: null,
      }
    },
    computed: {
      scopeDescription: computedValueProperty('scopeDescription'),
      searchTopics: computedValueProperty('searchTopics'),
      title: computedValueProperty('title'),
      hasAtLeastOneWikipediaTag() {
        const prefix = 'http://en.wikipedia.org/wiki/'
        return !!this.searchTopics.find(topic => topic.value.startsWith(prefix))
      },
      canSubmit() {
        return !this.submitting && !(this.part === 2 && this.modelValue.scopeDescription.length === 0) && !(this.part === 1 && (this.searchTopics.length === 0 || this.modelValue.title.length < 1))
      },
    },
    mounted() {
      this.$bus.on('descriptionGenerated', this.updateScopeDescription)
    },
    methods: {
      updateScopeDescription(description: string) {
        this.scopeDescription = description
      },
      handleNextStep() {
        this.$emit('next-step')
      },
      handleCancel() {
        this.$emit('cancel')
      },
      handleGenerateDescription() {
        this.$emit('generate-description')
      },
      validateTitle: debounce(function () {
        if (this.modelValue.title.length === 0 || this.modelValue.title === '') {
          this.isTitleValid = false
        }

        this.titleError = null
        validateTitle(this.modelValue.title, this.modelValue.id)
          .then((response) => {
            this.isTitleValid = response.valid

            if (!response.valid) {
              this.titleError = response.valid ? null : 'Title already exists'
            }
          })
      }, 300),
    },
    components: {
      DescriptionWithKeywords,
    },
    watch: {
      title() {
        this.validateTitle()
      },
    },
  })

  function computedValueProperty(name) {
    return {
      get() {
        return this.modelValue[name]
      },
      set(value) {
        this.$emit('update:modelValue', { ...this.modelValue, [name]: value })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .scope-description__header {
    margin-bottom: 20px;
  }

  :deep(.scope-description__items) {
    margin-top: 15px;
  }

  .scope-description__button {
    display: flex;
    align-items: center;
    margin-top: auto;

    span {
      margin-left: 20px;
    }
  }
</style>
