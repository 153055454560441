<template>
  <div class="ecosystem-member-spotting-areas has-action-bar">
    <div v-if="fetchingContent" style="display: flex; justify-content: center;">
      <icon name="spinner"/>
    </div>
    <div v-else>
      <div class="pull-right side-panel__spotting-area-header-button-container" style="text-align: right;">
        <div class="spotting-area-list-container">
          <dropdown
            new-prepend-icon="microscope"
            new-append-icon="chevron-down"
            icon-fill="black"
            v-model="selectedDropdownOption"
            :options="availableSpottingAreas"
            style="width: 100%"
            v-if="availableSpottingAreas.length > 0"
          />
        </div>
        <ds-button :label="`Create new ${spottingAreaLabel}`" icon="plus" @click="displayCreateSpottingAreasModal()"/>
        <ds-button icon="trash" @click="destroy(selectedSpottingArea)"
                   v-if="isMember && selectedSpottingArea && selectedSpottingArea.id > 0"/>
      </div>

      <div v-if="spottingAreas.length < 1">
        <span>Before you can start managing {{ spottingAreasLabel }}, please create one.</span>
      </div>

      <div v-else>
        <div v-if="selectedSpottingArea.id !== -1">
          <h3 class="h3">{{ spottingAreaLabel }} Information</h3>
          <div class="ecosystem-member-communities__general-container" style="width: 40%">
            <div class="ecosystem-member-communities__general-info">
              <div>
                <form-group :label="`${spottingAreaLabel} Name`" class="ecosystem-member-communities__general-input">
                  <ds-input required v-model="selectedSpottingArea.name"/>
                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.name">
                      <span>{{ errors.name[0] }}</span>
                    </div>
                  </div>
                </form-group>

                <form-group :label="`${spottingAreaLabel} Description ${isRequired ? '*' : ''}`" class="ecosystem-member-communities__general-input"
                            info-text="This text will be used in the navigation header">
                  <!--                  <ds-textarea :placeholder="`Provide a description of your ${spottingAreaLabel}`" required
                                        v-model="selectedSpottingArea.description"
                                        class="spotting-area__description"/>-->

                  <rich-text-area
                    :custom-placeholder="`Provide a description of your ${spottingAreaLabel}`" required
                    v-model="selectedSpottingArea.description"
                    class="spotting-area__description"
                  />
                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.description">
                      <span>{{ errors.description[0] }}</span>
                    </div>
                  </div>
                </form-group>

                <form-group :label="`${spottingAreaLabel} About Text`" class="ecosystem-member-communities__general-input"
                            info-text="This text will be displayed as a separate tab in the spotting area detail page">
                  <rich-text-area
                    custom-placeholder=""
                    v-model="selectedSpottingArea.about_text"
                    class="spotting-area__description"
                  />
                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.description">
                      <span>{{ errors.description[0] }}</span>
                    </div>
                  </div>
                </form-group>

                <form-group :label="`Add the actor portfolio's that belong to this ${spottingAreaLabel}`"
                            class="ecosystem-member-communities__general-input">
                  <dropdown v-model="selectedSpottingArea.portfolios" :multiple="true" search :options="portfolios"
                            :allowClear="true"/>

                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.actor_portfolios">
                      <span>{{ errors.actor_portfolios[0] }}</span>
                    </div>
                  </div>

                  <template v-slot:instructions>
                    <a
                      v-if="!saving"
                      href="#"
                      @click.prevent="handleViewPortfolios"
                    >
                      View portfolio's
                    </a>
                  </template>
                </form-group>

                <form-group :label="`Manage the trend monitors that belong to this ${spottingAreaLabel}`"
                            class="ecosystem-member-communities__general-input">
                  <dropdown v-model="selectedSpottingArea.trend_monitors" :multiple="true" search
                            :options="conceptSearches" :allowClear="true"/>

                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.trend_monitors">
                      <span>{{ errors.trend_monitors[0] }}</span>
                    </div>
                  </div>

                  <template v-slot:instructions>
                    <a
                      v-if="!saving"
                      href="#"
                      @click.prevent="handleViewTrendMonitors"
                    >
                      View trend monitors
                    </a>
                  </template>
                </form-group>

                <!-- Manage lexicons (dictionaries) -->
                <form-group :label="`Manage the lexicons that belong to this ${spottingAreaLabel} (max 1.)`">
                  <dropdown
                    :multiple="false"
                    :search="true"
                    :allow-clear="true"
                    :options="topicOptions"
                    v-model="selectedSpottingArea.context"
                    :disabled="saving"
                    :class="{ 'disabled-while-editing': saving }"
                  />

                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.context">
                      <span>{{ errors.context[0] }}</span>
                    </div>
                  </div>

                  <template v-slot:instructions>
                    <a
                      v-if="!saving"
                      href="#"
                      @click.prevent="handleEditTopics"
                    >
                      View lexicons
                    </a>
                  </template>
                </form-group>

              </div>

              <form-group label="Configure access">
                <label v-if="$store.getters.isSpottingAreaScopeEnabled" class="form-group__header">
                  <div class="form-group__label__text">
                    <Icon name="information-outline" size="18" style="vertical-align: sub;"/>
                    Don't forget to configure the accessible spotting areas per role in the section 'Accessible data' on
                    <router-link to="/settings/publisher">
                      the permissions
                    </router-link>
                  </div>
                </label>
                <label
                  v-if="selectedSpottingArea.highlight && (oldSpottingAreaType !== selectedSpottingArea.type) && selectedSpottingArea.type !== SPOTTING_AREA_TYPES.PUBLIC">
                  <div class="form-group__label__text"
                       style="color: red">
                    <Icon name="information-outline" size="18" style="vertical-align: sub;"/>
                    WARNING: Saving these changes will remove the <strong>highlighted</strong> status of this spotting
                    area.
                  </div>
                </label>
                <radio-button
                  :model-value="selectedSpottingArea.type"
                  @update:modelValue="selectedSpottingArea.type = $event"
                  name="type"
                  label="Public (for visitors and ecosystem members: always visible & only available through permission-based access, portfolio members can explore)"
                  val="public"
                />

                <!--<radio-button
                  :model-value="selectedSpottingArea.type"
                  @update:modelValue="selectedSpottingArea.type = $event"
                  name="type"
                  label="Restricted (for visitors and ecosystem members: only visible & available through permission-based access, portfolio members can contribute)"
                  val="restricted"
                />-->

                <radio-button
                  :model-value="selectedSpottingArea.type"
                  @update:modelValue="selectedSpottingArea.type = $event"
                  name="type"
                  label="Premium (for portfolio members: invite only, not for visitors and ecosystem members)"
                  val="premium"
                />

                <radio-button
                  :model-value="selectedSpottingArea.type"
                  @update:modelValue="selectedSpottingArea.type = $event"
                  name="type"
                  label="Private (only for owners and team members)"
                  val="private"
                />
              </form-group>

              <div v-if="selectedSpottingArea.type === SPOTTING_AREA_TYPES.PUBLIC" class="spotting-areas-highlighted">
                <form-group
                  :label="`Highlight this ${spottingAreaLabel} & choose the highlighted color`"
                  info-text="Make sure the spotting area is added to the accessible data, configurable in the permissions per role."
                >
                  <div class="spotting-area-highlight-container"
                       style="display: flex; flex-direction: row;" v-if="availableSpottingAreas.length > 0">

                    <div style="display: flex; flex-direction: row;align-items: center;">
                      <input
                        type="checkbox"
                        v-model="selectedSpottingArea.highlight"
                        :value="selectedSpottingArea.highlight"
                      />
                      <h5>Highlight this spotting area</h5>
                    </div>
                    <color-picker :colors="{}" title=""
                                  v-model="selectedSpottingArea.highlight_color"
                                  @updatePrimary="selectedSpottingArea.highlight_color = $event"
                                  :initial-value="selectedSpottingArea.highlight_color"/>
                  </div>
                </form-group>
              </div>

              <div v-if="selectedSpottingArea.type === SPOTTING_AREA_TYPES.PREMIUM">
                <form-group :label="`Which users can explore this spotting area? (only portfolio members)`"
                            class="ecosystem-member-communities__general-input">
                  <dropdown v-model="selectedSpottingArea.explorers" :multiple="true" search
                            :options="allPortfolioMembers"
                            :allowClear="true"/>

                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.explorers">
                      <span>{{ errors.explorers[0] }}</span>
                    </div>
                  </div>
                </form-group>

                <form-group :label="`Which users can contribute on this spotting area? (only portfolio members)`"
                            class="ecosystem-member-communities__general-input">
                  <dropdown v-model="selectedSpottingArea.contributors" :multiple="true" search
                            :options="allPortfolioMembers"
                            :allowClear="true"/>

                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.contributors">
                      <span>{{ errors.contributors[0] }}</span>
                    </div>
                  </div>
                </form-group>
              </div>


              <form-group :label="`${spottingAreaLabel} Picture`" class="ecosystem-member-communities__general-input">
                <image-input
                  :allow-remove="true"
                  @remove="selectedSpottingArea.image = ''"
                  v-model="selectedSpottingArea.image"
                  @input="selectedSpottingArea.image_name = $event"
                  width="512"
                  height="288"
                  :placeholder="$t('community_image_placeholder')"
                  :is-simplified="true"
                />
              </form-group>
            </div>
          </div>

          <action-bar :editing="true">
            <ds-button variant="primary" :label="`Save ${spottingAreaLabel}`" @click="updateSpottingArea()"
                       :disabled="saving" :loading="saving"/>
            <span class="action-bar__message" v-if="errors && errors.message">{{ errors.message }}</span>
            <span class="action-bar__message"
                  v-else-if="saved && !saving">{{ spottingAreaLabel }} has been updated</span>
          </action-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { deleteSpottingArea, ManageSpottingAreas } from '../../api/spottingareas'
  import { Dictionary, fetchAllConceptSearches } from '../../api/exploration'
  import { fetchAllPortfolios } from '../../api/portfolios'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import ActionBar from '../Form/ActionBar.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import RadioButton from '../Form/RadioButton.vue'
  import { fetchUsers } from '../../api/config'
  import { defineComponent } from 'vue'
  import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from '@/store/modules/spottingareas'
  import { SPOTTING_AREA_TYPES } from '@/store/modules/config.js'
  import RichTextArea from '@/components/TextArea/RichTextArea.vue'
  import ColorPicker from '@/components/Form/ColorPicker.vue'
  import FormGroup from '@/components/Form/FormGroup.vue'

  export default defineComponent({
    name: 'SpottingAreas.vue',
    data () {
      return {
        fetchingContent: false,
        spottingAreas: [],
        portfolios: [],
        conceptSearches: [],
        oldSpottingAreaType: null,
        selectedSpottingArea: {
          id: -1,
          name: null,
          description: '',
          about_text: '',
          type: SPOTTING_AREA_TYPES.PREMIUM,
          managers: [],
          members: [],
          permissions: {},
          image: null,
          image_path: null,
          file: null,
          ecosystem_id: 0,
          actor_portfolios: [],
          trend_monitors: [],
          context: [],
          explorers: [],
          contributors: [],
          highlight: false,
          highlight_color: '#000000'
        },
        selectedUsers: [],
        dictionaries: null,
        saving: false,
        errors: {},
        saved: false,
        minTitleLength: 2,
        minBodyLength: 10,
        allPortfolioMembers: [],
        originalPortfolioMembers: [],
      }
    },
    computed: {
      SPOTTING_AREA_TYPES () {
        return SPOTTING_AREA_TYPES
      },
      isRequired () {
        return this.selectedSpottingArea.type !== SPOTTING_AREA_TYPES.PREMIUM
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isSelectedSpottingAreaNew () {
        return this.selectedSpottingArea && !this.selectedSpottingArea.id
      },
      availableSpottingAreas () {
        if (!this.spottingAreas || this.spottingAreas.length === 0) {
          return []
        }

        return this.spottingAreas.map(SpottingArea => { // package is a reserved JS word
          return {
            value: SpottingArea.id,
            label: SpottingArea.name,
          }
        })
      },
      topicOptions () {
        if (!this.dictionaries) {
          return []
        }

        return this.dictionaries.map(item => ({
          value: item.id,
          label: item.value,
        }))
      },
      selectedDropdownOption: {
        get () {
          if (!this.selectedSpottingArea || !this.selectedSpottingArea.id) {
            return null
          }

          return {
            value: this.selectedSpottingArea.id,
            label: this.selectedSpottingArea.name,
          }
        },
        set (spottingAreaId) {
          const spottingArea = this.spottingAreas.find(p => p.id === spottingAreaId)
          this.selectedSpottingArea = spottingArea
          this.oldSpottingAreaType = spottingArea.type
          if (this.selectedSpottingArea.image) {
            this.selectedSpottingArea.image = window.location.protocol + '//' + window.location.hostname + '/api/spotting-areas/' + this.selectedSpottingArea.id + '/image'
          }
          this.selectedSpottingArea.description = this.selectedSpottingArea.description.replace('<p>', '').replace('</p>', '')
          this.errors = {}
        },
      },
    },
    methods: {
      fetchPortfolioMembers () {
        fetchUsers({ role: 'portfolio_member', limit: 50 }).then((response) => {
          const portfolioMembers = response.data.filter(user => user.verified && user.name.toLowerCase() !== 'removed user')

          portfolioMembers.forEach((member) => {
            const memberObject = {
              label: member.name + ' - ' + member.email,
              value: member.id,
            }
            this.allPortfolioMembers.push(memberObject)
            this.originalPortfolioMembers.push(memberObject)
          })
        })
      },
      fetchPortfolios () {
        fetchAllPortfolios({ limit: 1000, type: 'all' })
          .then(portfolios => {
            var portfolioOptions = []

            portfolios.forEach((portfolio) => {
              if (portfolio.virtual) {
                return
              }

              portfolioOptions.push({ value: portfolio.id, label: portfolio.name })
            })

            this.portfolios = portfolioOptions
          })
          .catch(errors => {
            console.log(errors)
          })
      },
      fetchTrendMonitors () {
        fetchAllConceptSearches({})
          .then(conceptSearches => {
            var conceptSearchOptions = []

            conceptSearches.forEach((conceptSearch) => {
              conceptSearchOptions.push({ value: conceptSearch.id, label: conceptSearch.title })
            })

            this.conceptSearches = conceptSearchOptions
          })
      },
      displayCreateSpottingAreasModal () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CREATE_SPOTTING_AREA)
      },
      removeDuplicates (suggestions) {
        return suggestions
      },
      confirmDeleteSpottingArea (spottingAreaId) {
        deleteSpottingArea({ spottingAreaId: spottingAreaId })
          .then(response => {
            this.fetchSpottingAreas(true)

            // Refresh the spotting areas list (crf. spotting areas overview page)
            this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
          })
          .catch(error => {
            console.log(error)
          })
      },
      updateSpottingArea () {
        this.saving = true
        this.saved = false
        this.errors = {}

        var spottingAreaId = this.selectedSpottingArea.id

        if (this.selectedSpottingArea.highlight
          && this.selectedSpottingArea.type !== SPOTTING_AREA_TYPES.PUBLIC) {
          this.selectedSpottingArea.highlight = false
        }

        ManageSpottingAreas
          .post(this.selectedSpottingArea)
          .then(response => {
            if (response.success) {
              this.$bus.emit('spottingAreaUpdated', { spottingAreaId: spottingAreaId })

              this.saved = true
              this.saving = false
            }

            this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT)
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
      },
      fetchDictionaryOptions () {
        // we should reconsider fetching the data when clicking the dropdown, so that the data is up to date when selecting an option
        Dictionary
          .get()
          .then(dictionaries => {
            this.dictionaries = dictionaries
          })
      },
      handleViewPortfolios () {
        window.open(window.location.protocol + '//' + window.location.host + '/portfolios', '_blank')
      },
      handleViewTrendMonitors () {
        window.open(window.location.protocol + '//' + window.location.host + '/dashboards/explore-content', '_blank')
      },
      handleEditTopics () {
        window.open(window.location.protocol + '//' + window.location.host + '/settings/exploration', '_blank')
      },
      destroy (SpottingArea) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          name: SpottingArea.title,
          resource: SpottingArea,
          modalContextType: 'spotting-area',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      fetchSpottingAreas (selectFirst = false, last = false) {
        this.fetchingContent = true

        ManageSpottingAreas
          .get()
          .then(response => {
            this.spottingAreas = response.map(c => {
              const spottingArea = { ...c }

              return spottingArea
            })

            if (last && !selectFirst && this.spottingAreas.length > 0) {
              this.spottingAreas.sort((a, b) => b.id - a.id)

              this.selectedDropdownOption = this.spottingAreas[0].id
              this.selectedSpottingArea = this.spottingAreas[0]

              if (this.selectedSpottingArea.image) {
                this.selectedSpottingArea.image_name = this.selectedSpottingArea.image
                this.selectedSpottingArea.image = window.location.protocol + '//' + window.location.hostname + '/api/spotting-areas/' + this.selectedSpottingArea.id + '/image'
              }
            }
            if (!last && selectFirst && this.spottingAreas.length > 0) {
              this.selectedDropdownOption = this.spottingAreas[0].id
              this.selectedSpottingArea = this.spottingAreas[0]

              if (this.selectedSpottingArea.image) {
                this.selectedSpottingArea.image_name = this.selectedSpottingArea.image
                this.selectedSpottingArea.image = window.location.protocol + '//' + window.location.hostname + '/api/spotting-areas/' + this.selectedSpottingArea.id + '/image'
              }
            }

            this.fetchingContent = false
          })
          .catch(errors => {
            console.log(errors)
          })
      },
    },
    components: {
      FormGroup, ColorPicker,
      RichTextArea,
      RadioButton,
      AutocompleteDropdown,
      Dropdown,
      ActionBar,
      ImageInput,
      DsTextarea,
    },
    mounted () {
      if (!this.$store.getters.hasAccessToNewSpottingAreas) {
        this.$router.push('/')
      }

      this.fetchSpottingAreas(true)
      this.fetchPortfolioMembers()

      this.fetchPortfolios()
      this.fetchTrendMonitors()
      this.fetchDictionaryOptions()

      this.$bus.on('spottingAreaCreated', () => {
        this.fetchSpottingAreas(false, true)

        // Refresh the spotting areas list (crf. spotting areas overview page)
        this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
      })

      this.$bus.on('spottingAreaUpdated', (context) => {
        this.fetchSpottingAreas(false)

        // Refresh the spotting areas list (crf. spotting areas overview page)
        this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
      })

      this.$bus.on('spottingAreaDeleteConfirmation', (context) => {
        this.confirmDeleteSpottingArea(context.resource.id)
      })
    },
    mixins: [TranslationsMixin],
    beforeUnmount () {
      this.$bus.off('spottingAreaCreated')
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  :deep(.color-picker__color-items) {
    margin: 0 !important;
  }

  .color-picker__main-body {
    width: 150px !important;
    border: 0px !important;
    background-color: transparent !important;
  }

  .spotting-area-list-container {
    position: relative;

    .color-picker__color-header {
      border: 1px solid black;
      width: fit-content !important;
      padding: 0;


      span {
        margin-left: 0 !important;
      }
    }
  }

  .ecosystem-member-spotting-areas {
    .side-panel__spotting-area-header-button-container {
      display: flex;
    }

    .spotting-area__description {
      min-height: 75px;
      margin-bottom: 20px;
    }

    .spotting-area-list-container {
      display: flex;
      align-items: center;
      color: white;
      background-color: var(--primary);
      margin-right: 10px;

      .icon-button {
        margin: 0px 10px;
        color: white;
      }

      .multiselect.multiselect--datascouts .multiselect__tags {
        border: 0;
        color: white;
        background-color: var(--primary) !important;

        span {
          color: white;
          background-color: var(--primary);
        }
      }
    }
  }
</style>
