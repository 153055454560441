<template>
  <div class="concept-search-guide">
    <GuideProgress v-model="step" :completedUntil="completedUntil" :steps="STEPS"
                   :disable-navigation="step === STEPS.length || isEditing"
                   style="margin-bottom: 40px;"/>
    <div>
      <div class="concept-search-guide__content">
        <ScopeDescription v-model="explorationSearch" v-show="step <= 2"
                          :is-generating-description="isGeneratingDescription"
                          :withCancel="withCancel" @next-step="handleNextStep"
                          @generate-description="handleGenerateDescription"
                          @cancel="handleCancel" :part="step"
                          :intro-js-mock-data="guidedStep && [{label: 'example'}]"
                          class="fixed-minheight-container"/>
        <ReportType v-if="hasAccessToExploration" v-model="explorationSearch"
                    :report-types="REPORT_TYPES" v-show="step === 3"
                    :withCancel="withCancel" @next-step="handleNextStep"
                    @cancel="handleCancel" class="fixed-minheight-container"/>
        <ExplorationSettings v-model="explorationSearch"
                             v-show="hasAccessToExploration ? step === 4 : step === 3"
                             :withCancel="withCancel" @next-step="handleNextStep"
                             @cancel="handleCancel" class="fixed-minheight-container"/>
        <SavingAndResult :response="guidedStep ? {} : response" v-show="step === STEPS.length"
                         :withCancel="withCancel" @done="handleDone"
                         @visit-concept-map="handleVisitConceptMap"
                         class="fixed-minheight-container"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import GuideProgress from './GuideProgress.vue'
  import ScopeDescription from './ScopeDescription.vue'
  import ReportType from './ReportType.vue'
  import ExplorationSettings from './ExplorationSettings.vue'
  import SavingAndResult from './SavingAndResult.vue'
  import Scoring from './Scoring.vue'

  import { storeConceptSearch, updateConceptSearchAndExplore, } from '../../api/exploration.js'
  import { generateDescription } from '../../api/generative'
  import { MUTATION_TYPES as CONCEPT_SEARCH_GUIDE, REPORT_TYPES, } from '../../store/modules/conceptSearchGuide.js'

  import DictionaryMixin from '../../util/DictionaryMixin.js'
  import { defineComponent } from 'vue'

  declare interface BaseComponentData {
    saving: boolean,
    response: number,
    REPORT_TYPES: REPORT_TYPES,
    guidedStep: number,
  }

  export default defineComponent({
    components: {
      GuideProgress,
      ScopeDescription,
      ReportType,
      ExplorationSettings,
      SavingAndResult,
      Scoring,
    },
    mixins: [DictionaryMixin],
    props: {
      withCancel: {
        type: Boolean,
      },
      startAtStep: {
        type: Number,
        required: false,
      },
      valueToScrollTo: {
        required: false
      }
    },
    data(): BaseComponentData {
      return {
        saving: false,
        response: null,
        REPORT_TYPES: REPORT_TYPES,
        guidedStep: null,
        isGeneratingDescription: false,
      }
    },
    computed: {
      isEditing() {
        return this.$store.state.conceptSearchGuide.conceptSearch && this.$store.state.conceptSearchGuide.conceptSearch.id > 0
      },
      STEPS() {
        if (this.hasAccessToExploration) {
          return [
            { value: 1, label: 'Keywords' },
            { value: 2, label: 'Research Scope' },
            { value: 3, label: 'Report type' },
            { value: 4, label: 'Market context' },
            { value: 5, label: 'Explore' },
          ]
        }

        return [
          { value: 1, label: 'Keywords' },
          { value: 2, label: 'Research Scope' },
          { value: 3, label: 'Market context' },
          { value: 4, label: 'Explore' },
        ]
      },
      hasAccessToExploration() {
        return this.$store.getters.hasAccessToExploration
      },
      step: {
        get() {
          if (this.guidedStep) {
            return this.guidedStep
          }
          return this.$store.state.conceptSearchGuide.step
        },
        set(step) {
          this.$store.commit(CONCEPT_SEARCH_GUIDE.UPDATE_STEP, step)
        },
      },
      completedUntil() {
        if (this.hasAccessToExploration) {
          return this.explorationSearch.id ? 4 : this.step - 1
        }
        return this.explorationSearch.id ? 2 : this.step - 1
      },
      explorationSearch: {
        get() {
          return this.$store.state.conceptSearchGuide.conceptSearch
        },
        set(conceptSearch) {
          this.$store.commit(CONCEPT_SEARCH_GUIDE.UPDATE_CONCEPT_SEARCH, conceptSearch)
        },
      },
    },
    methods: {
      handleNextStep() {
        this.step++

        if (this.step >= this.STEPS.length) {
          // Create or Update the search if we have reached the end
          this.handleCreateSearch()
        }
        let container = document.querySelector('.home')
        if (!container) {
          container = document.querySelector('.scrollable')
        }
        container.scrollTop = this.valueToScrollTo || 0
      },
      handleGenerateDescription() {
        this.isGeneratingDescription = true
        generateDescription('trend_monitoring_context', { title: this.explorationSearch.title, tags: this.explorationSearch.searchTopics })
          .then((response) => {
            //this.explorationSearch.context = response.result
            this.$bus.emit('descriptionGenerated', response.result)
          })
          .finally(() => {
            this.isGeneratingDescription = false
          })
      },
      handleCreateSearch() {
        this.saving = true
        this.response = null
        this.step = this.STEPS.length

        let promise

        if (this.explorationSearch.id === null) {
          promise = storeConceptSearch({
            title: this.explorationSearch.title,
            report_type: this.explorationSearch.reportType,
            context: this.explorationSearch.context,
            exclude: this.explorationSearch.exclude,
            news_groups: this.explorationSearch.newsGroups,
            search_topics: this.explorationSearch.searchTopics.map(topic => {
              if (topic.label.indexOf(' (user-generated)') > 0) {
                return {
                  label: topic.label.replace(' (user-generated)', ''),
                  value: topic.value,
                }
              }
              return { label: topic.label, value: topic.value }
            }),
            dictionary_context_id: this.explorationSearch.topics,
            description: this.explorationSearch.description,
            geography_context: this.explorationSearch.geographyContext,
            scope_description: this.explorationSearch.scopeDescription,
            timespan: this.explorationSearch.timespan,
            // scope_description_analysis: this.explorationSearch.scopeDescriptionAnalysis,
            scoring_config: this.explorationSearch.customScores,
            languages: this.explorationSearch.languages
          })
        } else {
          promise = updateConceptSearchAndExplore(this.explorationSearch.id, {
            title: this.explorationSearch.title,
            report_type: this.explorationSearch.reportType,
            context: this.explorationSearch.context,
            exclude: this.explorationSearch.exclude,
            news_groups: this.explorationSearch.newsGroups,
            search_topics: this.explorationSearch.searchTopics,
            dictionary_context_id: this.explorationSearch.topics,
            description: this.explorationSearch.description,
            geography_context: this.explorationSearch.geographyContext,
            scope_description: this.explorationSearch.scopeDescription,
            timespan: this.explorationSearch.timespan,
            // scope_description_analysis: this.explorationSearch.scopeDescriptionAnalysis,
            scoring_config: this.explorationSearch.customScores,
            languages: this.explorationSearch.languages
          })
        }

        promise
          .then(response => {
            this.$store.commit(CONCEPT_SEARCH_GUIDE.RESET_CONCEPT_SEARCH)
            this.saving = false
            this.response = response
            this.$emit('created')
          })
          .catch(() => {
            this.saving = false
            this.step = this.STEPS.length - 1
          })
      },
      handleDone() {
        this.$store.commit(CONCEPT_SEARCH_GUIDE.RESET_CONCEPT_SEARCH)
        if (this.response && this.response.concept_search_id) {
          this.$router.push(`/dashboards/concept-map/${this.response.concept_search_id}`)
        }
        this.$emit('done', this.response.concept_search)
      },
      handleCancel() {
        this.$emit('cancel')
      },
      handleVisitConceptMap() {
        if (!this.response) {
          return
        }

        this.$router.push({
          name: 'ConceptMap',
          params: { id: this.response.concept_search_id },
        })
      },
      setReportTypePresets(id, presets) {
        const reportType = this.REPORT_TYPES.find(r => r.id === id)

        if (reportType) {
          reportType.presets = { ...reportType.presets, ...presets }
        }
      },
    },
    created() {
      // By default, set the report type to market trend watch if there's none set yet (i.e. it's a new search)
      if (!this.explorationSearch.reportType) {
        var search = { ...this.explorationSearch }
        search.report_type = 'market_trend_watch'

        this.explorationSearch = search
      }

      // We load the dictionaries (using the DictionaryMixin, which shares the data between all users of DictionaryMixin),
      // and then find the required dictionaries for presets and update the presets.
      this.dictionaryPromise.then(dictionaries => {
        for (const reportType of REPORT_TYPES) {
          const foundDictionaries = dictionaries.filter(d => d.report_type === reportType.id)
          this.setReportTypePresets(reportType.id, { topics: foundDictionaries.map(d => d.id) })
        }
      })

      if (this.startAtStep) {
        this.step = this.startAtStep
      } else {
        this.step = 1
      }

      this.$bus.on('set-introjs-concept-step', (step) => {
        this.guidedStep = step
      })
    },
    beforeUnmount() {
      this.$bus.off('set-introjs-concept-step')
    }
  })
</script>

<style lang="scss" scoped>

  .fixed-minheight-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 337px);
  }

  .home {
    .fixed-minheight-container {
      min-height: calc(100vh - 250px);
    }
  }

  .concept-search-guide {
    padding-bottom: 20px;
  }

  .concept-search-guide__content {
    max-width: 640px;
    margin: 0 auto;
  }
</style>
