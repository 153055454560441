<template>
  <div class="challenge-container" ref="challengeContainer"
       :class="{'vertical-card': isVertical, 'is-simplified': isSimplified, 'full-card-clickable': isCommunityEcosystem}"
       @click="isCommunityEcosystem ? visitAnnouncement('containerClickDetail'): false"
  >
    <div class="challenge" v-if="announcement" :class="{'container': isContainer}">
      <div class="challenge-image-wrapper">
        <div class="challenge-image-inner-wrapper">
          <div v-if="announcement.embedded_video && isYoutubeVideo" class="challenge-image" style="position: relative;" :style="{height: fixedImageSize}">
            <iframe style="border-radius: 12px 12px 0px 0px" frameborder="0" :src="convertToYoutubeEmbed(announcement.embedded_video) + '?controls=0'"></iframe>
            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: transparent;"></div>
          </div>
          <div v-else class="challenge-image"
               :style="{'background-image': `url(${getImage()})`, height: fixedImageSize}">
          </div>
        </div>
        <div v-if="showImageActions" class="challenge-image-tags">
          <tag :label="String(announcement.comments) || '0'"
               :clickable="true"
               @click="visitAnnouncement('commentClickDetail')"
               icon="comments"/>
          <tag v-if="index && index % 2 === 0"
               :label="announcement.likers ? String(announcement.likers.length) : '0'"
               :icon="announcement.user_like ? 'thumbs-up' : 'thumbs-up-outline'"
               v-tooltip.right="getTooltipForLikes(announcement.likers)"
               style="cursor: pointer"
               :key="componentKey"/>
          <tag v-else
               :label="announcement.likers ? String(announcement.likers.length) : '0'"
               :icon="announcement.user_like ? 'thumbs-up' : 'thumbs-up-outline'"
               v-tooltip.left="getTooltipForLikes(announcement.likers)"
               style="cursor: pointer"
               :key="componentKey"/>
        </div>
        <div v-else-if="announcement.tags.length > 0" class="challenge-image-first-tag">
          <tag :label="announcement.tags[0].text" style="border-radius: 4px; font-size: 14px; padding: 5px 12px;"/>
        </div>
      </div>
      <div :class="{'challenge-text': true, 'text-left': hideReadMoreButton}"
           :style="{height: isVertical ? 'auto' : fixedImageSize}">
        <h3 class="h3 clickable" @click="visitAnnouncement('titleClickDetail')">
          {{ localizedDisplayProperty(announcement, 'title') }}</h3>
        <div v-if="showImageActions" class="challenge-image-tags challenge-image-tags-mobile">
          <div style="display: flex">
            <tag :label="String(announcement.comments) || '0'" :clickable="true"
                 @click="visitAnnouncement('commentClickDetail')"
                 icon="comments"/>
            <tag :label="announcement.likers ? String(announcement.likers.length) : '0'"
                 :icon="announcement.user_like ? 'thumbs-up' : 'thumbs-up-outline'"
                 v-tooltip.right="getTooltipForLikes(announcement.likers)"
                 style="cursor: pointer" v-if="index && index % 2 === 0"
                 :key="componentKey"/>
            <tag :label="announcement.likers ? String(announcement.likers.length) : '0'"
                 :icon="announcement.user_like ? 'thumbs-up' : 'thumbs-up-outline'"
                 v-tooltip.left="getTooltipForLikes(announcement.likers)"
                 style="cursor: pointer" v-else :key="componentKey"/>
          </div>
        </div>
        <p class="challenge-description"
           v-html="localizedDisplayProperty(announcement, 'shortDescription')">
        </p>
        <div v-if="!hideReadMoreButton" class="button-container">
          <ds-button :variant="isSimplified ? 'rounded' : 'primary'"
                     @click="trackHeapEvent('announcementCard.readMoreClickDetail')"
                     :to="getLinkForAnnouncement(announcement)"
                     :label="$t('announcement_call_to_action')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Tag from '../Tag/Tag.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { convertToYoutubeEmbed, getImageForAnnouncement, getImageForCommunity, getTooltipForLikes } from '../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'simplified-announcement-preview',
    props: {
      announcement: Object,
      index: Number,
      isSimplified: {
        type: Boolean,
        default: false,
      },
      isContainer: {
        type: Boolean,
        default: true,
      },
      hideReadMoreButton: {
        type: Boolean,
        default: false,
      },
      showImageActions: {
        type: Boolean,
        default: true,
      },
      isCommunityDetail: {
        type: Boolean,
        default: false,
      },
      community: Object,
      isVertical: Boolean,
      componentKey: Number,
    },
    data() {
      return {
        fixedImageSize: 0,
      }
    },
    components: {
      Tag,
    },
    mixins: [TranslationsMixin],
    methods: {
      convertToYoutubeEmbed,
      getLinkForAnnouncement(announcement) {
        const announcementType = announcement.is_event ? 'events' : 'announcements'

        if (this.$route.name === 'community-detail') {
          return `/communities/${this.$route.params.communityId}/${announcementType}/${announcement.slug || announcement.id}`
        }
        return '/' + announcementType + '/' + (announcement.slug || announcement.id)
      },
      getImageForAnnouncement,
      getTooltipForLikes,
      trackHeapEvent,
      getImage() {
        if (!this.announcement || !this.announcement.image || this.announcement.image === '') {
          if (this.isCommunityDetail && this.community && this.community.id && this.community.image) {
            return getImageForCommunity(this.community)
          }
          return this.getImageForAnnouncement(this.announcement)
        }

        return this.getImageForAnnouncement(this.announcement)
      },
      calculateImageHeight() {
        if (this.hideReadMoreButton) {
          this.fixedImageSize = `350px`

          return
        }

        if (window.innerWidth < 992) {
          if (this.isVertical && this.$refs.challengeContainer) {
            this.fixedImageSize = `calc(0.28125 * ${getComputedStyle(this.$refs.challengeContainer).width})`
          } else {
            this.fixedImageSize = 'auto'
          }
          return
        }

        if (this.$refs.challengeContainer) {
          // aspect ratio 0.5625 == 16:9, divided by 2 or 4 because image is half of total width
          if (this.isVertical) {
            this.fixedImageSize = `calc(0.28125 * ${getComputedStyle(this.$refs.challengeContainer).width})`
          } else {
            this.fixedImageSize = `calc(0.140625 * ${getComputedStyle(this.$refs.challengeContainer).width})`
          }
        }
      },
      visitAnnouncement(eventName) {
        trackHeapEvent('announcementCard' + eventName)

        const announcementType = this.announcement.is_event ? 'events' : 'announcements'
        if (this.$route.name === 'community-detail') {
          this.$router.push(`/communities/${this.$route.params.communityId}/${announcementType}/${this.announcement.slug || this.announcement.id}`)

          return
        }

        this.$router.push('/' + announcementType + '/' + (this.announcement.slug || this.announcement.id))
      },
    },
    mounted() {
      this.calculateImageHeight()
      this.$bus.on('resize', () => {
        this.calculateImageHeight()
      })
    },
    beforeUnmount() {
      this.$bus.off('resize')
    },
    computed: {
      isCommunityEcosystem() {
        return this.$store.getters.isCommunityEcosystem
      },
      isYoutubeVideo() {
        return this.announcement && this.announcement.embedded_video && this.announcement.embedded_video.includes('youtu')
      },
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .challenge-container {
    &:nth-child(even) .challenge {
      .tag:last-child {
        margin-right: 12px;
      }

      .tag:first-child {
        @media (min-width: $screen-lg) {
          margin-right: 0;
        }
      }

      .challenge-image-wrapper {
        .challenge-image-tags {
          position: absolute;
          bottom: 0px;
          right: 10px;
        }
      }
    }

    &.vertical-card:nth-child(odd) .challenge .tag:first-child {
      margin-right: 12px;
    }

    &:nth-child(odd) .challenge {
      .challenge-image-wrapper {
        .challenge-image-tags {
          position: absolute;
          bottom: 0px;
          left: 10px;
        }
      }
    }

    .tag {
      background: var(--primary-community);
      color: white;
      border: 0;

      :deep(path) {
        fill: white;
      }
    }
  }

  .challenge-container .challenge {
    .challenge-text {
      background-color: #EBEBEC;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .challenge-image-wrapper {
      position: relative;
    }

    .challenge-image-inner-wrapper {
      overflow: hidden;

      .challenge-image-tags {
        position: absolute;
        bottom: 0px;
        left: 10px;
      }
    }

    .challenge-image-first-tag {
      position: absolute;
      top: 15px;
      left: 15px;
    }

    .challenge-image {
      background-color: #eeeeee;
      transform: scale(1);
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }

    &:hover {
      .challenge-text {
        cursor: pointer;
        background-color: var(--primary-extra-lightest);
      }

      .challenge-image {
        // cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  .static-home-community-ecosystem {
    .full-card-clickable {
      &:hover {
        cursor: pointer;
      }
    }

    .challenge-container .challenge {
      margin-bottom: 0px !important;
    }
  }

</style>
