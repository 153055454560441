<template>
  <div class="static-top-bar" :style="{backgroundColor: $store.getters.isKlik ? '#102774' : $store.getters.isOpenCreativesZuidwest ? '#312782' : '#102774'}">
    <div class="static-top-bar__content" style="display: flex; align-items: center;">
      <div class="static-top-bar__logo">
        <ul>
          <router-link to="/dashboards/home">
            <img
              :src="leftLogoUrl" :alt="config.name"
              class="static-logo-image"
            >
          </router-link>
        </ul>
      </div>
      <div class="static-top-bar_navigation-items">
        <ul>
          <router-link
            to="/actors-simplified"
            target="_self"
            class="navigation_item"
          >
            <li v-if="isZuidwest">De gemeenschap</li>
            <li v-else>De community</li>
          </router-link>
          <router-link
            to="/products-simplified"
            target="_self"
            class="navigation_item"
          >
            <li>Ruimtes</li>
          </router-link>
          <router-link
            to="/announcements"
            target="_self"
            class="navigation_item"
          >
            <li>Kansen</li>
          </router-link>
          <router-link
            to="/events-simplified"
            target="_self"
            class="navigation_item"
          >
            <li>Agenda</li>
          </router-link>
        </ul>
      </div>
      <div class="static-top-bar_buttons">
        <div>
          <ds-button
            variant="secondary"
            label="Registreer"
            :size="null"
            :style="registerButtonStyle"
            @click="openSignupModal"
          />
          <ds-button
            class="login_button"
            variant="primary"
            label="Log in"
            :style="loginButtonStyle"
            :size="null"
            @click="openSigninModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    methods: {
      openSigninModal () {
        trackHeapEvent('staticHomePage.topBarLogin')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
      openSignupModal () {
        trackHeapEvent('staticHomePage.topBarRegister')
        if (this.$store.getters.hasAccessToEcosystemMemberPackages) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.PACKAGES_SIGN_UP)
        } else if (this.$store.state.config.allowAdd) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        }
      },
    },
    computed: {
      isZuidwest () {
        return this.$store.getters.isOpenCreativesZuidwest
      },
      leftLogoUrl () {
        if (this.isPnG) {
          return '/images/static-home-page/inqbet-logo.png'
        }
        return this.config.logo_url_light || this.config.logo_url
      },
      registerButtonStyle () {
        return {
          backgroundColor: 'transparent',
          color: 'white',
          fontFamily: '',
          fontWeight: '',
          height: 'auto',
          textTransform: 'capitalize',
        }
      },
      loginButtonStyle () {
        return {
          backgroundColor: 'transparent',
          fontFamily: '',
          fontWeight: '',
          color: 'white',
          height: 'auto',
          textTransform: 'capitalize',
        }
      },
      config () {
        return this.$store.state.config
      },
    },
  })
</script>

<style scoped lang="scss">
  @import "resources/assets/scss/variables";

  .static-logo-image {
    max-height: 40px;
    height: auto;
    max-width: calc(100vw - 100px - 190px);
    object-fit: contain;
  }

  .static-top-bar {
    .static-logo-image {
      max-height: 40px;
      margin-left: 18px;
      margin-right: 18px;
    }

    .static-top-bar__logo {
      align-items: center;
      display: flex;
      position: relative;

      a {
        height: 100px;
        align-items: center;
        display: flex;
      }

      .navigation__link--active {
        background-color: rgba(255, 255, 255, 0.1);
        color: transparent;
      }

      ul {
        display: flex;
        align-items: center;

        a {
          color: transparent;
        }

        li {
          margin: 0px 30px 0px 30px;
          color: #fff;
          opacity: 1;
        }
      }
    }

    .static-top-bar_navigation-items, {
      align-items: center;
      display: flex;
      position: relative;

      .navigation__link--active {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .navigation_item {
        height: 100px;
        align-items: center;
        display: flex;
        color: transparent;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        @media(max-width: $screen-lg) {
          height: auto;

          &:hover {
            background-color: transparent;
          }
        }
      }

      ul {
        display: flex;
        align-items: center;

        a {
          color: transparent;
        }

        li {
          margin: 0px 30px 0px 30px;
          color: #fff;
          opacity: 1;
        }
      }
    }

    .static-top-bar_buttons {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 20px;
    }
  }

  @media(max-width: $screen-lg) {
    .static-top-bar {
      padding: 20px 50px;

      .static-top-bar__logo {
        display: none;
      }

      .static-top-bar_navigation-items {
        margin-left: 0px;
      }
    }

  }

  @media(max-width: $screen-md) {
    .static-top-bar__content {
      display: flex;
      flex-direction: column;

      .static-top-bar_navigation-items {
        margin-left: 0px;
        display: flex;
        flex-direction: column;

        .navigation__link--active {
          background-color: #102774;
          margin: 0;
          height: 50px;
        }

        ul {
          flex-direction: column;

          li {
            margin-bottom: 15px;
          }
        }
      }

      .static-top-bar_buttons {
        margin-left: 0;
      }


    }
  }
</style>
