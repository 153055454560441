<template>
  <multiselect
      :model-value="internalValue"
      :options="internalOptions"
      label="label"
      trackBy="value"
      :disabled="disabled"
      :groupLabel="groupLabel"
      :groupValues="groupValues"
      :multiple="multiple"
      :allowEmpty="multiple"
      :loading="loading"
      :hideSelected="multiple"
      :searchable="search || noResultsHandlerEnabled"
      :closeOnSelect="!multiple"
      :placeholder="placeholder"
      :showLabels="false"
      :taggable="noResultsHandlerEnabled"
      :tagPlaceholder="noResultMessage.smallText"
      tagPosition="bottom"
      :openDirection="openDirection"
      class="multiselect--datascouts"
      :class="[{'multiselect--multiple': multiple}, {'multiselect--with-groups': hasGroups}, {'multiselect--clearable': isClearable, 'is-simplified': isSimplified}, cls]"
      :style="{width, marginTop}"
      :block-keys="['Tab']"
      :optionHeight="28"
      :maxHeight="maxHeight"
      :max="maxSelectedOptions"
      :show-no-options="false"
      :customLabel="dropdownLabel"
      @select="handleSelect"
      @remove="handleRemove"
      @tag="handleTag"
      @close="handleClose"
      @search-change="handleSearchChange"
      ref="multiselect"
  >
    <!--<template slot="singleLabel" slot-scope="props">
      <icon class="check"></icon>
    </template>-->

    <template
        v-slot:selection="{search, remove, values}" :getOptionLabel="getOptionLabel"
        :suggestedOptions="suggestedOptions" :acceptSuggestion="acceptSuggestion"
        :declineSuggestion="declineSuggestion"
    >
      <icon v-if="newPrependIcon" :name="newPrependIcon" :style="{fill: newPrependIconColor}"/>
      <div class="multiselect__tags-wrap" v-show="values.length > 0 || suggestedOptions.length > 0">
        <template v-for="(option, index) of values" @mousedown.prevent :key="`selected-${index}`">
          <span class="multiselect__tag">
            <div class="content">
              <span v-text="getOptionLabel(option)" :title="getOptionLabel(option)"/>
            </div>
            <div class="actions">
              <div
                  class="action" aria-hidden="true" tabindex="1" @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
              >
                <icon name="remove"/>
              </div>
            </div>
          </span>
        </template>
        <template v-for="(option, index) of suggestedOptions" @mousedown.prevent :key="`suggested-${index}`">
          <span class="multiselect__tag multiselect__tag--suggestion">
            <div class="content">
              <span v-text="getOptionLabel(option)" :title="getOptionLabel(option)"/>
            </div>
            <div class="actions">
              <div
                  class="action" aria-hidden="true" tabindex="1" @keydown.enter.prevent="acceptSuggestion(option)"
                  @mousedown.prevent="acceptSuggestion(option)"
                  v-tooltip.top="'Accept suggestion'"
              >
                <icon name="check"/>
              </div>
              <div
                  class="action" aria-hidden="true" tabindex="2" @keydown.enter.prevent="declineSuggestion(option)"
                  @mousedown.prevent="declineSuggestion(option)"
                  v-tooltip.top="'Decline suggestion'"
              >
                <icon name="remove"/>
              </div>
            </div>
          </span>
        </template>
      </div>
      <icon v-if="newAppendIcon" :name="newAppendIcon" :style="{fill: 'white'}" :class="'append-icon'"/>
    </template>
    <template v-slot:clear v-if="isClearable" :clear="clear">
      <div class="multiselect__clear" @mousedown.prevent="clear()">
        <icon name="remove"/>
      </div>
    </template>
    <template v-slot:noResult v-if="customNoResultMessage">{{ customNoResultMessage }}</template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    options: Array, // {label, value[, children]}
    data: Array, // DEPRECATED, {text, id[, children]}, please use `options` instead for new code
    modelValue: null, // value or array of values, can also include something that doesn't exist in the options, in which case format is {label: String, <any>...}
    suggestions: Array, // array of values, only supported together with an array `value`, can also include something that doesn't exist in the options, in which case format is {label: String, <any>...} and entire object is passed to suggestion events
    force: Boolean,
    multiple: Boolean,
    allowClear: Boolean, // enables a "Clear value" button on the right of the input
    search: Boolean,
    customLabel: null,
    isSimplified: {
      type: Boolean,
      default: false,
    },
    maxSelectedOptions: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    valueIsOption: { // Use entire options as values
      type: Boolean,
      default: false,
    },
    openDirection: { // Possible options are: top, bottom (default '' is auto)
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cls: null,
    noResultsHandlerEnabled: Boolean,
    customNoResultMessage: String,
    noResultMessage: {
      type: Object,
      default: () => {
        return {
          text: 'Other',
          smallText: 'Create new item',
        }
      },
    },
    reference: null,
    placeholder: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    marginTop: {
      type: String,
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
    newAppendIcon: {
      type: String,
      default: '',
    },
    newPrependIcon: {
      type: String,
      default: '',
    },
    iconFill: {
      type: String,
      default: 'white'
    },
    isHighlightedSpottingAreaDropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'search-change',
    'suggestion:accept',
    'suggestion:decline',
    'update:suggestions',
    'remove',
    'addNewItem',
  ],
  data: () => ({
    mounted: false,
  }),
  computed: {
    newPrependIconColor() {
      if (this.isHighlightedSpottingAreaDropdown) {
        return this.$store.state.spottingAreas.highlightedSpottingArea.highlight_color
      }

      if (this.iconFill) {
        return this.iconFill
      }

      return '#000000'
    },
    internalOptions() {
      if (this.data) {
        return convertData(this.data) || []
      }

      return convertOptions(this.options) || []
    },
    hasGroups() {
      for (const option of this.internalOptions) {
        if (option.children) return true
      }

      return false
    },
    groupLabel() {
      return this.hasGroups ? 'groupLabel' : undefined
    },
    groupValues() {
      return this.hasGroups ? 'children' : undefined
    },
    internalValue: {
      get() {
        if (Array.isArray(this.modelValue)) {
          return this.modelValue.map(this.getOptionForValue).filter(v => v !== null)
        }
        return this.getOptionForValue(this.modelValue)
      },
    },
    internalSuggestions() {
      // return Array.isArray(this.value) && this.suggestions ? this.suggestions : []
      return this.suggestions ? this.suggestions : []
    },
    suggestedOptions() {
      return this.internalSuggestions.map(this.getOptionForValue).filter(v => v !== null)
    },
    isClearable() {
      if (Array.isArray(this.modelValue)) {
        return this.allowClear && this.modelValue.length > 0
      }
      return this.allowClear && this.modelValue !== null && this.modelValue !== undefined
    },
  },
  methods: {
    dropdownLabel(option) {
      if (this.customLabel) {
        return this.customLabel(option)
      }

      return option.label
    },
    getValueForOption(option) {
      return this.valueIsOption ? option : option.value
    },
    getOptionForValue(value) {
      return getOptionForValueFromOptions(this.internalOptions, value, true)
    },
    getOptionLabel(option) {
      if (this.mounted) {
        return this.$refs.multiselect.getOptionLabel(option)
      }
    },
    handleSelect(selectedOption) {
      if (this.internalSuggestions.includes(selectedOption.value)) {
        const nextSuggestions = [...this.suggestions].filter(v => v !== selectedOption.value)
        this.$emit('suggestion:accept', selectedOption.value)
        this.$emit('update:suggestions', nextSuggestions)
      }

      let nextValue = this.getValueForOption(selectedOption)

      if (this.multiple) {
        const value = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue]
        nextValue = [...value, this.getValueForOption(selectedOption)]
      }

      this.emitInput(nextValue)

      if (nextValue.length === 1 && this.multiple) {
        this.preventMultiselectDeactivation()
      }
    },
    handleRemove(removedOption) {
      let nextValue = Array.isArray(this.modelValue) ? this.modelValue.filter(v => {
        if (!v) {
          // Safety for null values
          return false
        }

        var valueForOption = this.getValueForOption(removedOption)
        if (!v.value) {
          return v !== valueForOption
        }

        return v.value !== valueForOption.value && v.value !== valueForOption
      }) : null

      if (removedOption.value) {
        this.$emit('remove', removedOption.value)
      }
      if (this.multiple && nextValue === null) nextValue = []
      this.emitInput(nextValue)

      if (this.multiple && nextValue.length === 0) {
        this.preventMultiselectDeactivation()
      }
    },
    acceptSuggestion(option) {
      var nextValue

      if (Array.isArray(this.modelValue)) {
        nextValue = [...this.modelValue, option]
      } else {
        nextValue = option
      }

      const nextSuggestions = [...this.suggestions].filter(v => v !== this.getValueForOption(option))
      this.$emit('suggestion:accept', this.getValueForOption(option))
      this.$emit('update:suggestions', nextSuggestions)
      this.emitInput(nextValue)
    },
    declineSuggestion(option) {
      const nextSuggestions = [...this.suggestions].filter(v => v !== this.getValueForOption(option))
      this.$emit('suggestion:decline', this.getValueForOption(option))
      this.$emit('update:suggestions', nextSuggestions)
    },
    handleTag(searchValue) {
      this.$emit('addNewItem', searchValue)
    },
    handleClose() {
      setTimeout(() => {
        this.correctPointer()
      }, 0)
    },
    handleSearchChange(...args) {
      this.$emit('search-change', ...args)
    },
    clear() {
      for (const suggestion of this.internalSuggestions) {
        this.$emit('suggestion:decline', suggestion)
      }

      this.$emit('update:suggestions', [])

      if (Array.isArray(this.value)) {
        this.emitInput([])
      } else {
        this.emitInput(null)
      }
    },
    emitInput(value) {
      if (Array.isArray(value)) {
        value = value.filter(r => r)
      }

      if (!this.reference) {
        this.$emit('update:modelValue', value)
      } else {
        this.$emit('update:modelValue', { val: value, reference: this.reference })
      }
    },
    preventMultiselectDeactivation() {
      // Dirty, dirty little trick to prevent vue-multiselect from deactivating. This deactivation seems
      // to be a bug.
      if (this.mounted && this.$refs.multiselect.isOpen) {
        this.preventMultiselectMethodTemporarily('deactivate')
        setTimeout(() => {
          this.$refs.multiselect.deactivate()
          this.$refs.multiselect.activate()
        }, 0)
      }
    },
    preventMultiselectMethodTemporarily(name, alsoExecute) {
      const backup = this.$refs.multiselect[name]
      this.$refs.multiselect.deactivate = () => {
      }
      setTimeout(() => {
        this.$refs.multiselect[name] = backup
      }, 0)
    },
    correctPointer() {
      if (this.multiple) {
        return
      }

      const index = this.internalOptions.findIndex(o => this.getValueForOption(o) === this.modelValue)
      this.$refs.multiselect.pointerSet(Math.max(index, 0))
    },
    forceNonEmptyValue() {
      if (this.internalOptions.length <= 0) {
        return
      }

      if (this.multiple) {
        if (this.modelValue.length <= 0) {
          this.emitInput([this.getValueForOption(this.internalOptions[0])])
        }
      } else {
        if (this.modelValue === null || this.modelValue === undefined) {
          this.emitInput(this.getValueForOption(this.internalOptions[0]))
        }
      }
    },
  },
  mounted() {
    // This forces a rerender. The reasoning is as follows: the slot we use for tags doesn't include multiselect's
    // getOptionLabel() function, and we don't want to reimplement it, since it's relatively complex. We use a ref
    // to get access to getOptionLabel(), but the ref is only set after the component is mounted. Before the mount,
    // we don't actually have the labels, so we need to force a rerender after the mount to display the correct label.
    this.mounted = true

    if (this.force) {
      this.forceNonEmptyValue()
    }

    this.correctPointer()
  },
  components: {
    Multiselect,
  },
}

function convertOptions(options) {
  const converted = []

  for (const option of options) {
    if (option.children !== undefined || option.value !== undefined) {
      converted.push(convertRegularOption(option))
    } else {
      converted.push({ value: option, label: option })
    }
  }

  return converted
}

function convertRegularOption(option) {
  if (!option.children) {
    return {
      value: option.value,
      label: option.label,
      context: option.context,
    }
  }

  return {
    groupLabel: option.label,
    children: option.children.map(convertRegularOption),
    context: option.context,
  }
}

function convertData(data) {
  return data.map(convertDataOption)
}

function convertDataOption(option) {
  if (!option.children) {
    return {
      value: option.id,
      label: option.text || option.label,
    }
  }

  return {
    groupLabel: option.text,
    children: option.children.map(convertDataOption),
  }
}

function getOptionForValueFromOptions(options, value, forceResult = false) {
  for (const option of options) {
    if (option.children !== undefined) {
      const found = getOptionForValueFromOptions(option.children, value)
      if (found !== null) return found
    } else if (option.value === value || (value && value.label && option.value == value.label)) {
      return option
    }
  }

  if (!forceResult || !value) {
    return null
  }

  if (value.label !== undefined) {
    return { ...value, label: value.label, value: value.value }
  }

  return { label: value, value: value }
}
</script>
